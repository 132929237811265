import { Typography, Form, Input, Button } from "antd";
import { Link } from "react-router-dom";

const { Title } = Typography;

function RegisterPage() {
  // let history = useHistory();
  const onFinish = () => {
    // api.post("/user/register", values).then((res) => {
    //   history.push("/login");
    // });
  };

  return (
    <div
      style={{
        maxWidth: "500px",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: "70px",
      }}
    >
      <Title>Get Started ToeicX</Title>
      <Form
        name="register"
        size="large"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          label={<b>Email</b>}
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input style={{ borderColor: "black" }} />
        </Form.Item>

        <Form.Item
          label={<b>Password</b>}
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password style={{ borderColor: "black" }} />
        </Form.Item>

        <Form.Item
          label={<b>Name</b>}
          name="name"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input style={{ borderColor: "black" }} />
        </Form.Item>

        <Form.Item
          label={<b>Phone</b>}
          name="phone"
          rules={[{ required: true, message: "Please input your phone!" }]}
        >
          <Input style={{ borderColor: "black" }} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            <b>Sign Up</b>
          </Button>
        </Form.Item>
      </Form>

      <Link to="/login">
        <b>Log In</b>
      </Link>
    </div>
  );
}

export default RegisterPage;
