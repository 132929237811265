import RawFullTest from "./components/RawFullTest";
import RawMiniTest from "./components/RawMiniTest";
import RawSkillTest from "./components/RawSkillTest";
import RawVoca from "./components/RawVoca";
import InsertFullTest from "./components/InsertFullTest";
import InsertMiniTestTest from "./components/InsertMiniTest";
import InsertSkillTest from "./components/InsertSkillTest";
import InsertVoca from "./components/InsertVoca";

function Raw() {
  return (
    <div>
      <RawFullTest />
      <hr className="mt-5 mb-5" />

      <RawMiniTest />
      <hr className="mt-5 mb-5" />

      <RawSkillTest />
      <hr className="mt-5 mb-5" />

      <RawVoca />
      <hr className="mt-5 mb-5" />

      <InsertFullTest />
      <hr className="mt-5 mb-5" />

      <InsertMiniTestTest />
      <hr className="mt-5 mb-5" />

      <InsertSkillTest />
      <hr className="mt-5 mb-5" />

      <InsertVoca />
    </div>
  );
}

export default Raw;
