import { useEffect, useState } from "react";
import { Typography, Modal, Button, Tag } from "antd";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { PATH_NAME } from "paths";
import api from "api";

const { Title } = Typography;
const { confirm } = Modal;

function DefaultAvatarPage() {
  const [defaultAvatars, setDefaultAvatars] = useState([]);
  const history = useHistory();

  const getDefaultAvatar = () => {
    api("/default-avatar").then((data) => {
      if (Array.isArray(data)) {
        setDefaultAvatars(data);
      }
    });
  };

  useEffect(() => {
    getDefaultAvatar();
  }, []);

  const deleteByID = (id) => {
    api(`/default-avatar/delete/${id}`).then(() => {
      getDefaultAvatar();
    });
  };

  const showDeleteConfirm = (defaultAvatar) => {
    confirm({
      title: `Are you sure delete the avatar No.${defaultAvatar._id}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteByID(defaultAvatar._id);
      },
    });
  };

  return (
    <div>
      <div className="flex justify-between">
        <Title>List Avatars</Title>
        <Button
          type="primary"
          className="mt-2"
          onClick={() => {
            history.push(PATH_NAME.CREATE_DEFAULT_AVATAR);
          }}
        >
          Create
        </Button>
      </div>

      <div className="flex flex-wrap content-evenly">
        {defaultAvatars.map((defaultAvatar, index) => (
          <div
            key={index}
            style={{ minWidth: "300px" }}
            className="mb-8 p-5 border border-gray-300 mr-8 rounded-md hover:border-green-500"
          >
            <div className="mt-2">
              <b>{defaultAvatar.name}</b>
            </div>

            <div className="mt-2">
              <img src={defaultAvatar.src} alt="" />
            </div>

            <div className="mt-2">
              {defaultAvatar.active ? (
                <Tag icon={<CheckCircleOutlined />} color="success">
                  Active
                </Tag>
              ) : (
                <Tag icon={<CloseCircleOutlined />} color="default">
                  Inactive
                </Tag>
              )}
            </div>

            <div className="flex justify-between mt-5">
              <div className="flex">
                <Button
                  className="mr-5"
                  onClick={() => {
                    history.push(
                      PATH_NAME.UPDATE_DEFAULT_AVATAR.replace(
                        ":defaultAvatarID",
                        defaultAvatar._id
                      )
                    );
                  }}
                >
                  Edit
                </Button>
                <Button danger onClick={() => showDeleteConfirm(defaultAvatar)}>
                  Delete
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DefaultAvatarPage;
