function QuestionClusterTestingResult({ questionCluster }) {
  return (
    <div>
      <p>
        <b>{questionCluster.title}</b>
      </p>
      <div
        className="mb-10"
        dangerouslySetInnerHTML={{ __html: questionCluster.content }}
      />
      <p>
        <b>Listening:</b>
      </p>
      <div
        className="mb-10"
        dangerouslySetInnerHTML={{ __html: questionCluster.listening }}
      />
      <hr />
      <p>
        <b>Translate:</b>
      </p>
      <div
        className="mb-10"
        dangerouslySetInnerHTML={{ __html: questionCluster.translate }}
      />
    </div>
  );
}

export default QuestionClusterTestingResult;
