function Question({ question }) {
  return (
    <div key={question._id} className="mb-10">
      <div className="mb-2">
        <div className="mb-5">
          <b className="text-white bg-gray-500 p-1 rounded">
            {question.number}.
          </b>
        </div>
        <p>{question.title && question.title}</p>
        {question.question && <p>{question.question}</p>}
        {question.translate && (
          <p>
            <b>Translate: </b> {question.translate}
          </p>
        )}
      </div>

      {question.options.map((option) => (
        <div
          key={option._id}
          className={`mb-2 p-2 border border-gray-300 rounded-md ${
            question.answer === option.key && "border-green-500"
          }`}
        >
          <p>
            <b>{option.key}</b>. {option.value}
          </p>
          <p>
            <b>Translate:</b> {option.translate}
          </p>
        </div>
      ))}
      {question.explain && (
        <>
          <b>Explain:</b>
          <div
            dangerouslySetInnerHTML={{
              __html: question.explain,
            }}
          />
        </>
      )}
    </div>
  );
}

export default Question;
