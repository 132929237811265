import { useEffect, useState } from "react";
import api from "api";
import { Typography, Checkbox, Button, Modal } from "antd";
import { useHistory } from "react-router-dom";
import { PATH_NAME } from "paths";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { confirm } = Modal;

const FlagPage = () => {
  const [listFlags, setListFlags] = useState([]);
  const [isShowSaveButton, setIsShowSaveButton] = useState(false);
  const [flagsChange, setFlagsChange] = useState();
  const [selectedFlag, setSelectedFlag] = useState("");
  const history = useHistory();
  const getListFlags = () => {
    api(`/flag`).then((data) => {
      setListFlags(data);
    });
  };

  const onSave = () => {
    api.put(`/flag`, flagsChange).then(() => {
      window.location.reload();
    });
  };

  const deleteByID = (id) => {
    api(`/flag/delete/${id}`).then(() => {
      getListFlags();
    });
  };

  const showDeleteConfirm = (item) => {
    confirm({
      title: `Are you sure delete the flag ${item.flag}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteByID(item._id);
      },
    });
  };

  useEffect(() => {
    getListFlags();
  }, []);

  return (
    <div>
      <div className="flex justify-between">
        <Title>Flag</Title>

        <Button
          className="mt-2"
          onClick={() => {
            history.push(PATH_NAME.CREATE_FLAG);
          }}
        >
          Create
        </Button>
      </div>
      <div className="flex justify-between">
        {listFlags && (
          <div className="flex justify-between">
            {listFlags.map((item) => (
              <div
                key={item._id}
                className="mb-8 p-5 border border-gray-300 mr-8 rounded-md hover:border-green-500"
              >
                <div className="flex justify-between">
                  <b>Flag name:</b>
                  <div className="ml-2">{item.flag}</div>
                  <div className="justify-end ml-10">
                    <Checkbox
                      defaultChecked={item.isEnable}
                      disabled={
                        selectedFlag !== "" && selectedFlag !== item._id
                      }
                      onChange={(e) => {
                        setSelectedFlag(item._id);
                        setIsShowSaveButton(true);
                        setFlagsChange({
                          ...item,
                          isEnable: e.target.checked,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="flex justify-between mt-5">
                  <Button
                    className="mr-5"
                    danger
                    onClick={() => showDeleteConfirm(item)}
                  >
                    Delete
                  </Button>
                  <div className="flex">
                    {isShowSaveButton && selectedFlag === item._id && (
                      <Button
                        type="default"
                        className=""
                        onClick={() => {
                          onSave();
                        }}
                      >
                        Save
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default FlagPage;
