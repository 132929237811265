/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useState } from "react";
import { Button, Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import { LIST_QUESTION_CLUSTER_PATH } from "paths";
import QuestionClusterTesting from "components/QuestionClusterTesting";
import QuestionClusterTestingResult from "components/QuestionClusterTestingResult";
import QuestionTesting from "components/QuestionTesting";
import QuestionTestingResult from "components/QuestionTestingResult";

const { Title } = Typography;

function TestingPage() {
  const [test, setTest] = useState([]);
  const [questionClusters, setQuestionClusters] = useState([]);
  const [answerTesting, setAnswerTesting] = useState(new Map());
  const [result, setResult] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const history = useHistory();
  const { testSetID, testID } = useParams();

  useEffect(() => {
    fetch(`/question-cluster/preview/${testID}`)
      .then((response) => response.json())
      .then((data) => {
        setTest(data);
        if (Array.isArray(data.questionClusters)) {
          setQuestionClusters(
            data.questionClusters.sort(function (a, b) {
              return a.index - b.index;
            })
          );
        }
      });
  }, []);

  return (
    <div>
      <div className="flex">
        <LeftOutlined
          onClick={() => {
            history.push(LIST_QUESTION_CLUSTER_PATH(testSetID, testID));
          }}
          style={{
            fontSize: "25px",
            marginTop: "10px",
            marginRight: "30px",
            cursor: "pointer",
          }}
        />
        <Title>Testing</Title>
      </div>

      {test.audio && (
        <div className="mt-5 mb-5">
          <audio controls>
            <source src={test.audio} type="audio/mp3" />
          </audio>
        </div>
      )}

      {questionClusters.map((questionCluster) => (
        <div key={questionCluster._id} className="mb-3 p-5 border border-black">
          {isSubmit ? (
            <QuestionClusterTestingResult questionCluster={questionCluster} />
          ) : (
            <QuestionClusterTesting questionCluster={questionCluster} />
          )}

          {questionCluster.questions.map((question) => (
            <div key={question._id}>
              {isSubmit ? (
                <QuestionTestingResult
                  question={question}
                  answerTesting={answerTesting.get(question._id)}
                />
              ) : (
                <QuestionTesting
                  hiddenOption={
                    questionCluster.part === "1" || questionCluster.part === "2"
                  }
                  hiddenQuestion={questionCluster.part === "2"}
                  question={question}
                  answerTesting={answerTesting.get(question._id)}
                  onChooseAnswer={(key) => {
                    const newAnswerTesting = new Map(answerTesting);
                    newAnswerTesting.set(question._id, key);
                    setAnswerTesting(newAnswerTesting);
                  }}
                />
              )}
            </div>
          ))}
        </div>
      ))}

      <Button
        onClick={() => {
          const rs = [];
          questionClusters.forEach((questionCluster) => {
            questionCluster.questions.forEach((question) => {
              const questionResult = {
                ...question,
                answerTesting: answerTesting.get(question._id),
              };
              rs.push(questionResult);
            });
          });
          let correct = 0;
          const total = rs.length;
          rs.forEach((r) => {
            if (r.answerTesting === r.answer) {
              correct += 1;
            }
          });
          setResult({ correct, total });
          setIsSubmit(true);
        }}
      >
        Submit
      </Button>
      {isSubmit && (
        <div>
          {result.correct}/{result.total}
        </div>
      )}
    </div>
  );
}

export default TestingPage;
