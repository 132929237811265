import { useEffect, useState } from "react";
import {
  Typography,
  Input,
  InputNumber,
  Spin,
  Form,
  Select,
  Button,
} from "antd";
import { useHistory, useParams } from "react-router-dom";
import { LIST_QUESTION_CLUSTER_PATH, LIST_TEST_PATH } from "paths";
import UploadAudio from "components/UploadAudio";
import api from "api";

const { Title } = Typography;
const { Option } = Select;

function TestCreatePage() {
  const [form] = Form.useForm();

  const [test, setTest] = useState({});
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const { testID, testSetID } = useParams();

  useEffect(() => {
    if (testID) {
      api(`/test/${testID}`).then((data) => {
        setTest(data);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  const create = (value) => {
    api.post("/test", { ...value, testSetID }).then((data) => {
      history.push(LIST_QUESTION_CLUSTER_PATH(testSetID, data._id));
    });
  };

  const update = (value) => {
    api.put("/test", { ...value, testSetID, _id: test._id }).then(() => {
      history.push(LIST_TEST_PATH(testSetID));
    });
  };

  const onFinish = (value) => {
    testID ? update(value) : create(value);
  };

  return (
    <div>
      <Title>{testID ? "Update" : "Create"} Test</Title>
      {loading ? (
        <Spin />
      ) : (
        <div className="mb-5">
          <Form
            form={form}
            name="createupdatetest"
            layout="vertical"
            initialValues={test}
            onFinish={onFinish}
          >
            <Form.Item
              label="Index"
              name="index"
              rules={[{ required: true, message: "Please input index!" }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: "Please input title!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Membership"
              name="membership"
              rules={[{ required: true, message: "Please input membership!" }]}
            >
              <Select>
                <Option value="FREE">FREE</Option>
                <Option value="PREMIUM">PREMIUM</Option>
              </Select>
            </Form.Item>

            <Form.Item label="Audio" name="audio">
              <UploadAudio keyAudio="test" />
            </Form.Item>

            <div className="flex mt-5">
              <Button
                type="primary"
                className="mr-5"
                onClick={() => form.submit()}
              >
                {testID ? "Update" : "Create"}
              </Button>
              <Button
                onClick={() => {
                  history.push(LIST_TEST_PATH(testSetID));
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
}

export default TestCreatePage;
