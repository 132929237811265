import { useEffect, useState } from "react";
import { Typography, Modal, Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { CREATE_TIP_PATH, UPDATE_TIP_PATH } from "paths";
import api from "api";

const { Title } = Typography;
const { confirm } = Modal;

function TipsPage() {
  const [tips, setTips] = useState([]);
  const history = useHistory();

  const getTips = () => {
    api("/tip").then((data) => {
      if (Array.isArray(data)) {
        setTips(
          data.sort(function (a, b) {
            return a.index - b.index;
          })
        );
      }
    });
  };

  useEffect(() => {
    getTips();
  }, []);

  const deleteByID = (id) => {
    api(`/tip/delete/${id}`).then(() => {
      getTips();
    });
  };

  const showDeleteConfirm = (tip) => {
    confirm({
      title: `Are you sure delete ${tip.title}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteByID(tip._id);
      },
    });
  };

  return (
    <div>
      <div className="flex justify-between">
        <Title>List Tips</Title>
        <Button
          type="primary"
          className="mt-2"
          onClick={() => {
            history.push(CREATE_TIP_PATH());
          }}
        >
          Create
        </Button>
      </div>

      {tips.map((tip, index) => (
        <div
          key={index}
          className="mb-8 p-5 border border-gray-300 rounded-md hover:border-green-500"
        >
          <p>
            <b className="text-white bg-gray-500 p-1 rounded">{tip.index}.</b>
          </p>
          <div className="mt-2">
            <b>{tip.title}</b>
          </div>

          <iframe
            title="content"
            allowFullScreen
            style={{ height: "500px", width: "100%" }}
            className="mb-10"
            srcDoc={tip.content}
          >
            <p>Your browser does not support iframes.</p>{" "}
          </iframe>

          <div className="flex justify-between mt-5">
            <div className="flex">
              <Button
                className="mr-5"
                onClick={() => {
                  history.push(UPDATE_TIP_PATH(tip._id));
                }}
              >
                Edit
              </Button>
              <Button danger onClick={() => showDeleteConfirm(tip)}>
                Delete
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default TipsPage;
