function QuestionClusterTesting({ questionCluster }) {
  return (
    <div>
      <p>
        <b>{questionCluster.title}</b>
      </p>
      <div
        className="mb-10"
        dangerouslySetInnerHTML={{ __html: questionCluster.content }}
      />
    </div>
  );
}

export default QuestionClusterTesting;
