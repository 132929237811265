import { useEffect, useState } from "react";
import { Typography, Pagination, Spin, Button } from "antd";
import { useHistory } from "react-router-dom";
import api from "api";
import UserItem from "./components/UserItem";
import SearchForm from "./components/SearchForm";

const { Title } = Typography;

function UsersPage() {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const history = useHistory();

  const userUpdate = (newUser) => {
    const newUsers = [...users];
    for (let i = 0; i < newUsers.length; i += 1) {
      if (newUsers[i]._id === newUser._id) {
        newUsers[i] = newUser;
      }
    }
    setUsers(newUsers);
  };

  const getUsers = (nextPage) => {
    setLoading(true);
    api("/user/list", {
      params: { page: nextPage },
    })
      .then((data) => {
        const { docs } = data;
        if (Array.isArray(docs)) {
          setUsers(docs);
        }
        setTotal(data.total);
        setPage(nextPage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getUsers(page);
  }, []);

  const deleteByID = (id) => {
    api(`/user/delete/${id}`).then(() => {
      getUsers();
    });
  };

  return (
    <div>
      <div className="flex justify-between">
        <Title>List User</Title>
        <Button
          type="primary"
          className="mt-2"
          onClick={() => {
            history.push("/user/create");
          }}
        >
          Create
        </Button>
      </div>

      <SearchForm />

      <Spin spinning={loading}>
        <div className="flex flex-wrap content-evenly">
          {users.map((user) => (
            <UserItem
              key={user._id}
              user={user}
              onDelete={deleteByID}
              onUserUpdate={userUpdate}
            />
          ))}
        </div>
        <div className="mb-6 mt-6 flex justify-end">
          <Pagination
            onChange={(nextPage) => {
              getUsers(nextPage);
            }}
            current={page}
            total={total}
          />
        </div>
      </Spin>
    </div>
  );
}

export default UsersPage;
