import { useEffect, useState } from "react";
import { Typography, Modal, Button, Radio } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import {
  CREATE_SET_TEST_PATH,
  LIST_TEST_PATH,
  UPDATE_SET_TEST_PATH,
} from "paths";
import api from "api";
import { getKeysFromArray } from "utils";

const { Title } = Typography;
const { confirm } = Modal;

function TestSetPage() {
  const [testSets, setTestSets] = useState([]);
  const [type, setType] = useState("FULL_TEST");
  const [types, setTypes] = useState([]);
  const history = useHistory();

  const getTestSets = () => {
    api
      .get("/test-set")
      .then((data) => {
        if (Array.isArray(data)) {
          setTestSets(
            data.sort(function (a, b) {
              return a.index - b.index;
            })
          );

          setTypes(getKeysFromArray(data, "type"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTestSets();
  }, []);

  const deleteByID = (id) => {
    api(`/test-set/delete/${id}`).then(() => {
      getTestSets();
    });
  };

  const showDeleteConfirm = (testSet) => {
    confirm({
      title: `Are you sure delete ${testSet.title}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteByID(testSet._id);
      },
    });
  };

  return (
    <div>
      <div className="flex justify-between content-center">
        <Title>List Test Set</Title>
        <Button
          type="primary"
          className="mt-2"
          onClick={() => {
            history.push(CREATE_SET_TEST_PATH());
          }}
        >
          Create
        </Button>
      </div>

      <div className="flex justify-center mb-10">
        <Radio.Group
          value={type}
          onChange={(v) => {
            setType(v.target.value);
          }}
        >
          {types.map((t) => (
            <Radio.Button key={t} value={t}>
              {t}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>

      <div className="flex flex-wrap content-evenly">
        {testSets
          .filter((t) => t.type === type || type === "")
          .map((testSet) => (
            <div
              key={testSet._id}
              style={{ minWidth: "300px" }}
              className="mb-8 p-5 border border-gray-300 mr-8 rounded-md hover:border-green-500"
            >
              <p>
                <b className="text-white bg-gray-500 p-1 rounded">
                  {testSet.index}.
                </b>
              </p>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "8px",
                }}
              >
                <b
                  style={{
                    marginTop: "2px",
                    marginBottom: "2px",
                    borderBottom: "1px solid black",
                    paddingBottom: "1px",
                  }}
                >
                  {testSet.type}
                </b>
              </div>
              <b className="text-lg">{testSet.title}</b>
              <p>Level {testSet.level}</p>

              <div className="flex justify-between mt-5">
                <Button
                  className="mr-5"
                  onClick={() => {
                    history.push(LIST_TEST_PATH(testSet._id));
                  }}
                >
                  Detail
                </Button>
                <div className="flex">
                  <Button
                    className="mr-5"
                    onClick={() => {
                      history.push(UPDATE_SET_TEST_PATH(testSet._id));
                    }}
                  >
                    Edit
                  </Button>
                  <Button danger onClick={() => showDeleteConfirm(testSet)}>
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default TestSetPage;
