import { Form, Input, Button, Space, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import { LIST_QUESTION_PATH } from "paths";
import RichTextEditor from "components/RichTextEditor";
import api from "api";

const { Title } = Typography;
const { TextArea } = Input;

function QuestionCreatePage() {
  const [form] = Form.useForm();

  const [question, setQuestion] = useState();
  const history = useHistory();
  const { questionClusterID, questionID, testSetID, testID } = useParams();
  const [loading, setLoading] = useState(true);

  const onFinish = (value) => {
    if (questionID) {
      api
        .put("/question", {
          ...value,
          questionClusterID,
          _id: question._id,
        })
        .then(() => {
          history.push(
            LIST_QUESTION_PATH(testSetID, testID, questionClusterID)
          );
        });
    } else {
      api.post("/question", { ...value, questionClusterID }).then(() => {
        history.push(LIST_QUESTION_PATH(testSetID, testID, questionClusterID));
      });
    }
  };

  useEffect(() => {
    if (questionID) {
      api(`/question/${questionID}`).then((data) => {
        setQuestion(data);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <div>
      <Title>{questionID ? "Update" : "Create"} Question</Title>
      {loading ? (
        <Spin />
      ) : (
        <Form
          name="createupdatequestion"
          layout="vertical"
          form={form}
          initialValues={question}
          onFinish={onFinish}
        >
          <Form.Item
            name="number"
            label="Number"
            rules={[{ required: true, message: "Please input No. question!" }]}
          >
            <Input placeholder="Number" />
          </Form.Item>
          <Form.Item name="title" label="Title">
            <Input placeholder="Title" />
          </Form.Item>
          <Form.Item name="question" label="Question">
            <TextArea autoSize placeholder="Question" />
          </Form.Item>
          <Form.Item name="translate" label="Translate">
            <TextArea autoSize placeholder="Translate" />
          </Form.Item>
          <Form.List name="options">
            {(fields, { add, remove }) => (
              <>
                <div className="flex flex-col">
                  {fields.map((field) => (
                    <Space key={field.key} align="baseline">
                      <Form.Item
                        {...field}
                        style={{ width: "50px" }}
                        name={[field.name, "key"]}
                        fieldKey={[field.fieldKey, "key"]}
                        rules={[{ required: true, message: "Missing key" }]}
                      >
                        <Input placeholder="Key" />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        style={{ width: "500px" }}
                        name={[field.name, "value"]}
                        fieldKey={[field.fieldKey, "value"]}
                      >
                        <Input placeholder="Value" />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        style={{ width: "500px" }}
                        name={[field.name, "translate"]}
                        fieldKey={[field.fieldKey, "translate"]}
                      >
                        <Input placeholder="Translate" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                    </Space>
                  ))}
                </div>

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add option
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item name="answer" label="Answer">
            <Input placeholder="answer" />
          </Form.Item>

          <Form.Item name="explain" label="Explain">
            <RichTextEditor />
          </Form.Item>

          <Form.Item>
            <div className="flex mt-5">
              <Button
                type="primary"
                className="mr-5"
                onClick={() => form.submit()}
              >
                {questionID ? "Update" : "Create"}
              </Button>
              <Button
                onClick={() => {
                  history.push(
                    LIST_QUESTION_PATH(testSetID, testID, questionClusterID)
                  );
                }}
              >
                Cancel
              </Button>
            </div>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}

export default QuestionCreatePage;
