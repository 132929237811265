/* eslint-disable jsx-a11y/media-has-caption */
import { message, Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";

function UploadAudio({ value, onChange, keyAudio }) {
  const handleChangeUploadAudio = (info) => {
    if (info.file.status === "done") {
      onChange(`${process.env.REACT_APP_S3_URL}${info.file.response.uri}`);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const propsUploadAudio = {
    showUploadList: false,
    name: "file",
    action: `${process.env.REACT_APP_S3_URL}/media/upload-audio`,
    headers: {
      authorization: localStorage.getItem("token"),
      key: keyAudio,
    },
    onChange: handleChangeUploadAudio,
  };

  return (
    <div className="p-5 border border-gray-300 rounded-md">
      <Upload {...propsUploadAudio}>
        <Button icon={<UploadOutlined />}>Click to Upload Audio</Button>
      </Upload>
      {value && (
        <div className="mt-5">
          <audio controls>
            <source src={value} type="audio/mp3" />
          </audio>
        </div>
      )}
    </div>
  );
}

export default UploadAudio;
