import { Button, Form, Input, Select, Row, Col } from "antd";

const { Option } = Select;

function SearchForm() {
  const [form] = Form.useForm();

  return (
    <div className="mb-8 pt-5 pr-5 pl-5 border border-gray-300 rounded-md">
      <Form form={form} name="createupdatetestset" layout="vertical">
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Form.Item label="Name" name="name">
              <Input />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={12}>
            <Form.Item label="Email" name="email">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Form.Item label="Phone" name="phone">
              <Input />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={12}>
            <Form.Item label="Role" name="role">
              <Select>
                <Option value="ADMIN">ADMIN</Option>
                <Option value="USER">USER</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <div className="flex mt-5 justify-end">
            <Button
              type="primary"
              className="mr-5"
              onClick={() => form.submit()}
            >
              Search
            </Button>
            <Button>Clear</Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default SearchForm;
