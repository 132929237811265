export const PATH_NAME = {
  // HOME,
  HOME: "/",

  // DEFAULT AVATAR
  DEFAULT_AVATAR: "/default-avatar",
  CREATE_DEFAULT_AVATAR: "/default-avatar/create",
  UPDATE_DEFAULT_AVATAR: "/default-avatar/update/:defaultAvatarID",

  // USER
  NOTIFICATIONS: "/notification",
  CREATE_NOTIFICATION: "/notification/create",

  // USER
  USERS: "/user",
  CREATE_USER: "/user/create",
  UPDATE_USER: "/user/update/:userID",

  // GRAMMAR
  TIP: "/tip",
  CREATE_TIP: "/tip/create",
  UPDATE_TIP: "/tip/update/:tipID",

  // VOCA-CATEGORY
  LIST_VOCA_CATEGORY: "/voca-category",
  CREATE_VOCA_CATEGORY: "/voca-category/create",
  UPDATE_VOCA_CATEGORY: "/voca-category/update/:vocaCategoryID",

  // VOCA
  VOCA: "/voca-category/:vocaCategoryID/voca",
  CREATE_VOCA: "/grammar-category/:vocaCategoryID/voca/create",
  UPDATE_VOCA: "/grammar-category/:vocaCategoryID/voca/update/:vocaID",

  // GRAMMAR-TOPIC
  LIST_GRAMMAR_TOPIC: "/grammar-topic",
  CREATE_GRAMMAR_TOPIC: "/grammar-topic/create",
  UPDATE_GRAMMAR_TOPIC: "/grammar-topic/update/:grammarTopicID",

  // GRAMMAR
  GRAMMAR: "/grammar-topic/:grammarTopicID/grammar",
  CREATE_GRAMMAR: "/grammar-topic/:grammarTopicID/grammar/create",
  UPDATE_GRAMMAR: "/grammar-topic/:grammarTopicID/grammar/update/:grammarID",

  // TEST-SET
  LIST_SET_TEST: "/test-set",
  CREATE_SET_TEST: "/test-set/create",
  UPDATE_SET_TEST: "/test-set/update/:testSetID",

  // TEST
  LIST_TEST: "/test-set/:testSetID/test",
  CREATE_TEST: "/test-set/:testSetID/test/create",
  UPDATE_TEST: "/test-set/:testSetID/test/update/:testID",

  // QUESTION-CLUSTER
  LIST_QUESTION_CLUSTER: "/test-set/:testSetID/test/:testID/question-cluster",
  CREATE_QUESTION_CLUSTER:
    "/test-set/:testSetID/test/:testID/question-cluster/create",
  UPDATE_QUESTION_CLUSTER:
    "/test-set/:testSetID/test/:testID/question-cluster/update/:questionClusterID",

  // QUESTION
  LIST_QUESTION:
    "/test-set/:testSetID/test/:testID/question-cluster/:questionClusterID/question",
  CREATE_QUESTION:
    "/test-set/:testSetID/test/:testID/question-cluster/:questionClusterID/question/create",
  UPDATE_QUESTION:
    "/test-set/:testSetID/test/:testID/question-cluster/:questionClusterID/question/update/:questionID",

  // PREVIEW
  PREVIEW_TEST: "/test-set/:testSetID/test/:testID/preview",

  // TESTING
  TESTING: "/test-set/:testSetID/test/:testID/testing",

  // FLAG
  FLAG: "/flag",
  CREATE_FLAG: "/flag/create",
  UPDATE_FLAG: "/flag/update/:id",
};

// TIP
export const TIP_PATH = () => PATH_NAME.TIP;

export const CREATE_TIP_PATH = () => PATH_NAME.CREATE_TIP;

export const UPDATE_TIP_PATH = (tipID) =>
  PATH_NAME.UPDATE_TIP.replace(":tipID", tipID);

// VOCA
export const VOCA_PATH = (vocaCategoryID) =>
  PATH_NAME.VOCA.replace(":vocaCategoryID", vocaCategoryID);

export const CREATE_VOCA_PATH = (vocaCategoryID) =>
  PATH_NAME.CREATE_VOCA.replace(":vocaCategoryID", vocaCategoryID);

export const UPDATE_VOCA_PATH = (vocaCategoryID, vocaID) =>
  PATH_NAME.UPDATE_VOCA.replace(":vocaCategoryID", vocaCategoryID).replace(
    ":vocaID",
    vocaID
  );

// VOCA CATEGORY
export const VOCA_CATEGORY_PATH = () => PATH_NAME.LIST_VOCA_CATEGORY;

export const CREATE_VOCA_CATEGORY_PATH = () => PATH_NAME.CREATE_VOCA_CATEGORY;

export const UPDATE_VOCA_CATEGORY_PATH = (vocaCategoryID) =>
  PATH_NAME.UPDATE_VOCA_CATEGORY.replace(":vocaCategoryID", vocaCategoryID);

// GRAMMAR
export const GRAMMAR_PATH = (grammarTopicID) =>
  PATH_NAME.GRAMMAR.replace(":grammarTopicID", grammarTopicID);

export const CREATE_GRAMMAR_PATH = (grammarTopicID) =>
  PATH_NAME.CREATE_GRAMMAR.replace(":grammarTopicID", grammarTopicID);

export const UPDATE_GRAMMAR_PATH = (grammarTopicID, grammarID) =>
  PATH_NAME.UPDATE_GRAMMAR.replace(":grammarTopicID", grammarTopicID).replace(
    ":grammarID",
    grammarID
  );

// GRAMMAR-TOPIC
export const LIST_GRAMMAR_TOPIC_PATH = () => PATH_NAME.LIST_GRAMMAR_TOPIC;

export const CREATE_GRAMMAR_TOPIC_PATH = () => PATH_NAME.CREATE_GRAMMAR_TOPIC;

export const UPDATE_GRAMMAR_TOPIC_PATH = (grammarTopicID) =>
  PATH_NAME.UPDATE_GRAMMAR_TOPIC.replace(":grammarTopicID", grammarTopicID);

// TEST-SET
export const LIST_SET_TEST_PATH = () => PATH_NAME.LIST_SET_TEST;

export const CREATE_SET_TEST_PATH = () => PATH_NAME.CREATE_SET_TEST;

export const UPDATE_SET_TEST_PATH = (testSetID) =>
  PATH_NAME.UPDATE_SET_TEST.replace(":testSetID", testSetID);

// TEST
export const LIST_TEST_PATH = (testSetID) =>
  PATH_NAME.LIST_TEST.replace(":testSetID", testSetID);

export const CREATE_TEST_PATH = (testSetID) =>
  PATH_NAME.CREATE_TEST.replace(":testSetID", testSetID);

export const UPDATE_TEST_PATH = (testSetID, testID) =>
  PATH_NAME.UPDATE_TEST.replace(":testSetID", testSetID).replace(
    ":testID",
    testID
  );

// QUESTION-CLUSTER
export const LIST_QUESTION_CLUSTER_PATH = (testSetID, testID) =>
  PATH_NAME.LIST_QUESTION_CLUSTER.replace(":testSetID", testSetID).replace(
    ":testID",
    testID
  );

export const CREATE_QUESTION_CLUSTER_PATH = (testSetID, testID) =>
  PATH_NAME.CREATE_QUESTION_CLUSTER.replace(":testSetID", testSetID).replace(
    ":testID",
    testID
  );

export const UPDATE_QUESTION_CLUSTER_PATH = (
  testSetID,
  testID,
  questionClusterID
) =>
  PATH_NAME.UPDATE_QUESTION_CLUSTER.replace(":testSetID", testSetID)
    .replace(":testID", testID)
    .replace(":questionClusterID", questionClusterID);

// QUESTION
export const LIST_QUESTION_PATH = (testSetID, testID, questionClusterID) =>
  PATH_NAME.LIST_QUESTION.replace(":testSetID", testSetID)
    .replace(":testID", testID)
    .replace(":questionClusterID", questionClusterID);

export const CREATE_QUESTION_PATH = (testSetID, testID, questionClusterID) =>
  PATH_NAME.CREATE_QUESTION.replace(":testSetID", testSetID)
    .replace(":testID", testID)
    .replace(":questionClusterID", questionClusterID);

export const UPDATE_QUESTION_PATH = (
  testSetID,
  testID,
  questionClusterID,
  questionID
) =>
  PATH_NAME.UPDATE_QUESTION.replace(":testSetID", testSetID)
    .replace(":testID", testID)
    .replace(":questionClusterID", questionClusterID)
    .replace(":questionID", questionID);

// PREVIEW
export const PREVIEW_TEST_PATH = (testSetID, testID) =>
  PATH_NAME.PREVIEW_TEST.replace(":testSetID", testSetID).replace(
    ":testID",
    testID
  );

// TESTING
export const TESTING_PATH = (testSetID, testID) =>
  PATH_NAME.TESTING.replace(":testSetID", testSetID).replace(":testID", testID);

// Flag
export const UPDATE_FLAG_PATH = (id) =>
  PATH_NAME.UPDATE_FLAG.replace(":id", id);
