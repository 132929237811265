import { useEffect, useState } from "react";
import { Typography, Button, Pagination, Table, Tag } from "antd";
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { PATH_NAME } from "paths";
import api from "api";

const { Title } = Typography;

function NotificationPage() {
  const [notifications, setNotifications] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const getNotifications = (nextPage) => {
    setLoading(true);
    api(`/notification`, {
      params: { page: nextPage },
    })
      .then((data) => {
        const { docs } = data;
        if (Array.isArray(docs)) {
          setNotifications(docs);
        }
        setTotal(data.total);
        setPage(nextPage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const columns = [
    {
      title: <b>Title</b>,
      dataIndex: "title",
      key: "title",
    },
    {
      title: <b>Body</b>,
      dataIndex: "body",
      key: "body",
    },
    {
      title: <b>Type</b>,
      dataIndex: "type",
      key: "type",
    },
    {
      title: <b>Success Count</b>,
      dataIndex: "successCount",
      key: "successCount",
    },
    {
      title: <b>Failure Count</b>,
      dataIndex: "failureCount",
      key: "failureCount",
    },
    {
      title: <b>Status</b>,
      key: "status",
      dataIndex: "status",
      render: (status) =>
        status === "SUCCESS" ? (
          <Tag icon={<CheckCircleOutlined />} color="success">
            {status}
          </Tag>
        ) : status === "ERROR" ? (
          <Tag icon={<CloseCircleOutlined />} color="error">
            {status}
          </Tag>
        ) : (
          <Tag icon={<SyncOutlined spin />} color="processing">
            {status}
          </Tag>
        ),
    },
  ];

  return (
    <div>
      <div className="flex justify-between">
        <Title>List Notification</Title>
        <Button
          type="primary"
          className="mt-2"
          onClick={() => {
            history.push(PATH_NAME.CREATE_NOTIFICATION);
          }}
        >
          Create
        </Button>
      </div>
      <Table
        rowKey={(record) => record._id}
        columns={columns}
        dataSource={notifications}
        loading={loading}
        pagination={false}
      />
      <div className="mb-6 mt-6 flex justify-end">
        <Pagination
          onChange={(nextPage) => {
            getNotifications(nextPage);
          }}
          current={page}
          total={total}
        />
      </div>
    </div>
  );
}

export default NotificationPage;
