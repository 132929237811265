/* eslint-disable no-unused-vars */
/* eslint-disable no-continue */
/* eslint-disable no-await-in-loop */
import { Typography, Button, Col, Row } from "antd";
import cheerio from "cheerio";
import { to } from "utils";
import api from "api";

const { Title } = Typography;

function RawSkillTest() {
  const rawQuestionP3 = (data, $, number, answer) => {
    const listOption = $(data).find("table");

    const optionQuestions = [];
    for (let i = 1; i < listOption.length; i += 1) {
      optionQuestions.push({
        key: i === 1 ? "A" : i === 2 ? "B" : i === 3 ? "C" : "D",
        value: $(listOption[i])
          .find("td")[3]
          .firstChild?.data.toString()
          .trim(),
        translate: "",
      });
    }

    const questionDom = $(data).find("[align='left'] td");

    const question = {
      number,
      question: questionDom[0]?.children[1]
        ? questionDom[0].children[1].data.toString()
        : "",
      translate: "",
      options: optionQuestions,
      explain: "",
      answer,
    };

    return question;
  };
  const rawQuestionP1P2 = (data, $, number) => {
    const listOption = $("#divScript_id_1 tr");

    const pattAnswer = /(?<=EXERCISEResults = ")(.*)(?=";)/;
    const answer = data.match(pattAnswer)[0].replace("|", "");

    const optionQuestions = [];
    for (let i = 0; i < listOption.length; i += 1) {
      optionQuestions.push({
        key: i === 0 ? "A" : i === 1 ? "B" : i === 2 ? "C" : "D",
        value: $(listOption[i])
          .find("td")[2]
          .firstChild?.data.toString()
          .trim(),
        translate: "",
      });
    }

    const questionDom = $("#divScript_id_1 > div ");

    const question = {
      number,
      question: questionDom.text(),
      translate: "",
      options: optionQuestions,
      explain: "",
      answer,
    };

    return question;
  };

  const getQuestionCluster1 = async (url, index) => {
    const [errResponse, response] = await to(
      api.get(`/raw-tool/get-source-web`, {
        url,
      })
    );

    if (errResponse) {
      return [errResponse];
    }
    const $ = cheerio.load(response);
    const audiosDom = $("audio source");
    const imageDom = $("#image_id_1");

    const question = rawQuestionP1P2(response, $, index);

    const questionCluster = {
      part: "1",
      title: `Question ${index}:`,
      audio: `https://elearn.edu.vn/${audiosDom[0].attribs.src}`,
      content: imageDom
        .parent()
        .html()
        .replace("Asset/Image", "https://elearn.edu.vn/Asset/Image"),
      translate: "",
      listening: "",
      questions: [question],
    };
    return [null, questionCluster];
  };

  const getQuestionCluster2 = async (url, index) => {
    const [errResponse, response] = await to(
      api.get(`/raw-tool/get-source-web`, {
        url,
      })
    );

    if (errResponse) {
      return [errResponse];
    }
    const $ = cheerio.load(response);
    const audiosDom = $("audio source");

    const question = rawQuestionP1P2(response, $, index);

    const questionCluster = {
      part: "2",
      title: `Question ${index}:`,
      audio: `https://elearn.edu.vn/${audiosDom[0].attribs.src}`,
      content: "",
      translate: "",
      listening: "",
      questions: [question],
    };
    return [null, questionCluster];
  };

  const getQuestionCluster34 = async (url, part) => {
    const [errResponse, response] = await to(
      api.get(`/raw-tool/get-source-web`, {
        url,
      })
    );

    if (errResponse) {
      return [errResponse];
    }
    const $ = cheerio.load(response);

    const audiosDom = $("audio source");

    const patTotalQuestion = /(?<=var EXERCISETotal = ")(.*)(?=";)/;
    const totalQuestionString = response.match(patTotalQuestion)[0];
    const totalQuestion = parseInt(totalQuestionString, 10);

    const pattAnswer = /(?<=EXERCISEResults = ")(.*)(?=";)/;
    const answers = response
      .match(pattAnswer)[0]
      .replaceAll("|", "")
      .toString();

    const questions = [];
    for (
      let questionIndex = 1;
      questionIndex < totalQuestion;
      questionIndex += 1
    ) {
      const questionDom = $(`#question_id_${questionIndex}`);
      questions.push(
        rawQuestionP3(questionDom, $, questionIndex, answers[questionIndex - 1])
      );
    }

    const listeningDom = $("[class='MsoNormal']");

    if (audiosDom[0]) {
      const questionCluster = {
        part,
        title:
          totalQuestion === 2
            ? `Question:`
            : `Question 1 - ${totalQuestion - 1}`,
        audio: `https://elearn.edu.vn/${audiosDom[0].attribs.src}`,
        content: "",
        translate: "",
        listening: listeningDom
          .html()
          .replace("<strong><u>Script:</u></strong><br>", ""),
        questions,
      };
      return [null, questionCluster];
    }
    return ["no_audio"];
  };

  const getQuestionCluster5 = async (url) => {
    const [errResponse, response] = await to(
      api.get(`/raw-tool/get-source-web`, {
        url,
      })
    );

    if (errResponse) {
      return [errResponse];
    }
    const $ = cheerio.load(response);

    const patTotalQuestion = /(?<=var EXERCISETotal = ")(.*)(?=";)/;
    const totalQuestionString = response.match(patTotalQuestion)[0];
    const totalQuestion = parseInt(totalQuestionString, 10);

    const pattAnswer = /(?<=EXERCISEResults = ")(.*)(?=";)/;
    const answers = response
      .match(pattAnswer)[0]
      .replaceAll("|", "")
      .toString();

    const questions = [];
    for (
      let questionIndex = 1;
      questionIndex < totalQuestion;
      questionIndex += 1
    ) {
      const questionDom = $(`#question_id_${questionIndex}`);
      questions.push(
        rawQuestionP3(questionDom, $, questionIndex, answers[questionIndex - 1])
      );
    }

    const questionCluster = {
      part: 5,
      title:
        totalQuestion === 2 ? `Question:` : `Question 1 - ${totalQuestion - 1}`,
      audio: "",
      content: "",
      translate: "",
      listening: "",
      questions,
    };
    return [null, questionCluster];
  };

  const getQuestionCluster6 = async (url) => {
    const [errResponse, response] = await to(
      api.get(`/raw-tool/get-source-web`, {
        url,
      })
    );

    if (errResponse) {
      return [errResponse];
    }
    const $ = cheerio.load(response);

    const patTotalQuestion = /(?<=var EXERCISETotal = ")(.*)(?=";)/;
    const totalQuestionString = response.match(patTotalQuestion)[0];
    const totalQuestion = parseInt(totalQuestionString, 10);

    const pattAnswer = /(?<=EXERCISEResults = ")(.*)(?=";)/;
    const answers = response
      .match(pattAnswer)[0]
      .replaceAll("|", "")
      .toString();

    const questions = [];
    for (
      let questionIndex = 1;
      questionIndex < totalQuestion;
      questionIndex += 1
    ) {
      const questionDom = $(`#question_id_${questionIndex}`);
      questions.push(
        rawQuestionP3(questionDom, $, questionIndex, answers[questionIndex - 1])
      );
    }

    const contentDom = $(".divContent");

    const questionCluster = {
      part: 6,
      title:
        totalQuestion === 2 ? `Question:` : `Question 1 - ${totalQuestion - 1}`,
      audio: "",
      content: contentDom.html(),
      translate: "",
      listening: "",
      questions,
    };
    return [null, questionCluster];
  };

  const getQuestionCluster7 = (url) => async () => {
    const [errResponse, response] = await to(
      api.get(`/raw-tool/get-source-web`, {
        url,
      })
    );

    if (errResponse) {
      return [errResponse];
    }
    const $ = cheerio.load(response);

    const patTotalQuestion = /(?<=var EXERCISETotal = ")(.*)(?=";)/;
    const totalQuestionString = response.match(patTotalQuestion)[0];
    const totalQuestion = parseInt(totalQuestionString, 10);

    const pattAnswer = /(?<=EXERCISEResults = ")(.*)(?=";)/;
    const answers = response
      .match(pattAnswer)[0]
      .replaceAll("|", "")
      .toString();

    const questions = [];
    for (
      let questionIndex = 1;
      questionIndex < totalQuestion;
      questionIndex += 1
    ) {
      const questionDom = $(`#question_id_${questionIndex}`);
      questions.push(
        rawQuestionP3(questionDom, $, questionIndex, answers[questionIndex - 1])
      );
    }

    const contentDom = $(".divContent");

    const removePath = /(?<=<!--)(.*)(?=-->)/gms;
    const removeStrings = contentDom.html().match(removePath);

    let content = contentDom.html();
    if (removeStrings) {
      content = content
        .replaceAll(removeStrings[0], "")
        .replaceAll("<!--", "")
        .replaceAll("-->", "");
    }

    const questionCluster = {
      part: 7,
      title:
        totalQuestion === 2 ? `Question:` : `Question 1 - ${totalQuestion - 1}`,
      audio: "",
      content,
      translate: "",
      listening: "",
      questions,
    };

    return [null, questionCluster];
  };

  const raw = (part) => async () => {
    const pageURL =
      "http://elearn.edu.vn/ToeicLesson.aspx?learnID=1&courseID=4&strategyID=28&categoryID=31&levelID=3&subLevel=-1&typeMenu=2";
    const [errResponse, response] = await to(
      api.get(`/raw-tool/get-source-web`, {
        url: pageURL,
      })
    );

    if (errResponse) {
      console.error("ERROR:", errResponse);
      return;
    }

    const $1 = cheerio.load(response);
    const skillsDom = $1(`.mid-content select option`);

    for (let indexSkill = 0; indexSkill < skillsDom.length; indexSkill += 1) {
      const skillText = skillsDom[indexSkill].children[0].data
        .replaceAll(" ", "-")
        .toUpperCase();

      const [errResponseSkill, responseSkill] = await to(
        api.get(`/raw-tool/get-source-web`, {
          url: `http://elearn.edu.vn/ToeicLesson.aspx?learnID=1&courseID=4&strategyID=28&categoryID=31&levelID=3&subLevel=-1&typeMenu=2`,
        })
      );

      if (errResponseSkill) {
        console.error("ERROR:", errResponseSkill);
        return;
      }

      const pattTotalPage = /(?<=var totalPages = ")(.*)(?=";)/;
      const totalPageString = responseSkill.data.match(pattTotalPage)[0];
      const totalPages = parseInt(totalPageString, 10);
      const questionClusters = [];

      const $ = cheerio.load(responseSkill.data);

      for (let index = 1; index <= totalPages; index += 1) {
        const linkDom = $(`#liQues_${index}`);
        const link = linkDom[0].firstChild.attribs.href
          ? `https://elearn.edu.vn/${linkDom[0].firstChild.attribs.href}`
          : pageURL;
        if (part === 1) {
          const [errQuestionCluster, questionCluster] =
            await getQuestionCluster1(link, index);
          if (errQuestionCluster) {
            console.error("ERROR:", errQuestionCluster);
            return;
          }
          questionClusters.push(questionCluster);
        }
        if (part === 2) {
          const [errQuestionCluster, questionCluster] =
            await getQuestionCluster2(link, index);
          if (errQuestionCluster) {
            console.error("ERROR:", errQuestionCluster);
            return;
          }
          questionClusters.push(questionCluster);
        }
        if (part === 3) {
          const [errQuestionCluster, questionCluster] =
            await getQuestionCluster34(link, "3");
          if (errQuestionCluster) {
            console.error("ERROR:", errQuestionCluster);
            if (errQuestionCluster === "no_audio") {
              continue;
            } else {
              return;
            }
          }
          questionClusters.push(questionCluster);
        }
        if (part === 4) {
          const [errQuestionCluster, questionCluster] =
            await getQuestionCluster34(link, "4");
          if (errQuestionCluster) {
            console.error("ERROR:", errQuestionCluster);
            if (errQuestionCluster === "no_audio") {
              continue;
            } else {
              return;
            }
          }
          questionClusters.push(questionCluster);
        }

        if (part === 5) {
          const [errQuestionCluster, questionCluster] =
            await getQuestionCluster5(link);
          if (errQuestionCluster) {
            console.error("ERROR:", errQuestionCluster);
            return;
          }
          questionClusters.push(questionCluster);
        }

        if (part === 6) {
          const [errQuestionCluster, questionCluster] =
            await getQuestionCluster6(link);
          if (errQuestionCluster) {
            console.error("ERROR:", errQuestionCluster);
            return;
          }
          questionClusters.push(questionCluster);
        }
        if (part === 7) {
          const [errQuestionCluster, questionCluster] =
            await getQuestionCluster7(link)();
          if (errQuestionCluster) {
            console.error("ERROR:", errQuestionCluster);
            return;
          }
          questionClusters.push(questionCluster);
        }
      }
    }
  };

  return (
    <div>
      <Title>Raw Skill Test</Title>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Button className="w-full" onClick={raw(1)}>
            Raw P1
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={raw(2)}>
            Raw P2
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={raw(3)}>
            Raw P3
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={raw(4)}>
            Raw P4
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={raw(5)}>
            Raw P5
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={raw(6)}>
            Raw P6
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={raw(7)}>
            Raw P7
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default RawSkillTest;

// note Part 3 & Part 4 title question cluster wrong, need fix when import
