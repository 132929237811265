import { useEffect, useState } from "react";
import { Typography, Modal, Button, Spin } from "antd";
import { LeftOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useParams, useHistory } from "react-router-dom";
import {
  LIST_QUESTION_CLUSTER_PATH,
  CREATE_QUESTION_PATH,
  UPDATE_QUESTION_PATH,
} from "paths";
import QuestionCluster from "components/QuestionCluster";
import Question from "components/Question";
import api from "api";

const { Title } = Typography;
const { confirm } = Modal;

function QuestionClusterDetailPage() {
  const { testSetID, testID, questionClusterID } = useParams();

  const [questions, setQuestions] = useState([]);
  const [loadingQuestion, setLoadingQuestion] = useState(false);
  const [questionCluster, setQuestionCluster] = useState({});
  const history = useHistory();

  const fetchQuestion = () => {
    setLoadingQuestion(true);
    api(`/question/search-by-question-cluster-id/${questionClusterID}`)
      .then((data) => {
        if (Array.isArray(data)) {
          setQuestions(data);
        }
      })
      .finally(() => {
        setLoadingQuestion(false);
      });
  };

  useEffect(() => {
    api(`/question-cluster/${questionClusterID}`).then((data) => {
      setQuestionCluster(data);
    });
    fetchQuestion();
  }, []);

  const deleteByID = (id) => {
    api(`/question/delete/${id}`).then(() => {
      const newQuestions = questions.filter((item) => item._id !== id);
      setQuestions(newQuestions);
    });
  };

  const showDeleteConfirm = (question) => {
    confirm({
      title: `Are you sure delete the question No.${question.number}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteByID(question._id);
      },
    });
  };

  return (
    <div>
      <div className="flex">
        <LeftOutlined
          onClick={() => {
            history.push(LIST_QUESTION_CLUSTER_PATH(testSetID, testID));
          }}
          style={{
            fontSize: "25px",
            marginTop: "10px",
            marginRight: "30px",
            cursor: "pointer",
          }}
        />
        <Title>
          Question Cluster <u>#{questionCluster.index}</u>
        </Title>
      </div>

      <div className="mb-3 p-5 border border-gray-300 rounded-md">
        <QuestionCluster questionCluster={questionCluster} />
      </div>

      <div className="flex justify-between mt-10 mb-5">
        <Title level={3}>List Question</Title>
        <Button
          type="primary"
          onClick={() => {
            history.push(
              CREATE_QUESTION_PATH(testSetID, testID, questionClusterID)
            );
          }}
        >
          Create Question
        </Button>
      </div>

      <Spin spinning={loadingQuestion}>
        {questions.map((question, index) => (
          <div
            className="mb-3 p-5 border border-gray-300 rounded-md"
            key={index}
          >
            <Question question={question} />
            <div className="flex mt-5">
              <Button
                className="mr-5"
                onClick={() => {
                  history.push(
                    UPDATE_QUESTION_PATH(
                      testSetID,
                      testID,
                      questionClusterID,
                      question._id
                    )
                  );
                }}
              >
                Edit
              </Button>
              <Button danger onClick={() => showDeleteConfirm(question)}>
                Delete
              </Button>
            </div>
          </div>
        ))}
      </Spin>
    </div>
  );
}

export default QuestionClusterDetailPage;
