/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useState } from "react";
import { Typography, Modal, Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import { CREATE_VOCA_PATH, UPDATE_VOCA_PATH } from "paths";
import api from "api";

const { Title } = Typography;
const { confirm } = Modal;

function VocaPage() {
  const [vocas, setVocas] = useState([]);
  const history = useHistory();
  const { vocaCategoryID } = useParams();

  const getVoca = () => {
    api(`/voca/list-by-category/${vocaCategoryID}`).then((data) => {
      if (Array.isArray(data)) {
        setVocas(
          data.sort(function (a, b) {
            return a.index - b.index;
          })
        );
      }
    });
  };

  useEffect(() => {
    getVoca();
  }, []);

  const deleteByID = (id) => {
    api(`/voca/delete/${id}`).then(() => {
      getVoca();
    });
  };

  const showDeleteConfirm = (voca) => {
    confirm({
      title: `Are you sure delete ${voca.word}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteByID(voca._id);
      },
    });
  };

  return (
    <div>
      <div className="flex justify-between">
        <Title>List Voca</Title>
        <Button
          type="primary"
          className="mt-2"
          onClick={() => {
            history.push(CREATE_VOCA_PATH(vocaCategoryID));
          }}
        >
          Create
        </Button>
      </div>

      {vocas.map((voca, index) => (
        <div
          key={index}
          className="mb-8 p-5 border border-gray-300 rounded-md hover:border-green-500"
        >
          <p>
            <b className="text-white bg-gray-500 p-1 rounded">{voca.index}.</b>
          </p>

          <audio className="mt-4 mb-2" controls>
            <source src={voca.audio} type="audio/mp3" />
          </audio>

          <img
            style={{ height: "150px", width: "150px" }}
            alt="Voca"
            src={voca.imgSrc}
            className="rounded-md mt-4"
          />

          <div className="mt-2">
            <b>
              {voca.word} ({voca.spelling})
            </b>
          </div>

          <div className="mt-2">
            <p>{voca.meaning}</p>
          </div>

          <div className="mt-2">
            {" "}
            <p>{voca.example}</p>
          </div>

          <div className="flex mt-4">
            <Button
              className="mr-5"
              onClick={() => {
                history.push(UPDATE_VOCA_PATH(vocaCategoryID, voca._id));
              }}
            >
              Edit
            </Button>
            <Button danger onClick={() => showDeleteConfirm(voca)}>
              Delete
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default VocaPage;
