import { useEffect, useState } from "react";
import { Typography, Input, Button, Spin, Form } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { PATH_NAME } from "paths";
import api from "api";

// const { Option } = Select;
const { Title } = Typography;

function GrammarTopicCreatePage() {
  const [form] = Form.useForm();
  const [flagData, setFlagData] = useState({});
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      api(`/flag/${id}`).then((data) => {
        setFlagData(data);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  const create = (value) => {
    api.post("/flag", value).then(() => {
      history.push(PATH_NAME.FLAG);
    });
  };

  const onFinish = (value) => {
    create(value);
  };

  return (
    <div>
      <Title>{id ? "Update" : "Create"} FLAG</Title>
      {loading ? (
        <Spin />
      ) : (
        <div>
          <Form
            form={form}
            name="createupdateflag"
            layout="vertical"
            initialValues={flagData}
            onFinish={onFinish}
          >
            <Form.Item
              label="Flag Name"
              name="flag"
              rules={[{ required: true, message: "Please input flag name!" }]}
            >
              <Input />
            </Form.Item>
          </Form>

          <div className="mt-5 flex">
            <Button
              type="primary"
              className="mr-5"
              onClick={() => form.submit()}
            >
              Create
            </Button>
            <Button
              onClick={() => {
                history.push(PATH_NAME.FLAG);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default GrammarTopicCreatePage;
