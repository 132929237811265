import { message, Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";

function UploadImage({ imageList, onChangeImageList, keyImage }) {
  const copyImageLink = (url) => {
    const textField = document.createElement("textarea");
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    message.info("Copied Image");
  };

  const handleChange = (info) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      let fileList = [...info.fileList];
      fileList = fileList.map((file) => {
        const newFile = file;
        if (newFile.response) {
          newFile.name = `${process.env.REACT_APP_S3_URL}${newFile.response.uri}`;
          newFile.url = `${process.env.REACT_APP_S3_URL}${newFile.response.uri}`;
        }
        return newFile;
      });
      onChangeImageList(fileList);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const propsUpload = {
    name: "file",
    action: `${process.env.REACT_APP_URI_API}/media/upload`,
    headers: {
      authorization: localStorage.getItem("token"),
      key: keyImage,
    },
    onChange: handleChange,
  };

  return (
    <div className="p-5 border border-gray-300 rounded-md">
      <Upload {...propsUpload}>
        <Button icon={<UploadOutlined />}>Click to Upload</Button>
      </Upload>
      {imageList.length > 0 && (
        <>
          <p className="mt-5 mb-5">Image List</p>
          <div className="flex flex-wrap content-start">
            {imageList.map((i) => (
              <div
                key={i.url}
                className="m-5 cursor-pointer"
                onClick={() => {
                  copyImageLink(i.url);
                }}
              >
                <img src={i.url} alt="alt" />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default UploadImage;
