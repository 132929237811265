import { useState } from "react";
import {
  Avatar,
  Button,
  Modal,
  Radio,
  Form,
  Input,
  InputNumber,
  notification,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { getDateString } from "utils";
import api from "api";

const { confirm } = Modal;

function UserItem({ user, onDelete, onUserUpdate }) {
  const history = useHistory();
  const [form] = Form.useForm();
  const [isUpgradeUserModalVisible, setIsUpgradeUserModalVisible] =
    useState(false);

  const showDeleteConfirm = () => {
    confirm({
      title: `Are you sure delete ${user.email}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        onDelete(user._id);
      },
    });
  };

  const onFinish = (values) => {
    const dateExp = new Date(user.expiredMembership);
    let newDateExp = new Date();
    if (dateExp.getTime() > new Date().getTime()) {
      newDateExp = dateExp;
    }
    newDateExp.setMonth(newDateExp.getMonth() + values.month);
    const newDateExpString = `${newDateExp.getFullYear()}-${
      newDateExp.getMonth() + 1
    }-${newDateExp.getDate()}`;
    api
      .post(`/user/upgrade-user`, {
        ...values,
        expiredDate: newDateExpString,
        userID: user._id,
      })
      .then(() => {
        setIsUpgradeUserModalVisible(false);
        notification.success({
          message: "Success",
          description: "Upgrade user success",
        });
        onUserUpdate({
          ...user,
          membership: "PREMIUM",
          expiredMembership: newDateExp.toString(),
        });
      });
  };

  const handleCancel = () => {
    setIsUpgradeUserModalVisible(false);
  };

  return (
    <div
      style={{ minWidth: "300px" }}
      className="mb-8 p-5 border border-gray-300 rounded-md hover:border-green-500 mr-5"
    >
      <Modal
        title="Upgrade Premium Membership User"
        visible={isUpgradeUserModalVisible}
        onOk={() => form.submit()}
        onCancel={handleCancel}
        width={700}
      >
        <Form
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          form={form}
          autoComplete="off"
        >
          <Form.Item
            label="Amount"
            name="amount"
            rules={[{ required: true, message: "Please input amount!" }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Form.Item>
          <Form.Item
            label="Payment method"
            name="paymentMethod"
            rules={[
              { required: true, message: "Please input payment method!" },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="CASH">CASH</Radio.Button>
              <Radio.Button value="MOMO">MOMO</Radio.Button>
              <Radio.Button value="BANK">BANK</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="Month"
            name="month"
            rules={[{ required: true, message: "Please input month!" }]}
          >
            <Radio.Group>
              <Radio.Button value={1}>1 Month</Radio.Button>
              <Radio.Button value={3}>3 Month</Radio.Button>
              <Radio.Button value={6}>6 Month</Radio.Button>
              <Radio.Button value={12}>1 Year</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: "Please input description!" }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
      <div className="flex justify-end">
        {user.role === "ADMIN" ? (
          <p>
            <b className="text-white bg-yellow-300 p-1 rounded">{user.role}</b>
          </p>
        ) : (
          <p>
            <b className="text-white bg-gray-400 p-1 rounded">{user.role}</b>
          </p>
        )}
      </div>

      <div className="flex items-center mt-2">
        <Avatar
          size={64}
          src={
            user.picture
              ? user.picture
              : "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
          }
        />

        <div className="ml-2">
          <p>
            <b className="text-lg">{user.name}</b>
          </p>

          <p>
            <b className="text-gray-500">{user.email}</b>
          </p>

          {user.membership === "FREE" ? (
            <p>
              <b className="text-white bg-gray-300 p-1 rounded">
                {user.membership}
              </b>
            </p>
          ) : (
            <p>
              <b className="text-white bg-green-400 p-1 rounded">
                {user.membership}
              </b>
            </p>
          )}
        </div>
      </div>

      <p className="mt-2">
        <span style={{ width: "180px", float: "left" }}>Phone</span>:{" "}
        {user.phone ? user.phone : "N/A"}
      </p>

      <p className="mt-2">
        <span style={{ width: "180px", float: "left" }}>
          Expired membership at
        </span>
        : {getDateString(user.expiredMembership)}
      </p>

      <p className="mt-2">
        <span style={{ width: "180px", float: "left" }}>Created by</span>:{" "}
        {user.createdBy ? user.createdBy : "-"}
      </p>
      <p className="mt-2">
        <span style={{ width: "180px", float: "left" }}>Updated by</span>:{" "}
        {user.updatedBy ? user.updatedBy : "-"}
      </p>
      <p className="mt-2">
        <span style={{ width: "180px", float: "left" }}>Created at</span>:{" "}
        {getDateString(user.createdAt)}
      </p>
      <p className="mt-2">
        <span style={{ width: "180px", float: "left" }}>Updated at</span>:{" "}
        {getDateString(user.updatedAt)}
      </p>
      <div className="flex justify-between mt-4">
        <div className="mr-5 flex">
          <Button
            type="primary"
            className="mr-5"
            onClick={() => setIsUpgradeUserModalVisible(true)}
          >
            Upgrade
          </Button>

          <Button
            onClick={() => {
              history.push(`/user/update/${user._id}`);
            }}
          >
            Edit
          </Button>
        </div>

        <Button danger onClick={showDeleteConfirm}>
          Delete
        </Button>
      </div>
    </div>
  );
}

export default UserItem;
