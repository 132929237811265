/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { Typography, Button } from "antd";
import dataTests from "datajson/MINI-TEST/data";
import api from "api";
import { to } from "utils";

const { Title } = Typography;

const mediaEndpoint = "https://api.stg.toeicx.com";

function InsertMiniTestTest() {
  const replaceImageContent = async (content, key) => {
    const imagePath = /(?<=<img src=")(.*)(?=.jpg")/;
    const imageLink = content.match(imagePath);

    if (imageLink) {
      const serverLink = await api.post("/raw-tool/upload-image", {
        link: `${imageLink[0]}.jpg`,
        key,
      });

      const newContent = content.replace(
        `${imageLink[0]}.jpg`,
        mediaEndpoint + serverLink
      );
      return newContent;
    }
    return content;
  };

  const addData = async (testID, testData, indexStart = 1) => {
    const data = testData;

    for (let i = 0; i < data.length; i += 1) {
      const content = await replaceImageContent(
        data[i].content ? data[i].content : "",
        "question-cluster"
      );
      const listening = await replaceImageContent(
        data[i].listening ? data[i].listening : "",
        "question-cluster"
      );
      const translate = await replaceImageContent(
        data[i].translate ? data[i].translate : "",
        "question-cluster"
      );

      let audioLink = "";
      if (data[i].audio) {
        audioLink = await api.post("/raw-tool/upload-audio", {
          link: data[i].audio,
          key: "question-cluster",
        });
      }

      const [errQuestionCluster, questionCluster] = await to(
        api.post("/question-cluster", {
          index: indexStart + i,
          audio: audioLink ? mediaEndpoint + audioLink.replace("/src", "") : "",
          part: data[i].part,
          title: data[i].title,
          content,
          listening,
          translate,
          testID,
        })
      );

      if (errQuestionCluster) {
        console.error(errQuestionCluster);
        return;
      }

      for (let j = 0; j < data[i].questions.length; j += 1) {
        const [errQuestion] = await to(
          api.post("/question", {
            questionClusterID: questionCluster._id,
            number: data[i].questions[j].number,
            title: "",
            question: data[i].questions[j].question,
            translate: data[i].questions[j].translate
              ? data[i].questions[j].translate
              : "",
            options: data[i].questions[j].options,
            answer: data[i].questions[j].answer,
            explain: data[i].questions[j].explain
              ? data[i].questions[j].explain
              : "",
          })
        );

        if (errQuestion) {
          console.error(errQuestion);
          return;
        }
      }
    }
  };

  const addDataTest = async () => {
    const [errTestSet, testSet] = await to(
      api.post("/test-set", {
        index: 9,
        title: `MINI TEST`,
        type: "SORT_TEST",
      })
    );
    if (errTestSet) {
      console.error(errTestSet);
      return;
    }
    let index = 1;
    for (const dataTest in dataTests) {
      const [errTest, test] = await to(
        api.post("/test", {
          index,
          audio: "",
          title: `TEST ${index}`,
          testSetID: testSet._id,
        })
      );
      if (errTest) {
        console.error(errTest);
        return;
      }
      await addData(test._id, dataTests[dataTest]);
      index += 1;
    }
  };

  return (
    <div>
      <Title>Insert MiniTest</Title>
      <Button onClick={addDataTest}>Add ALL</Button>
    </div>
  );
}

export default InsertMiniTestTest;
