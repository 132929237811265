import { useEffect, useState } from "react";
import { Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import { LIST_QUESTION_CLUSTER_PATH } from "paths";
import QuestionCluster from "components/QuestionCluster";
import Question from "components/Question";
import api from "api";

const { Title } = Typography;

function PreviewPage() {
  const [questionClusters, setQuestionClusters] = useState([]);
  const history = useHistory();
  const { testSetID, testID } = useParams();

  useEffect(() => {
    api(`/question-cluster/preview/${testID}`).then((data) => {
      if (Array.isArray(data.questionClusters)) {
        setQuestionClusters(
          data.questionClusters.sort(function (a, b) {
            return a.index - b.index;
          })
        );
      }
    });
  }, []);

  return (
    <div>
      <div className="flex">
        <LeftOutlined
          onClick={() => {
            history.push(LIST_QUESTION_CLUSTER_PATH(testSetID, testID));
          }}
          style={{
            fontSize: "25px",
            marginTop: "10px",
            marginRight: "30px",
            cursor: "pointer",
          }}
        />
        <Title>Preview</Title>
      </div>

      {questionClusters.map((questionCluster) => (
        <div key={questionCluster._id} className="mb-3 p-5 border border-black">
          <QuestionCluster questionCluster={questionCluster} />
          {questionCluster.questions.map((question) => (
            <div key={question._id}>
              <hr />
              <Question question={question} />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default PreviewPage;
