import axios from "axios";
import { notification } from "antd";

const instance = axios.create({
  baseURL: process.env.REACT_APP_URI_API,
});

instance.interceptors.request.use((config) => {
  const newConfig = config;
  newConfig.headers = {
    ...config.headers,
    authorization: localStorage.getItem("token"),
  };
  return newConfig;
});

instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // eslint-disable-next-line no-console
    console.log(error);
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.clear();
        notification.error({
          message: "Unauthorized",
          description: error.response.data.error.message,
        });
      } else {
        notification.error({
          message: "Error",
          description: error.response.data.error.message,
        });
      }

      const err = {
        status: error.response.status,
        error: error.response.data.error.message,
      };

      return Promise.reject(err);
    }
    notification.error({
      message: "Error",
      description: error.message,
    });
    const err = {
      status: "",
      error: error.message,
    };

    return Promise.reject(err);
  }
);

export default instance;
