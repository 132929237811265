import { useEffect, useState } from "react";
import { Typography, InputNumber, Input, Button, Spin, Form } from "antd";
import { useHistory, useParams } from "react-router-dom";

import { LIST_QUESTION_CLUSTER_PATH, LIST_QUESTION_PATH } from "paths";
import UploadImage from "components/UploadImage";
import UploadAudio from "components/UploadAudio";
import RichTextEditor from "components/RichTextEditor";
import api from "api";

const { Title } = Typography;

function QuestionClusterCreatePage() {
  const [form] = Form.useForm();

  const [questionCluster, setQuestionCluster] = useState({});
  const [loading, setLoading] = useState(true);
  const [imageList, setImageList] = useState([]);

  const history = useHistory();
  const { testSetID, testID, questionClusterID } = useParams();

  useEffect(() => {
    if (questionClusterID) {
      api(`/question-cluster/${questionClusterID}`).then((data) => {
        setQuestionCluster(data);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);
  const update = (value) => {
    api
      .put("/question-cluster", {
        _id: questionCluster._id,
        ...value,
        testID,
      })
      .then(() => {
        history.push(LIST_QUESTION_CLUSTER_PATH(testSetID, testID));
      });
  };

  const create = (value) => {
    api.post("/question-cluster", { ...value, testID }).then((data) => {
      history.push(LIST_QUESTION_PATH(testSetID, testID, data._id));
    });
  };

  const onFinish = (value) => {
    if (questionClusterID) {
      update(value);
    } else {
      create(value);
    }
  };

  return (
    <div>
      <Title>{questionClusterID ? "Update" : "Create"} Question Cluster</Title>
      {loading ? (
        <Spin />
      ) : (
        <div>
          <Form
            name="create-update-question-cluster"
            layout="vertical"
            form={form}
            initialValues={questionCluster}
            onFinish={onFinish}
          >
            <Form.Item
              name="index"
              label="Index"
              rules={[{ required: true, message: "Please input index!" }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              name="part"
              label="Part"
              rules={[{ required: true, message: "Please input part!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item name="title" label="Title">
              <Input />
            </Form.Item>

            <Form.Item name="audio" label="Audio">
              <UploadAudio keyAudio="question-cluster" />
            </Form.Item>

            <div className="mb-5">
              <p>Upload image</p>
              <div className="mt-5">
                <UploadImage
                  keyImage="question-cluster"
                  imageList={imageList}
                  onChangeImageList={(list) => setImageList(list)}
                />
              </div>
            </div>

            <Form.Item label="Content" name="content">
              <RichTextEditor />
            </Form.Item>

            <Form.Item label="Translate" name="translate">
              <RichTextEditor />
            </Form.Item>

            <Form.Item label="Listening" name="listening">
              <RichTextEditor />
            </Form.Item>

            <Form.Item>
              <div className="flex mt-5">
                <Button
                  type="primary"
                  className="mr-5"
                  onClick={() => form.submit()}
                >
                  {questionClusterID ? "Update" : "Create"}
                </Button>
                <Button
                  onClick={() => {
                    history.push(LIST_QUESTION_CLUSTER_PATH(testSetID, testID));
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
}

export default QuestionClusterCreatePage;
