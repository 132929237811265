import { Route, Redirect } from "react-router-dom";

const isAuthenticated = () =>
  localStorage.getItem("token") && localStorage.getItem("user");

function PrivateRoute({ component: Page, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Page {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
