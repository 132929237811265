/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useState } from "react";
import { Typography, Modal, Button } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { LeftOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  CREATE_TEST_PATH,
  LIST_QUESTION_CLUSTER_PATH,
  LIST_SET_TEST_PATH,
  UPDATE_TEST_PATH,
} from "paths";
import api from "api";

const { Title } = Typography;
const { confirm } = Modal;

function TestPage() {
  const [tests, setTests] = useState([]);
  const history = useHistory();
  const { testSetID } = useParams();

  const getTests = () => {
    api(`/test/search-by-test-set-id/${testSetID}`).then((data) => {
      if (Array.isArray(data)) {
        setTests(
          data.sort(function (a, b) {
            return a.index - b.index;
          })
        );
      }
    });
  };

  useEffect(() => {
    getTests();
  }, []);

  const deleteByID = (id) => {
    api(`/test/delete/${id}`).then(() => {
      getTests();
    });
  };

  const showDeleteConfirm = (test) => {
    confirm({
      title: `Are you sure delete ${test.title}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteByID(test._id);
      },
    });
  };

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex content-center">
          <LeftOutlined
            onClick={() => {
              history.push(LIST_SET_TEST_PATH());
            }}
            style={{
              fontSize: "25px",
              marginTop: "10px",
              marginRight: "30px",
              cursor: "pointer",
            }}
          />
          <Title>List Test</Title>
        </div>

        <Button
          type="primary"
          className="mt-2"
          onClick={() => {
            history.push(CREATE_TEST_PATH(testSetID));
          }}
        >
          Create
        </Button>
      </div>
      <div className="flex flex-wrap content-evenly">
        {tests.map((test, index) => (
          <div
            key={index}
            style={{ minWidth: "300px" }}
            className="mb-8 p-5 mr-8 border border-gray-300 rounded-md hover:border-green-500"
          >
            <div className="flex justify-between">
              <p>
                <b className="text-white bg-gray-500 p-1 rounded">
                  {test.index}.
                </b>
              </p>

              {test.membership === "FREE" ? (
                <p>
                  <b className="text-white bg-gray-300 p-1 rounded">
                    {test.membership}
                  </b>
                </p>
              ) : (
                <p>
                  <b className="text-white bg-green-400 p-1 rounded">
                    {test.membership}
                  </b>
                </p>
              )}
            </div>

            <p className="text-lg pt-2">{test.title}</p>

            {test.audio && (
              <div className="mt-5 mb-5">
                <audio controls>
                  <source src={test.audio} type="audio/mp3" />
                </audio>
              </div>
            )}

            <div className="flex justify-between mt-5">
              <Button
                className="mr-5"
                onClick={() => {
                  history.push(LIST_QUESTION_CLUSTER_PATH(testSetID, test._id));
                }}
              >
                Detail
              </Button>
              <div className="flex">
                <Button
                  className="mr-5"
                  onClick={() => {
                    history.push(UPDATE_TEST_PATH(testSetID, test._id));
                  }}
                >
                  Edit
                </Button>
                <Button danger onClick={() => showDeleteConfirm(test)}>
                  Delete
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TestPage;
