import { useEffect, useState } from "react";
import { Typography, Button, Spin } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { GRAMMAR_PATH } from "paths";
import UploadImage from "components/UploadImage";
import RichTextEditor from "components/RichTextEditor";
import api from "api";

const { Title } = Typography;

function CreateGrammarPage() {
  const [content, setContent] = useState("");
  const [grammar, setGrammar] = useState({});

  const [loading, setLoading] = useState(true);
  const [imageList, setImageList] = useState([]);

  const history = useHistory();
  const { grammarTopicID, grammarID } = useParams();

  useEffect(() => {
    if (grammarID) {
      api(`/grammar/get-by-grammar-topic-id/${grammarTopicID}`).then((data) => {
        setContent(data.content);
        setGrammar(data);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  const create = () => {
    api.post("/grammar", { content, grammarTopicID }).then(() => {
      history.push(GRAMMAR_PATH(grammarTopicID));
    });
  };

  const update = () => {
    api
      .put("/grammar", {
        _id: grammar._id,
        content,
        grammarTopicID,
      })
      .then(() => {
        history.push(GRAMMAR_PATH(grammarTopicID));
      });
  };

  return (
    <div>
      <Title>{grammarID ? "Update" : "Create"} Grammar</Title>
      {loading ? (
        <Spin />
      ) : (
        <div>
          <div className="mt-5">
            <div className="mb-5">
              <UploadImage
                keyImage="grammar"
                imageList={imageList}
                onChangeImageList={(list) => setImageList(list)}
              />
            </div>

            <p>Content:</p>
            <RichTextEditor
              value={grammar.content}
              onChange={(value) => {
                setContent(value);
              }}
            />
            <div className="flex mt-4">
              <Button
                type="primary"
                className="mr-5"
                onClick={() => {
                  grammarID ? update() : create();
                }}
              >
                {grammarID ? "Update" : "Create"}
              </Button>
              <Button
                onClick={() => {
                  history.push(GRAMMAR_PATH(grammarTopicID));
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CreateGrammarPage;
