import { useEffect, useState } from "react";
import {
  Typography,
  Input,
  InputNumber,
  Form,
  Spin,
  Select,
  Button,
} from "antd";
import { useHistory, useParams } from "react-router-dom";
import { LIST_SET_TEST_PATH, LIST_TEST_PATH } from "paths";
import api from "api";

const { Title } = Typography;
const { Option } = Select;

function TestSetCreatePage() {
  const [testSet, settestSet] = useState({});
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();

  const history = useHistory();
  const { testSetID } = useParams();

  useEffect(() => {
    if (testSetID) {
      api(`/test-set/${testSetID}`).then((data) => {
        settestSet(data);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  const create = (value) => {
    api.post("/test-set", value).then((data) => {
      history.push(LIST_TEST_PATH(data._id));
    });
  };

  const update = (value) => {
    api.put("/test-set", { ...value, _id: testSet._id }).then(() => {
      history.push(LIST_SET_TEST_PATH());
    });
  };

  const onFinish = (value) => {
    testSetID ? update(value) : create(value);
  };

  return (
    <div>
      <Title>{testSetID ? "Update" : "Create"} Test Set</Title>
      {loading ? (
        <Spin />
      ) : (
        <div>
          <div className="mb-5">
            <Form
              form={form}
              name="createupdatetestset"
              layout="vertical"
              initialValues={testSet}
              onFinish={onFinish}
            >
              <Form.Item
                label="Index"
                name="index"
                rules={[{ required: true, message: "Please input index!" }]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: "Please input title!" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Type"
                name="type"
                rules={[
                  { required: true, message: "Please choose type test set!" },
                ]}
              >
                <Select>
                  <Option value="FULL_TEST">FULL_TEST</Option>
                  <Option value="SORT_TEST">SORT_TEST</Option>
                  <Option value="GRAMMAR_TEST">GRAMMAR_TEST</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Level"
                name="level"
                rules={[{ required: true, message: "Please input level!" }]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item>
                <div className="flex mt-5">
                  <Button
                    type="primary"
                    className="mr-5"
                    onClick={() => form.submit()}
                  >
                    {testSetID ? "Update" : "Create"}
                  </Button>
                  <Button
                    onClick={() => {
                      history.push(LIST_SET_TEST_PATH());
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
}

export default TestSetCreatePage;
