import { Typography, Button } from "antd";
import data from "../dataRawMiniTest";

const { Title } = Typography;

function RawMiniTest() {
  const getOptions = (optionsData) => {
    const options = [];
    const question = optionsData[0].content;
    let answer = "A";

    for (let i = 1; i < optionsData.length; i += 1) {
      const option = {
        key: i === 1 ? "A" : i === 2 ? "B" : i === 3 ? "C" : "D",
        value: optionsData[i].content,
        translate: "",
      };
      if (optionsData[i].correct === "1") {
        answer = i === 1 ? "A" : i === 2 ? "B" : i === 3 ? "C" : "D";
      }
      options.push(option);
    }
    return [question, answer, options];
  };

  const getOptionsP1 = (optionsData) => {
    const options = [];
    let answer = "A";

    for (let i = 0; i < optionsData.length; i += 1) {
      const option = {
        key: i === 0 ? "A" : i === 1 ? "B" : i === 2 ? "C" : "D",
        value: optionsData[i].content,
        translate: "",
      };
      if (optionsData[i].correct === "1") {
        answer = i === 0 ? "A" : i === 1 ? "B" : i === 2 ? "C" : "D";
      }
      options.push(option);
    }
    return [answer, options];
  };

  const rawp1 = (rawdata) => {
    const dataQuestionCLusters = rawdata.filter((q) => q.type === "1");

    const questionClusters = [];
    for (let i = 0; i < dataQuestionCLusters.length; i += 1) {
      const [answer, options] = getOptionsP1(dataQuestionCLusters[i].answer[0]);
      const questionCluster = {
        part: "1",
        title: `Question ${i + 1}:`,
        audio: `https://www.anhngumshoa.com/uploads/sound/${dataQuestionCLusters[i].sound}`,
        content: `<img src="https://www.anhngumshoa.com/uploads/images/resize/550x550/${dataQuestionCLusters[i].images}">`,
        translate: "",
        listening: "",
        questions: [
          {
            number: `${i + 1}`,
            question: "",
            translate: "",
            options,
            explain: "",
            answer,
          },
        ],
      };
      questionClusters.push(questionCluster);
    }

    return questionClusters;
  };

  const rawp2 = (rawdata, index) => {
    const dataQuestionCLusters = rawdata
      .filter((q) => q.type === "2")
      .sort((a, b) => {
        return b.ordering - a.ordering;
      });

    const questionClusters = [];
    for (let i = 0; i < dataQuestionCLusters.length; i += 1) {
      const [question, answer, options] = getOptions(
        dataQuestionCLusters[i].answer[0]
      );
      const questionCluster = {
        part: "2",
        title: `Question ${index + i + 1}:`,
        audio: `https://www.anhngumshoa.com/uploads/sound/${dataQuestionCLusters[i].sound}`,
        content: "",
        translate: "",
        listening: "",
        questions: [
          {
            number: `${index + i + 1}`,
            question,
            translate: "",
            options,
            explain: "",
            answer,
          },
        ],
      };
      questionClusters.push(questionCluster);
    }

    return questionClusters;
  };

  const getQuestions = (questionsData, index) => {
    const questions = [];

    for (let i = 0; i < questionsData.length; i += 1) {
      const [questionTitle, answer, options] = getOptions(questionsData[i]);
      const question = {
        number: `${index + i}`,
        question: questionTitle === "[...]" ? "" : questionTitle,
        translate: "",
        options,
        explain: "",
        answer,
      };
      questions.push(question);
    }
    return questions;
  };

  const rawp3 = (rawdata, index, part) => {
    const dataQuestionCLusters = rawdata
      .filter((q) => q.type === part)
      .sort((a, b) => {
        return b.ordering - a.ordering;
      });
    let nextIndex = index + 1;
    const questionClusters = [];
    for (let i = 0; i < dataQuestionCLusters.length; i += 1) {
      const questions = getQuestions(dataQuestionCLusters[i].answer, nextIndex);
      const questionCluster = {
        part,
        title: `Question ${nextIndex} - ${nextIndex + questions.length - 1}:`,
        audio: `https://www.anhngumshoa.com/uploads/sound/${dataQuestionCLusters[i].sound}`,
        content: "",
        translate: "",
        listening: dataQuestionCLusters[i].detail,
        questions,
      };
      nextIndex += questions.length;
      questionClusters.push(questionCluster);
    }

    return [nextIndex, questionClusters];
  };

  const rawp5 = (rawdata, index) => {
    const dataQuestionCLusters = rawdata
      .filter((q) => q.type === "5")
      .sort((a, b) => {
        return b.ordering - a.ordering;
      });

    const questionClusters = [];
    for (let i = 0; i < dataQuestionCLusters.length; i += 1) {
      const [answer, options] = getOptionsP1(dataQuestionCLusters[i].answer[0]);
      const questionCluster = {
        part: "5",
        title: `Question ${index + i + 1}:`,
        audio: "",
        content: dataQuestionCLusters[i].detail,
        translate: "",
        listening: "",
        questions: [
          {
            number: `${index + i + 1}`,
            question: "",
            translate: "",
            options,
            explain: "",
            answer,
          },
        ],
      };
      questionClusters.push(questionCluster);
    }

    return [index + questionClusters.length + 1, questionClusters];
  };

  const rawp6 = (rawdata, index, part) => {
    const dataQuestionCLusters = rawdata
      .filter((q) => q.type === part)
      .sort((a, b) => {
        return b.ordering - a.ordering;
      });
    let nextIndex = index + 1;
    const questionClusters = [];
    for (let i = 0; i < dataQuestionCLusters.length; i += 1) {
      const questions = getQuestions(dataQuestionCLusters[i].answer, nextIndex);
      const questionCluster = {
        part,
        title: `Question ${nextIndex} - ${nextIndex + questions.length - 1}:`,
        audio: "",
        content: dataQuestionCLusters[i].detail,
        translate: "",
        listening: "",
        questions,
      };
      nextIndex += questions.length;
      questionClusters.push(questionCluster);
    }

    return [nextIndex, questionClusters];
  };

  const raw = () => {
    const datas = {};
    for (let i = 1; i < 11; i += 1) {
      let dataTest = [];
      const p1 = rawp1(data[`test${i}`]);
      dataTest = dataTest.concat(p1);
      const p2 = rawp2(data[`test${i}`], dataTest.length);
      dataTest = dataTest.concat(p2);
      const [nextIndex, p3] = rawp3(data[`test${i}`], dataTest.length, "3");
      dataTest = dataTest.concat(p3);
      const [nextIndexp4, p4] = rawp3(data[`test${i}`], nextIndex - 1, "4");
      dataTest = dataTest.concat(p4);
      const [nextIndexp5, p5] = rawp5(data[`test${i}`], nextIndexp4 - 1);
      dataTest = dataTest.concat(p5);
      const [nextIndexp6, p6] = rawp6(data[`test${i}`], nextIndexp5 - 1, "6");
      dataTest = dataTest.concat(p6);
      const [, p7] = rawp6(data[`test${i}`], nextIndexp6 - 1, "7");
      dataTest = dataTest.concat(p7);
      datas[`test${i}`] = dataTest;
    }
  };

  return (
    <div>
      <Title>Raw Mini Test</Title>
      <Button onClick={raw}>Raw all</Button>
    </div>
  );
}

export default RawMiniTest;
