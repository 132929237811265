/* eslint-disable import/no-extraneous-dependencies */
// eslint-disable-next-line import/no-unresolved
import "ckeditor5/ckeditor5.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Paragraph,
  Undo,
  Image,
  ImageInsert,
  ImageToolbar,
  ImageCaption,
  ImageStyle,
  ImageResize,
  LinkImage,
  Table,
  TableToolbar,
  TableSelection,
} from "ckeditor5";

function RichTextEditor({ value, onChange }) {
  return (
    <CKEditor
      editor={ClassicEditor}
      config={{
        toolbar: [
          "undo",
          "redo",
          "|",
          "bold",
          "italic",
          "insertImage",
          "insertTable",
        ],
        image: {
          toolbar: [
            "imageStyle:block",
            "imageStyle:side",
            "|",
            "toggleImageCaption",
            "|",
            "linkImage",
          ],
          insert: {
            type: "auto",
          },
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "selectTableColumn",
            "selectTableRow",
            "setTableColumnHeader",
            "setTableRowHeader",
            "insertTable",
            "insertTableColumnLeft",
            "insertTableColumnRight",
            "insertTableRowAbove",
            "insertTableRowBelow",
            "removeTableColumn",
            "removeTableRow",
            "selectTableColumn",
            "selectTableRow",
            "setTableColumnHeader",
            "setTableRowHeader",
            "mergeTableCellRight",
            "mergeTableCellLeft",
            "mergeTableCellUp",
            "mergeTableCellDown",
            "splitTableCellVertically",
            "splitTableCellHorizontally",
          ],
        },
        plugins: [
          Bold,
          Essentials,
          Italic,
          Paragraph,
          Undo,
          Image,
          ImageInsert,
          ImageToolbar,
          ImageCaption,
          ImageStyle,
          ImageResize,
          LinkImage,
          Table,
          TableToolbar,
          TableSelection,
        ],
        initialData: value,
      }}
      onChange={(event, editor) => {
        const data = editor.getData();
        onChange(data);
      }}
    />
  );
}

export default RichTextEditor;
