/* eslint-disable no-await-in-loop */
import { Typography, Button } from "antd";
import api from "api";
import datavoca from "datajson/VOCA/data";
import { to } from "utils";

const { Title } = Typography;

const mediaEndpoint = "https://api.stg.toeicx.com";

function InsertVoca() {
  const uploadImage = async (url, key) => {
    const serverLink = await api.post("/raw-tool/upload-image", {
      link: url,
      key,
    });
    return serverLink;
  };

  const addData = async (data) => {
    const imageTopic = await uploadImage(
      data.topicImage,
      "vocabulary-category"
    );
    const [errVocaTopic, vocaTopic] = await to(
      api.post("/voca-category", {
        index: data.index,
        title: data.topicName,
        imgSrc: mediaEndpoint + imageTopic,
      })
    );

    if (errVocaTopic) {
      console.error(errVocaTopic);
      return;
    }

    for (let i = 0; i < data.vocas.length; i += 1) {
      const imageVoca = await uploadImage(data.vocas[i].image, "vocabulary");
      const audioLink = await api.post("/raw-tool/upload-audio", {
        link: data.vocas[i].audio,
        key: "vocabulary",
      });
      const [errVoca] = await to(
        api.post("/voca", {
          index: data.vocas[i].index,
          categoryID: vocaTopic._id,
          word: data.vocas[i].vord,
          meaning: data.vocas[i].meaning,
          meaningTranslate: data.vocas[i].meaningVi,
          spelling: data.vocas[i].spelling,
          example: data.vocas[i].exam,
          exampleTranslate: data.vocas[i].examVi,
          imgSrc: mediaEndpoint + imageVoca,
          audio: mediaEndpoint + audioLink,
        })
      );

      if (errVoca) {
        console.error(errVoca);
        return;
      }
    }
  };

  const addAllData = async () => {
    datavoca.forEach(async (element) => {
      await addData(element);
    });
  };

  const clearVoca = async () => {
    const [errVoca] = await to(api.get("/raw-tool/clear-voca"));
    if (errVoca) {
      console.error(errVoca);
    }
  };

  return (
    <div>
      <Title>Insert Voca</Title>
      <Button className="mr-5" onClick={addAllData}>
        Add all
      </Button>
      <Button onClick={clearVoca}>Clear Voca</Button>
    </div>
  );
}

export default InsertVoca;
