/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
import { Typography, Button } from "antd";
import cheerio from "cheerio";
import api from "api";
import { to } from "utils";

const { Title } = Typography;

function RawVoca() {
  const rawTopic = async (url) => {
    const [errResponse, response] = await to(
      api.get(`/raw-tool/get-source-web`, {
        url,
      })
    );

    if (errResponse) {
      return [errResponse];
    }

    const vocas = [];

    const $ = cheerio.load(response);
    const vocaDoms = $(".tuvung.col-md-12");

    const $vn = cheerio.load(
      response.replaceAll("<!--", "").replaceAll("-->", "")
    );
    const vocaVnContainer = $vn(".container")[3];
    const vocaVnDoms = $vn(vocaVnContainer).find(".col-md-12");

    for (let i = 0; i < vocaDoms.length; i += 1) {
      const vocaDom = vocaDoms[i];

      const meaningVi = $(vocaVnDoms[i]).find(".row .mean")[0].children[0].data;

      const exam = $(vocaVnDoms[i]).find(".row .ex-en")[0].children[0].data;
      const examVi = $(vocaVnDoms[i]).find(".row .ex-vi")[0].children[0].data;

      const vord = $(vocaDom).find(".noidung span")[0].children[0].data;
      const spelling = $(vocaDom).find(".noidung span")[1].children[0].data;
      const audio = `https://toeicexamstore.com/${
        $(vocaDom).find("audio source")[0].attribs.src
      }`;

      const image = `https://toeicexamstore.com/${
        $(vocaDom).find("img")[0].attribs.src
      }`;

      const meaning =
        $(vocaDom).find(".noidung")[0].children[6].children[0].data +
        $(vocaDom).find(".noidung")[0].children[7].data;

      vocas.push({
        index: i + 1,
        vord,
        spelling: spelling.trim(),
        meaning: meaning.replace("  ", " "),
        meaningVi,
        exam,
        examVi,
        audio,
        image,
      });
    }
    return [null, vocas];
  };

  const raw = async () => {
    const [errResponse, response] = await to(
      api.get(`/raw-tool/get-source-web`, {
        url: "https://toeicexamstore.com/vocabulary/600-tu-vung-toeic",
      })
    );

    if (errResponse) {
      return [errResponse];
    }

    const $ = cheerio.load(response);
    const topicDoms = $(".col-md-3");

    const topics = [];
    for (let i = 26; i < 27; i += 1) {
      const topicDom = topicDoms[i];

      const topicURL = $(topicDom).find("h3 a")[0].attribs.href;
      const topicName = $(topicDom).find("h3 a")[0].children[0].data;
      const topicImage = `https://toeicexamstore.com/${
        $(topicDom).find("img")[0].attribs.src
      }`;

      const [errVocas, vocas] = await rawTopic(topicURL);
      if (errVocas) {
        console.log(errVocas);
        return;
      }

      const topic = {
        index: i + 1,
        vocas,
        topicName,
        topicImage,
      };
      topics.push(topic);
    }
  };

  return (
    <div>
      <Title>Raw Voca</Title>
      <Button onClick={raw}>Raw all</Button>
    </div>
  );
}

export default RawVoca;
