import { useEffect, useState } from "react";
import {
  Typography,
  Input,
  InputNumber,
  Spin,
  Form,
  Select,
  Button,
} from "antd";
import { useHistory, useParams } from "react-router-dom";
import { PATH_NAME } from "paths";
import UploadImage from "components/UploadImage";
import api from "api";

const { Title } = Typography;
const { Option } = Select;

function VocaCategoryCreatePage() {
  const [imgList, setImgList] = useState([]);
  const [vocaCategory, setVocaCategory] = useState({});
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();

  const history = useHistory();
  const { vocaCategoryID } = useParams();

  useEffect(() => {
    if (vocaCategoryID) {
      api(`/voca-category/${vocaCategoryID}`).then((data) => {
        setVocaCategory(data);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  const create = (value) => {
    api.post("/voca-category/", value).then(() => {
      history.push(PATH_NAME.LIST_VOCA_CATEGORY);
    });
  };

  const update = (value) => {
    api.put("/voca-category", { _id: vocaCategory._id, ...value }).then(() => {
      history.push(PATH_NAME.LIST_VOCA_CATEGORY);
    });
  };

  const onFinish = (value) => {
    vocaCategoryID ? update(value) : create(value);
  };

  return (
    <div>
      <Title>{vocaCategoryID ? "Update" : "Create"} Voca Category</Title>
      {loading ? (
        <Spin />
      ) : (
        <div>
          <UploadImage
            keyImage="vocabulary-category"
            imageList={imgList}
            onChangeImageList={(list) => {
              setImgList(list);
            }}
          />

          <div className="mt-5">
            <Form
              form={form}
              name="createupdatetestset"
              layout="vertical"
              initialValues={vocaCategory}
              onFinish={onFinish}
            >
              <Form.Item
                label="Index"
                name="index"
                rules={[{ required: true, message: "Please input index!" }]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: "Please input title!" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Image"
                name="imgSrc"
                rules={[
                  { required: true, message: "Please input image link!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Membership"
                name="membership"
                rules={[
                  { required: true, message: "Please input membership!" },
                ]}
              >
                <Select>
                  <Option value="FREE">FREE</Option>
                  <Option value="PREMIUM">PREMIUM</Option>
                </Select>
              </Form.Item>
            </Form>
          </div>

          <div className="flex mt-5">
            <Button
              className="mr-5"
              type="primary"
              onClick={() => form.submit()}
            >
              {vocaCategoryID ? "Update" : "Create"}
            </Button>
            <Button
              onClick={() => {
                history.push(PATH_NAME.LIST_VOCA_CATEGORY);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default VocaCategoryCreatePage;
