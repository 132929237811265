import { useHistory, Switch } from "react-router-dom";
import { Button } from "antd";
import PrivateRoute from "components/PrivateRoute";
import ROUTERS from "routers";
import { PATH_NAME } from "paths";
import "./Main.css";

function Item({ text, path }) {
  const history = useHistory();
  return (
    <Button
      style={{ minWidth: "200px" }}
      className="mt-4 w-full"
      onClick={() => {
        history.push(path);
      }}
    >
      {text}
    </Button>
  );
}

function MainPage() {
  const history = useHistory();
  const logout = () => {
    localStorage.clear();
    history.push("/login");
  };

  return (
    <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <div className="header-container flex justify-between">
        <p className="header-logo">TOEICX</p>
        <Button type="primary" size="small" onClick={() => logout()}>
          Logout
        </Button>
      </div>
      <div className="main-container">
        <div className="flex flex-col pl-10">
          <Item text="Test set" path={PATH_NAME.LIST_SET_TEST} />
          <Item text="Grammar" path={PATH_NAME.LIST_GRAMMAR_TOPIC} />
          <Item text="Vocabulary" path={PATH_NAME.LIST_VOCA_CATEGORY} />
          <Item text="Tip" path={PATH_NAME.TIP} />
          <Item text="User" path={PATH_NAME.USERS} />
          <Item text="Notification" path={PATH_NAME.NOTIFICATIONS} />
          <Item text="Default Avatar" path={PATH_NAME.DEFAULT_AVATAR} />
          <Item text="Flag" path={PATH_NAME.FLAG} />
        </div>

        <div className="page-container">
          <div className="page">
            <Switch>
              {ROUTERS.map((router, index) => (
                <PrivateRoute
                  key={index}
                  exact={router.exact}
                  path={router.pathname}
                  component={router.component}
                />
              ))}
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
