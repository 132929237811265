import { useEffect, useState } from "react";
import { Typography, Input, InputNumber, Spin, Form, Button } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { VOCA_PATH } from "paths";
import UploadImage from "components/UploadImage";
import UploadAudio from "components/UploadAudio";
import api from "api";

const { Title } = Typography;

function VocaCreatePage() {
  const [imgList, setImgList] = useState([]);
  const [voca, setVoca] = useState({});
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const { vocaCategoryID, vocaID } = useParams();

  const [form] = Form.useForm();

  useEffect(() => {
    if (vocaID) {
      api(`/voca/${vocaID}`).then((data) => {
        setVoca(data);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  const create = (value) => {
    api.post("/voca", { categoryID: vocaCategoryID, ...value }).then(() => {
      history.push(VOCA_PATH(vocaCategoryID));
    });
  };

  const update = (value) => {
    api
      .put("/voca", { _id: voca._id, categoryID: vocaCategoryID, ...value })
      .then(() => {
        history.push(VOCA_PATH(vocaCategoryID));
      });
  };

  const onFinish = (value) => {
    vocaID ? update(value) : create(value);
  };

  return (
    <div>
      <Title>{vocaID ? "Update" : "Create"} Voca</Title>
      {loading ? (
        <Spin />
      ) : (
        <div>
          <UploadImage
            keyImage="vocabulary"
            imageList={imgList}
            onChangeImageList={(list) => {
              setImgList(list);
            }}
          />

          <div className="mt-4">
            <Form
              form={form}
              name="createupdatetestset"
              layout="vertical"
              initialValues={voca}
              onFinish={onFinish}
            >
              <Form.Item
                label="Index"
                name="index"
                rules={[{ required: true, message: "Please input index!" }]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item
                label="Word"
                name="word"
                rules={[{ required: true, message: "Please input word!" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Spelling"
                name="spelling"
                rules={[{ required: true, message: "Please input spelling!" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Image"
                name="imgSrc"
                rules={[
                  { required: true, message: "Please input image link!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Audio"
                name="audio"
                rules={[{ required: true, message: "Please upload audio!" }]}
              >
                <UploadAudio keyAudio="vocabulary" />
              </Form.Item>

              <Form.Item
                label="Meaning"
                name="meaning"
                rules={[{ required: true, message: "Please input meaning!" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Example"
                name="example"
                rules={[{ required: true, message: "Please input example!" }]}
              >
                <Input />
              </Form.Item>
            </Form>
          </div>

          <div className="mt-5 flex">
            <Button
              className="mr-5"
              type="primary"
              onClick={() => {
                form.submit();
              }}
            >
              {vocaID ? "Update" : "Create"}
            </Button>
            <Button
              onClick={() => {
                history.push(VOCA_PATH(vocaCategoryID));
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default VocaCreatePage;
