import { useEffect, useState } from "react";
import { Typography, Modal, Button } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { LeftOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  CREATE_GRAMMAR_PATH,
  LIST_GRAMMAR_TOPIC_PATH,
  UPDATE_GRAMMAR_PATH,
} from "paths";
import api from "api";

const { Title } = Typography;
const { confirm } = Modal;

function GrammarPage() {
  const [grammars, setGrammars] = useState([]);
  const history = useHistory();
  const { grammarTopicID } = useParams();

  const getGrammars = () => {
    api(`/grammar/list-by-grammar-topic-id/${grammarTopicID}`).then((data) => {
      setGrammars(data);
    });
  };

  const deleteByID = (id) => {
    api(`/grammar/delete/${id}`).then(() => {
      getGrammars();
    });
  };

  useEffect(() => {
    getGrammars();
  }, []);

  const showDeleteConfirm = (grammar) => {
    confirm({
      title: `Are you sure delete this grammar?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteByID(grammar._id);
      },
    });
  };

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex">
          <LeftOutlined
            onClick={() => {
              history.push(LIST_GRAMMAR_TOPIC_PATH());
            }}
            style={{
              fontSize: "25px",
              marginTop: "10px",
              marginRight: "30px",
              cursor: "pointer",
            }}
          />
          <Title>Grammar</Title>
        </div>

        <Button
          className="mt-2"
          onClick={() => {
            history.push(CREATE_GRAMMAR_PATH(grammarTopicID));
          }}
        >
          Create
        </Button>
      </div>

      {grammars.map((grammar) => (
        <div
          key={grammar._id}
          className="mb-8 p-5 border border-gray-300 rounded-md hover:border-green-500"
        >
          <div
            dangerouslySetInnerHTML={{
              __html: `<html>
      <head><meta name="viewport" content="width=device-width, initial-scale=1.0"></head>
      <body style='"margin: 0; padding: 0;'>
        <div>
          ${grammar.content}
        </div>
      </body>
    </html>`,
            }}
          />
          <div className="flex justify-between mt-5">
            <div className="flex">
              <Button
                className="mr-5"
                onClick={() => {
                  history.push(
                    UPDATE_GRAMMAR_PATH(grammarTopicID, grammar._id)
                  );
                }}
              >
                Edit
              </Button>
              <Button danger onClick={() => showDeleteConfirm(grammar)}>
                Delete
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default GrammarPage;
