import { useEffect, useState } from "react";
import {
  Typography,
  Input,
  InputNumber,
  Button,
  Spin,
  Select,
  Form,
} from "antd";
import { useHistory, useParams } from "react-router-dom";
import { PATH_NAME } from "paths";
import api from "api";

const { Option } = Select;
const { Title } = Typography;

function GrammarTopicCreatePage() {
  const [form] = Form.useForm();

  const [tests, setTests] = useState([]);
  const [grammarTopic, setGrammarTopic] = useState({});
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const { grammarTopicID } = useParams();

  useEffect(() => {
    api(`/test/grammar-test`).then((data) => {
      if (Array.isArray(data)) {
        setTests(data);
      }
    });
    if (grammarTopicID) {
      api(`/grammar-topic/${grammarTopicID}`).then((data) => {
        setGrammarTopic(data);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  const create = (value) => {
    api.post("/grammar-topic/", value).then(() => {
      history.push(PATH_NAME.LIST_GRAMMAR_TOPIC);
    });
  };

  const update = (value) => {
    api.put("/grammar-topic", { _id: grammarTopic._id, ...value }).then(() => {
      history.push(PATH_NAME.LIST_GRAMMAR_TOPIC);
    });
  };

  const onFinish = (value) => {
    grammarTopicID ? update(value) : create(value);
  };

  return (
    <div>
      <Title>{grammarTopicID ? "Update" : "Create"} Grammar Topic</Title>
      {loading ? (
        <Spin />
      ) : (
        <div>
          <Form
            form={form}
            name="createupdatetestset"
            layout="vertical"
            initialValues={grammarTopic}
            onFinish={onFinish}
          >
            <Form.Item
              label="Index"
              name="index"
              rules={[{ required: true, message: "Please input index!" }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: "Please input title!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Test"
              name="testID"
              rules={[{ required: true, message: "Please select test!" }]}
            >
              <Select
                showSearch
                placeholder="Select a test"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return (
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                {tests.map((t) => (
                  <Option key={t._id} value={t._id}>
                    {t.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Membership"
              name="membership"
              rules={[{ required: true, message: "Please input membership!" }]}
            >
              <Select>
                <Option value="FREE">FREE</Option>
                <Option value="PREMIUM">PREMIUM</Option>
              </Select>
            </Form.Item>
          </Form>

          <div className="mt-5 flex">
            <Button
              type="primary"
              className="mr-5"
              onClick={() => form.submit()}
            >
              {grammarTopicID ? "Update" : "Create"}
            </Button>
            <Button
              onClick={() => {
                history.push(PATH_NAME.LIST_GRAMMAR_TOPIC);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default GrammarTopicCreatePage;
