import QuestionClusterPage from "pages/question-cluster/QuestionClusterPage";
import QuestionClusterDetailPage from "pages/question-cluster/QuestionClusterDetailPage";
import QuestionClusterCreatePage from "pages/question-cluster/QuestionClusterCreatePage";
import QuestionCreatePage from "pages/question/QuestionCreatePage";
import TestSetPage from "pages/test-set/TestSetPage";
import TestSetCreatePage from "pages/test-set/TestSetCreatePage";
import TestPage from "pages/test/TestPage";
import TestCreatePage from "pages/test/TestCreatePage";
import PreviewPage from "pages/preview/PreviewPage";
import TestingPage from "pages/testing/TestingPage";
import GrammarTopicPage from "pages/grammar-topic/GrammarTopic";
import GrammarTopicCreatePage from "pages/grammar-topic/GrammarTopicCreatePage";
import GrammarPage from "pages/grammar/GrammarPage";
import CreateGrammarPage from "pages/grammar/CreateGrammarPage";
import VocaCategoriesPage from "pages/voca-category/VocaCategories";
import VocaCategoryCreatePage from "pages/voca-category/VocaCategoryCreatePage";
import VocaPage from "pages/voca/Vocas";
import VocaCreatePage from "pages/voca/VocaCreatePage";
import TipsPage from "pages/tip/TipsPage";
import TipCreatePage from "pages/tip/TipCreatePage";
import UsersPage from "pages/user/Users";
import UserCreatePage from "pages/user/UserCreatePage";
import Raw from "pages/raw/Raw";
import NotificationPage from "pages/notification/NotificationPage";
import NotificationCreatePage from "pages/notification/NotificationCreatePage";
import DefaultAvatarPage from "pages/default-avatar/DefaultAvatar";
import DefaultAvatarCreatePage from "pages/default-avatar/CreateDefaultAvatar";
import Flag from "pages/flag/FlagPage";
import CreateFlagPage from "pages/flag/CreateFlagPage";

import { PATH_NAME } from "paths";

const ROUTERS = [
  {
    name: "Raw Tool",
    pathname: "/raw",
    exact: true,
    component: Raw,
  },

  {
    name: "List Default Avatar",
    pathname: PATH_NAME.DEFAULT_AVATAR,
    exact: true,
    component: DefaultAvatarPage,
  },
  {
    name: "Create Default Avatar",
    pathname: PATH_NAME.CREATE_DEFAULT_AVATAR,
    exact: true,
    component: DefaultAvatarCreatePage,
  },
  {
    name: "Update Default Avatar",
    pathname: PATH_NAME.UPDATE_DEFAULT_AVATAR,
    exact: true,
    component: DefaultAvatarCreatePage,
  },

  {
    name: "List Notification",
    pathname: PATH_NAME.NOTIFICATIONS,
    exact: true,
    component: NotificationPage,
  },
  {
    name: "Create Notification",
    pathname: PATH_NAME.CREATE_NOTIFICATION,
    exact: true,
    component: NotificationCreatePage,
  },

  {
    name: "List User",
    pathname: PATH_NAME.USERS,
    exact: true,
    component: UsersPage,
  },
  {
    name: "Create User",
    pathname: PATH_NAME.CREATE_USER,
    exact: true,
    component: UserCreatePage,
  },
  {
    name: "Update User",
    pathname: PATH_NAME.UPDATE_USER,
    exact: true,
    component: UserCreatePage,
  },
  {
    name: "List Tip",
    pathname: PATH_NAME.TIP,
    exact: true,
    component: TipsPage,
  },
  {
    name: "Create Tip",
    pathname: PATH_NAME.CREATE_TIP,
    exact: true,
    component: TipCreatePage,
  },
  {
    name: "Update Tip",
    pathname: PATH_NAME.UPDATE_TIP,
    exact: true,
    component: TipCreatePage,
  },
  {
    name: "List Voca Category",
    pathname: PATH_NAME.LIST_VOCA_CATEGORY,
    exact: true,
    component: VocaCategoriesPage,
  },
  {
    name: "Create Voca Category",
    pathname: PATH_NAME.CREATE_VOCA_CATEGORY,
    exact: true,
    component: VocaCategoryCreatePage,
  },
  {
    name: "Update Voca Category",
    pathname: PATH_NAME.UPDATE_VOCA_CATEGORY,
    exact: true,
    component: VocaCategoryCreatePage,
  },
  {
    name: "Voca",
    pathname: PATH_NAME.VOCA,
    exact: true,
    component: VocaPage,
  },
  {
    name: "Create Voca",
    pathname: PATH_NAME.CREATE_VOCA,
    exact: true,
    component: VocaCreatePage,
  },
  {
    name: "Update Voca",
    pathname: PATH_NAME.UPDATE_VOCA,
    exact: true,
    component: VocaCreatePage,
  },
  {
    name: "List Grammar Topic",
    pathname: PATH_NAME.LIST_GRAMMAR_TOPIC,
    exact: true,
    component: GrammarTopicPage,
  },
  {
    name: "Create Grammar Topic",
    pathname: PATH_NAME.CREATE_GRAMMAR_TOPIC,
    exact: true,
    component: GrammarTopicCreatePage,
  },
  {
    name: "Update Grammar Topic",
    pathname: PATH_NAME.UPDATE_GRAMMAR_TOPIC,
    exact: true,
    component: GrammarTopicCreatePage,
  },
  {
    name: "Grammar",
    pathname: PATH_NAME.GRAMMAR,
    exact: true,
    component: GrammarPage,
  },
  {
    name: "Create Grammar",
    pathname: PATH_NAME.CREATE_GRAMMAR,
    exact: true,
    component: CreateGrammarPage,
  },
  {
    name: "Update Grammar",
    pathname: PATH_NAME.UPDATE_GRAMMAR,
    exact: true,
    component: CreateGrammarPage,
  },
  {
    name: "List Test Set",
    pathname: PATH_NAME.LIST_SET_TEST,
    exact: true,
    component: TestSetPage,
  },
  {
    name: "Create Test Set",
    pathname: PATH_NAME.CREATE_SET_TEST,
    exact: true,
    component: TestSetCreatePage,
  },
  {
    name: "Update Test Set",
    pathname: PATH_NAME.UPDATE_SET_TEST,
    exact: true,
    component: TestSetCreatePage,
  },
  {
    name: "List Test",
    pathname: PATH_NAME.LIST_TEST,
    exact: true,
    component: TestPage,
  },
  {
    name: "Create Test",
    pathname: PATH_NAME.CREATE_TEST,
    exact: true,
    component: TestCreatePage,
  },
  {
    name: "Update Test",
    pathname: PATH_NAME.UPDATE_TEST,
    exact: true,
    component: TestCreatePage,
  },
  {
    name: "List Question Cluster",
    pathname: PATH_NAME.LIST_QUESTION_CLUSTER,
    exact: true,
    component: QuestionClusterPage,
  },
  {
    name: "Create Question Cluster",
    pathname: PATH_NAME.CREATE_QUESTION_CLUSTER,
    exact: true,
    component: QuestionClusterCreatePage,
  },
  {
    name: "Update Question Cluster",
    pathname: PATH_NAME.UPDATE_QUESTION_CLUSTER,
    exact: true,
    component: QuestionClusterCreatePage,
  },
  {
    name: "Detail Question Cluster",
    pathname: PATH_NAME.LIST_QUESTION,
    exact: true,
    component: QuestionClusterDetailPage,
  },
  {
    name: "Create Question",
    pathname: PATH_NAME.CREATE_QUESTION,
    exact: true,
    component: QuestionCreatePage,
  },
  {
    name: "Update Question",
    pathname: PATH_NAME.UPDATE_QUESTION,
    exact: true,
    component: QuestionCreatePage,
  },
  {
    name: "Preview test",
    pathname: PATH_NAME.PREVIEW_TEST,
    exact: true,
    component: PreviewPage,
  },
  {
    name: "Preview test",
    pathname: PATH_NAME.TESTING,
    exact: true,
    component: TestingPage,
  },
  {
    name: "Flag",
    pathname: PATH_NAME.FLAG,
    exact: true,
    component: Flag,
  },
  {
    name: "Create flag",
    pathname: PATH_NAME.CREATE_FLAG,
    exact: true,
    component: CreateFlagPage,
  },
  {
    name: "Update flag",
    pathname: PATH_NAME.UPDATE_FLAG,
    exact: true,
    component: CreateFlagPage,
  },
];

export default ROUTERS;
