import { Form, Input, Button, Typography } from "antd";
import { useHistory } from "react-router-dom";
import api from "api";

const { Title } = Typography;

function LoginPage() {
  const history = useHistory();
  const onFinish = (values) => {
    api.post("/user/login", values).then((data) => {
      localStorage.setItem("token", data.token);
      localStorage.setItem("user", JSON.stringify(data.user));
      history.push("/");
    });
  };

  return (
    <div
      style={{
        maxWidth: "500px",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: "70px",
      }}
    >
      <Title>Log In to ToeicX</Title>
      <Form name="login" layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            <b>Log in</b>
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default LoginPage;
