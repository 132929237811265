const idraw = {
  ets2024: {
    test1: {
      p1: "66bd5fefeebee4774c0b7f12",
      p2: "66bd5ffaeebee4774c0b7f13",
      p3: "66bd600187747b91a00e7c23",
      p4: "66bd600a53832a27180aad52",
      p5: "66bd6013eebee4774c0b7f14",
      p6: "66bd601b1b38e1a15009a732",
      p7: "66bd6025eebee4774c0b7f15",
    },
    test2: {
      p1: "66bd60351b38e1a15009a733",
      p2: "66bd603f1b38e1a15009a734",
      p3: "66bd60481b38e1a15009a735",
      p4: "66bd6050b71bafae6000bee2",
      p5: "66bd6059ee694a33070a7dd2",
      p6: "66bd6062ee694a33070a7dd3",
      p7: "66bd606aab0ef51a560e8db2",
    },
    test3: {
      p1: "66bd6084dad81c0587035662",
      p2: "66bd6099ab0ef51a560e8db3",
      p3: "66bd60a1dad81c0587035663",
      p4: "66bd60a98475d4746a00dd13",
      p5: "66bd60b1ab0ef51a560e8db4",
      p6: "66bd60b9dad81c0587035664",
      p7: "66bd60c34d5287b533009a62",
    },
    test4: {
      p1: "66bd60da23d0112840096a52",
      p2: "66bd60e4ecfecd3b58069c52",
      p3: "66bd60f223d0112840096a53",
      p4: "66bd60fbecfecd3b58069c53",
      p5: "66bd6104ecfecd3b58069c54",
      p6: "66bd610b3fc67a038801ec82",
      p7: "66bd611523d0112840096a54",
    },
    test6: {
      p1: "66bd61673fc67a038801ec84",
      p2: "66bd6171537750c2970f0f22",
      p3: "66bd617a537750c2970f0f23",
      p4: "66bd6181facbd2cc1f0a7da3",
      p5: "66bd618b537750c2970f0f24",
      p6: "66bd6193537750c2970f0f25",
      p7: "66bd619c03f95f331c0cdfc2",
    },
    test7: {
      p1: "66bd61fd4eccf089d10edea2",
      p2: "66bd62084eccf089d10edea3",
      p3: "66bd62104eccf089d10edea4",
      p4: "66bd62184eccf089d10edea5",
      p5: "66bd6223630d92eae6026b52",
      p6: "66bd622d4f6ae49bdf07b8c2",
      p7: "66bd6238630d92eae6026b53",
    },
    test8: {
      p1: "66bd624a739a9f5cab033972",
      p2: "66bd6254d4e4acb1e10aee02",
      p3: "66bd625dd4e4acb1e10aee03",
      p4: "66bd6266f3269c13ff0f22b2",
      p5: "66bd626f1aa690edc207c8d2",
      p6: "66bd6278f3269c13ff0f22b3",
      p7: "66bd62841aa690edc207c8d4",
    },
    test9: {
      p1: "66bd6297429b617aa404c242",
      p2: "66bd62a301bfea26410e9932",
      p3: "66bd62ae429b617aa404c243",
      p4: "66bd62b6429b617aa404c244",
      p5: "66bd62c0429b617aa404c245",
      p6: "66bd62c8429b617aa404c246",
      p7: "66bd62d3429b617aa404c247",
    },
    test10: {
      p1: "66bd62e9acd82cd5480e1562",
      p2: "66bd62f2acd82cd5480e1563",
      p3: "66bd62fa085fea46e00e7332",
      p4: "66bd6303085fea46e00e7333",
      p5: "66bd630b085fea46e00e7334",
      p6: "66bd6313085fea46e00e7335",
      p7: "66bd631b085fea46e00e7336",
    }
  },
  ets2023: {
    test1: {
      p1: "66bd5c6d1b0647dd8d022a53",
      p2: "66bd5c7d93057bb9ab01e382",
      p3: "66bd5c886b766dc6a8083b52",
      p4: "66bd5c97d6d331ccdf072563",
      p5: "66bd5ca193057bb9ab01e384",
      p6: "66bd5ca96d29b2ad490845a2",
      p7: "66bd5cb26d29b2ad490845a3",
    },
    test2: {
      p1: "66bd5cca624e593f3104f752",
      p2: "66bd5cde624e593f3104f753",
      p3: "66bd5ce8624e593f3104f754",
      p4: "66bd5cf302bd8476990adfc3",
      p5: "66bd5cfc1e7fc88725076842",
      p6: "66bd5d051e7fc88725076843",
      p7: "66bd5d0e624e593f3104f756",
    },
    test3: {
      p1: "66bd5d2243d95c422e02f002",
      p2: "66bd5d2d8395b06963053322",
      p3: "66bd5d3743d95c422e02f003",
      p4: "66bd5d4443d95c422e02f004",
      p5: "66bd5d4cab42f49214054ce2",
      p6: "66bd5d5e4361d0d8510c7702",
      p7: "66bd5d6c4361d0d8510c7703",
    },
    test4: {
      p1: "66bd5d8142c453156f0db623",
      p2: "66bd5d8bab42f49214054ce5",
      p3: "66bd5d944361d0d8510c7704",
      p4: "66bd5d9c4361d0d8510c7705",
      p5: "66bd5da6ed744ddd5d03ddb2",
      p6: "66bd5db218c4e145cc0e4a82",
      p7: "66bd5dbced744ddd5d03ddb3",
    },
    test5: {
      p1: "66bd5de7ed744ddd5d03ddb4",
      p2: "66bd5df1d592a01af904a2d2",
      p3: "66bd5dfaac448d6b4a0e6cb2",
      p4: "66bd5e04ac448d6b4a0e6cb3",
      p5: "66bd5e0cac448d6b4a0e6cb4",
      p6: "66bd5e17d592a01af904a2d3",
      p7: "66bd5e1eac448d6b4a0e6cb5",
    },
    test6: {
      p1: "66bd5e30ac448d6b4a0e6cb6",
      p2: "66bd5e391a47bca7f9012f42",
      p3: "66bd5e431a47bca7f9012f43",
      p4: "66bd5e4d1a47bca7f9012f44",
      p5: "66bd5e55512058d18002bc72",
      p6: "66bd5e5ef22d51c04401c272",
      p7: "66bd5e681a47bca7f9012f45",
    },
    test7: {
      p1: "66bd5e7d0790d8491909e1c2",
      p2: "66bd5e87f22d51c04401c273",
      p3: "66bd5e90f22d51c04401c274",
      p4: "66bd5e9971279f0b7006d843",
      p5: "66bd5ea20790d8491909e1c3",
      p6: "66bd5eaad2ed3d6acc060532",
      p7: "66bd5eb471279f0b7006d844",
    },
    test8: {
      p1: "66bd5ec6d2ed3d6acc060534",
      p2: "66bd5ed060c1885cad029b02",
      p3: "66bd5edd92aef8719e01db22",
      p4: "66bd5ee7d2ed3d6acc060535",
      p5: "66bd5ef0d2ed3d6acc060536",
      p6: "66bd5ef860c1885cad029b04",
      p7: "66bd5f0260c1885cad029b05",
    },
    test9: {
      p1: "66bd5f1692aef8719e01db23",
      p2: "66bd5f2192aef8719e01db24",
      p3: "66bd5f29dee0dfd6df0193d3",
      p4: "66bd5f3167de60972a0e35f2",
      p5: "66bd5f3a5185f5130104fc62",
      p6: "66bd5f43dee0dfd6df0193d4",
      p7: "66bd5f4d074ce5aa0e08b762",
    },
    test10: {
      p1: "66bd5f60074ce5aa0e08b763",
      p2: "66bd5f6b5185f5130104fc63",
      p3: "66bd5f746a800f86fc068b82",
      p4: "66bd5f7d074ce5aa0e08b764",
      p5: "66bd5f8687747b91a00e7c22",
      p6: "66bd5f8f6a800f86fc068b83",
      p7: "66bd5f97074ce5aa0e08b765",
    }
  },
  ets2022: {
    test1: {
      p1: "66bc756e13fd51a11f0e0d03",
      p2: "66bc757dca1a8ee7fb075172",
      p3: "66bc758b18d5d7169b0b2d72",
      p4: "66bc75973ef362bc3c09d0b2",
      p5: "66bc75a218d5d7169b0b2d74",
      p6: "66bc75ae18d5d7169b0b2d75",
      p7: "66bc75b618d5d7169b0b2d76",
    },
    test2: {
      p1: "66bc76e1fa95d74eb201a552",
      p2: "66bc76eb248f82ab050bf633",
      p3: "66bc76f4248f82ab050bf634",
      p4: "66bc76fdfa95d74eb201a554",
      p5: "66bc7708fa95d74eb201a555",
      p6: "66bc7710fa95d74eb201a556",
      p7: "66bc771afa95d74eb201a557",
    },
    test3: {
      p1: "66bc775644facfeaf10d39c2",
      p2: "66bc776144facfeaf10d39c3",
      p3: "66bc776d723cdbcc3b0cab13",
      p4: "66bc7779723cdbcc3b0cab14",
      p5: "66bc778241d29e9bf1023c53",
      p6: "66bc778e44facfeaf10d39c4",
      p7: "66bc779ac0ce9efa99083e72",
    },
    test4: {
      p1: "66bd58b947df9c0e4d0a2002",
      p2: "66bd58c647df9c0e4d0a2004",
      p3: "66bd58d047df9c0e4d0a2005",
      p4: "66bd58dacbfe51228c06b612",
      p5: "66bd58e647df9c0e4d0a2007",
      p6: "66bd58f3cbfe51228c06b613",
      p7: "66bd58fbcbfe51228c06b614",
    },
    test5: {
      p1: "66bd59a1be9b7afa5b02a0b2",
      p2: "66bd59ab44217a668205af72",
      p3: "66bd59b544217a668205af73",
      p4: "66bd59be44217a668205af74",
      p5: "66bd59c844217a668205af75",
      p6: "66bd59d244217a668205af76",
      p7: "66bd59de4087ca08f7015272",
    },
    test6: {
      p1: "66bd5a4d4087ca08f7015273",
      p2: "66bd5a5604be891f8b049da2",
      p3: "66bd5a6104be891f8b049da3",
      p4: "66bd5a6a9e8ff3d102036b22",
      p5: "66bd5a759e8ff3d102036b23",
      p6: "66bd5a8204be891f8b049da4",
      p7: "66bd5a8d9e8ff3d102036b25",
    },
    test7: {
      p1: "66bd5aabfa5578ef8a0fcde2",
      p2: "66bd5ab4fa5578ef8a0fcde3",
      p3: "66bd5abdfbe1cabb100ea2d2",
      p4: "66bd5ac6f5741ff07d066362",
      p5: "66bd5ad1fa5578ef8a0fcde4",
      p6: "66bd5ada9acb6a40300a0ad2",
      p7: "66bd5ae4f5741ff07d066363",
    },
    test8: {
      p1: "66bd5b079acb6a40300a0ad3",
      p2: "66bd5b109acb6a40300a0ad4",
      p3: "66bd5b18ca02fc313304d422",
      p4: "66bd5b21ca02fc313304d423",
      p5: "66bd5b2aca02fc313304d424",
      p6: "66bd5b31ca02fc313304d425",
      p7: "66bd5b3bca02fc313304d426",
    },
    test9: {
      p1: "66bd5bab34c8b495d205aa42",
      p2: "66bd5bb534c8b495d205aa43",
      p3: "66bd5bbebf1079fc5b05cb22",
      p4: "66bd5bc7bf1079fc5b05cb23",
      p5: "66bd5bcfbf1079fc5b05cb24",
      p6: "66bd5bd9bf1079fc5b05cb25",
      p7: "66bd5be2bf1079fc5b05cb26",
    },
    test10: {
      p1: "66bd5c02380deef63d0879f2",
      p2: "66bd5c0f380deef63d0879f3",
      p3: "66bd5c17380deef63d0879f4",
      p4: "66bd5c1f380deef63d0879f5",
      p5: "66bd5c27380deef63d0879f6",
      p6: "66bd5c2f6ad7db94410b25b2",
      p7: "66bd5c3b9033c15af0030142",
    }
  },
  ets2021: {
    test1: {
      p1: "66bc5e7b2a3d0fe64d02dcb3",
      p2: "66bc5ec4d4816c4cdc0c8ff3",
      p3: "66bc5ecf84622c9a380b1672",
      p4: "66bc5edb84622c9a380b1673",
      p5: "66bc5ee584622c9a380b1675",
      p6: "66bc5eef84622c9a380b1676",
      p7: "66bc5ef984622c9a380b1677",
    },
    test2: {
      p1: "66bc73c2114d851df2048b82",
      p2: "66bc73d8114d851df2048b84",
      p3: "66bc73e2114d851df2048b85",
      p4: "66bc73ecf1872e5686095e44",
      p5: "66bc73fab22045822b06f822",
      p6: "66bc7403b22045822b06f823",
      p7: "66bc740fb22045822b06f824",
    },
    test3: {
      p1: "66bc742bbecc803ed4081122",
      p2: "66bc743a9097bc5d7104b123",
      p3: "66bc744531003bc792066bf2",
      p4: "66bc7450becc803ed4081125",
      p5: "66bc745904aeedea420da1a2",
      p6: "66bc746331003bc792066bf3",
      p7: "66bc746c31003bc792066bf4",
    },
    test4: {
      p1: "66bc748204aeedea420da1a3",
      p2: "66bc74a0b326ce278b0d6842",
      p3: "66bc74abb326ce278b0d6844",
      p4: "66bc74b5b326ce278b0d6845",
      p5: "66bc74beb326ce278b0d6846",
      p6: "66bc74ca1a75761f4f02b402",
      p7: "66bc74d5478acaa50f0d0432",
    },
    test5: {
      p1: "66bc74f81a75761f4f02b403",
      p2: "66bc75021a75761f4f02b404",
      p3: "66bc75111571f470fd0b4fc2",
      p4: "66bc75211571f470fd0b4fc3",
      p5: "66bc752bac744e6898008ce3",
      p6: "66bc7536ac744e6898008ce4",
      p7: "66bc7541ac744e6898008ce5",
    }
  },
  ets2020: {
    test1: {
      p1: "6110e290b55480589c5a458e",
      p2: "6110e2bb9e3ec05c7a5b9390",
      p3: "6110e2c29e3ec05c7a5b9391",
      p4: "6110e2c736e35c44d37c49de",
      p5: "6110e2cbb55480589c5a4590",
      p6: "6110e2cf36e35c44d37c49df",
      p7: "6110e2d5b55480589c5a4591",
    },
    test2: {
      p1: "60bc961d2cbc1302d23fb25d",
      p2: "60bc963017de916c591d2b47",
      p3: "60bc96432cbc1302d23fb25f",
      p4: "60bc96542cbc1302d23fb260",
      p5: "60bc96682cbc1302d23fb261",
      p6: "60bc967817de916c591d2b48",
      p7: "60bc968c2cbc1302d23fb262",
    },
    test3: {
      p1: "60bccffa3346e2225062f5d8",
      p2: "60bcd0703346e2225062f5dc",
      p3: "6110e96e5726f10726090294",
      p4: "60bcd09417de916c591d2b96",
      p5: "60bcd0ab17de916c591d2b97",
      p6: "60bcd0bf17de916c591d2b99",
      p7: "60bcd0d817de916c591d2b9a",
    },
    test4: {
      p1: "60bcd1b98375783eec2176b2",
      p2: "60bcd1d68375783eec2176b3",
      p3: "60bcd1eafb398a10c94c3525",
      p4: "60bcd1fcfb398a10c94c3526",
      p5: "60bcd2138375783eec2176b4",
      p6: "6110e994060f8d3f3f620b6b",
      p7: "60bcd2428375783eec2176b6",
    },
    test5: {
      p1: "6110e9b370d1ff121d1aa4cd",
      p2: "6110e9e7060f8d3f3f620b6c",
      p3: "6110e9ec70d1ff121d1aa4cf",
      p4: "6110e9f0060f8d3f3f620b6d",
      p5: "6110e9f35726f10726090295",
      p6: "6110e9f6060f8d3f3f620b6e",
      p7: "6110e9fc5726f10726090296",
    },
    test6: {
      p1: "60bcd3768375783eec2176c2",
      p2: "60bcd3858375783eec2176c3",
      p3: "60bcd3958375783eec2176c4",
      p4: "60bcd3a78375783eec2176c5",
      p5: "60bcd3b73346e2225062f5ec",
      p6: "60bcd3cb3346e2225062f5ee",
      p7: "60bcd3e13346e2225062f5ef",
    },
    test7: {
      p1: "60bcd3f03346e2225062f5f0",
      p2: "60bcd4168375783eec2176c7",
      p3: "60bcd4253346e2225062f5f1",
      p4: "60bcd4373346e2225062f5f2",
      p5: "60bcd4473346e2225062f5f3",
      p6: "60bcd4578375783eec2176cb",
      p7: "60bcd46b8375783eec2176cc",
    },
    test8: {
      p1: "60bcd484fb398a10c94c3535",
      p2: "60bcd493fb398a10c94c3536",
      p3: "60bcd4a68375783eec2176cd",
      p4: "60bcd4b63346e2225062f5f5",
      p5: "60bcd4c78375783eec2176d0",
      p6: "60bcd4d9fb398a10c94c353a",
      p7: "60bcd4edfb398a10c94c353b",
    },
    test9: {
      p1: "60bcd50a3346e2225062f5f7",
      p2: "60bcd5258375783eec2176d3",
      p3: "60bcd539fb398a10c94c353c",
      p4: "60bcd54a8375783eec2176d4",
      p5: "60bcd565fb398a10c94c353d",
      p6: "60bcd57a3346e2225062f5f9",
      p7: "60bcd58c3346e2225062f5fa",
    },
    test10: {
      p1: "60bcd5ae8375783eec2176d8",
      p2: "60bcd5fdfb398a10c94c3542",
      p3: "60bcd6818375783eec2176da",
      p4: "60bcd787fb398a10c94c3548",
      p5: "60bcd796fb398a10c94c3549",
      p6: "60bcd7a8fb398a10c94c354b",
      p7: "60bcd7b7fb398a10c94c354c",
    },
  },
  ets2019: {
    test1: {
      p1: "60bcdccc7399387461723855",
      p2: "60bcdd017399387461723857",
      p3: "60bcdd0f7399387461723858",
      p4: "60bcdd3591bc746077730895",
      p5: "60bcdd5191bc746077730896",
      p6: "60bcdd63e48d1310070903dc",
      p7: "60bcdd6991bc746077730898",
    },
    test2: {
      p1: "60bcdd8d91bc746077730899",
      p2: "60bcdd90739938746172385a",
      p3: "60bcddab80635557fd3d6c42",
      p4: "60bcddb0739938746172385c",
      p5: "60bcddb4e48d1310070903df",
      p6: "60bcddcee48d1310070903e1",
      p7: "60bcddd1e48d1310070903e2",
    },
    test3: {
      p1: "60bcddf1739938746172385e",
      p2: "60bcddf480635557fd3d6c43",
      p3: "60bcddf8e48d1310070903e4",
      p4: "60bcde0fe48d1310070903e6",
      p5: "60bcde1380635557fd3d6c44",
      p6: "60bcde1780635557fd3d6c45",
      p7: "60bcde1b80635557fd3d6c46",
    },
    test4: {
      p1: "60bcde45e48d1310070903e8",
      p2: "60bcde487399387461723860",
      p3: "60bcde4d80635557fd3d6c47",
      p4: "60bcde51e48d1310070903e9",
      p5: "60bcde6ce48d1310070903ea",
      p6: "60bcde727399387461723862",
      p7: "60bcde7680635557fd3d6c49",
    },
    test5: {
      p1: "60bcde9480635557fd3d6c4a",
      p2: "60bcde97e48d1310070903eb",
      p3: "60bcde9a80635557fd3d6c4b",
      p4: "60bcde9e80635557fd3d6c4c",
      p5: "60bcdeba7399387461723865",
      p6: "60bcdebe80635557fd3d6c4d",
      p7: "60bcdec280635557fd3d6c4e",
    },
    test6: {
      p1: "60bcdee9e48d1310070903ed",
      p2: "60bcdeee7399387461723866",
      p3: "60bcdef1e48d1310070903ee",
      p4: "60bcdef580635557fd3d6c4f",
      p5: "60bcdf0e80635557fd3d6c51",
      p6: "60bcdf11e48d1310070903ef",
      p7: "60bcdf157399387461723868",
    },
    test7: {
      p1: "60bcdf32e48d1310070903f1",
      p2: "60bcdf3580635557fd3d6c53",
      p3: "60bcdf3a7399387461723869",
      p4: "60bcdf55e48d1310070903f3",
      p5: "60bcdf649289de23444b4f02",
      p6: "60bcdf6680635557fd3d6c54",
      p7: "60bcdf6b9289de23444b4f03",
    },
    test8: {
      p1: "60bcdf959289de23444b4f04",
      p2: "60bcdf9780635557fd3d6c56",
      p3: "60bcdf9b9289de23444b4f05",
      p4: "60bcdf9e80635557fd3d6c57",
      p5: "60bcdfb980635557fd3d6c58",
      p6: "60bcdfbc9289de23444b4f06",
      p7: "60bcdfc19289de23444b4f07",
    },
    test9: {
      p1: "60bcdfea9289de23444b4f0a",
      p2: "60bcdfedb4c3b024ec042fa2",
      p3: "60bcdff480635557fd3d6c5b",
      p4: "60bcdff7b4c3b024ec042fa3",
      p5: "60bce01980635557fd3d6c5c",
      p6: "60bce01c80635557fd3d6c5d",
      p7: "60bce0219289de23444b4f0d",
    },
    test10: {
      p1: "60bce04a9289de23444b4f0e",
      p2: "60bce04db4c3b024ec042fa8",
      p3: "60bce05063c33f441f7dbd52",
      p4: "60bce05363c33f441f7dbd53",
      p5: "60bce0569289de23444b4f0f",
      p6: "60bce059b4c3b024ec042fa9",
      p7: "60bce05eb4c3b024ec042faa",
    },
  },
  ets2018: {
    test1: {
      p1: "60bda43bb8f47d1d190aa969",
      p2: "60bda43ff82b906e584cd28a",
      p3: "60bda443e039545de10ad290",
      p4: "60bda4493ff20e6e075b4cc2",
      p5: "60bda469e039545de10ad291",
      p6: "60bda46de039545de10ad292",
      p7: "60bda471c055d03138696532",
    },
    test2: {
      p1: "60bda4abe039545de10ad294",
      p2: "60bda4b0c055d03138696533",
      p3: "60bda4b53ff20e6e075b4cc3",
      p4: "60bda4b9e039545de10ad295",
      p5: "60bda4bec055d03138696534",
      p6: "60bda4c2e039545de10ad296",
      p7: "60bda4c73ff20e6e075b4cc4",
    },
    test3: {
      p1: "60bdb113c055d03138696545",
      p2: "60bdb1183ff20e6e075b4cce",
      p3: "60bdb11dc055d03138696547",
      p4: "60bdb12259a1d134b535ab95",
      p5: "60bdb12693b2303ec7710b42",
      p6: "60bdb12b59a1d134b535ab96",
      p7: "60bdb12fc055d03138696548",
    },
    test4: {
      p1: "60bdb17759a1d134b535ab97",
      p2: "60bdb17c93b2303ec7710b44",
      p3: "60bdb181c055d0313869654b",
      p4: "60bdb18693b2303ec7710b45",
      p5: "60bdb18ac055d0313869654c",
      p6: "60bdb18e93b2303ec7710b46",
      p7: "60bdb19259a1d134b535ab98",
    },
    test5: {
      p1: "60bdb1cf93b2303ec7710b47",
      p2: "60bdb1d459a1d134b535ab99",
      p3: "60bdb1d959a1d134b535ab9a",
      p4: "60bdb1dd93b2303ec7710b49",
      p5: "60bdb1e2c055d0313869654d",
      p6: "60bdb1e693b2303ec7710b4a",
      p7: "60bdb1ea59a1d134b535ab9b",
    },
  },
  ets: {
    test1: {
      p1: "60be104b09668b59c879c727",
      p2: "60be1034d6930151f40c3323",
      p3: "60be103809668b59c879c724",
      p4: "60be103d00ad6570875f92fd",
      p5: "60be104109668b59c879c726",
      p6: "60be1044d6930151f40c3324",
      p7: "60be104800ad6570875f92fe",
    },
    test2: {
      p1: "60be10c509668b59c879c729",
      p2: "60be10c800ad6570875f92ff",
      p3: "60be10cb09668b59c879c72a",
      p4: "60be10cfd6930151f40c3325",
      p5: "60be10d309668b59c879c72b",
      p6: "60be10d5d6930151f40c3326",
      p7: "60be10d909668b59c879c72c",
    },
    test3: {
      p1: "60be111300ad6570875f9300",
      p2: "60be1118d6930151f40c3327",
      p3: "60be111c00ad6570875f9301",
      p4: "60be1120d6930151f40c3328",
      p5: "60be112300ad6570875f9302",
      p6: "60be1127d6930151f40c3329",
      p7: "60be112b09668b59c879c72d",
    },
    test4: {
      p1: "60be116509668b59c879c72e",
      p2: "60be1168d6930151f40c332a",
      p3: "60be116b09668b59c879c72f",
      p4: "60be116ed6930151f40c332b",
      p5: "60be1af82a1f882e4a506cbb",
      p6: "6110eb015726f1072609029a",
      p7: "60be117b09668b59c879c730",
    },
    test5: {
      p1: "60be11ad09668b59c879c731",
      p2: "60be11b1d6930151f40c332d",
      p3: "60be11b409668b59c879c732",
      p4: "60be11b700ad6570875f9304",
      p5: "60be11bb00ad6570875f9305",
      p6: "60be11bed6930151f40c332f",
      p7: "60be11c200ad6570875f9306",
    },
    test6: {
      p1: "60be120a09668b59c879c733",
      p2: "60be120d2a1f882e4a506c82",
      p3: "60be121009668b59c879c734",
      p4: "60be12142a1f882e4a506c83",
      p5: "60be121809668b59c879c735",
      p6: "60be121bd6930151f40c3330",
      p7: "60be121f2a1f882e4a506c84",
    },
    test7: {
      p1: "60be1251d6930151f40c3331",
      p2: "60be12552a1f882e4a506c85",
      p3: "60be1258d6930151f40c3332",
      p4: "60be125c2a1f882e4a506c86",
      p5: "60be1260d6930151f40c3333",
      p6: "60be12622a1f882e4a506c87",
      p7: "60be1266d6930151f40c3334",
    },
  },
  eco: {
    test1: {
      p1: "60be12d909668b59c879c737",
      p2: "60be12ddd6930151f40c3335",
      p3: "60be12e009668b59c879c738",
      p4: "60be12e3d6930151f40c3336",
      p5: "60be12e709668b59c879c739",
      p6: "60be12ead6930151f40c3337",
      p7: "60be12ee2a1f882e4a506c88",
    },
    test2: {
      p1: "60be1324d6930151f40c3338",
      p2: "60be132e09668b59c879c73a",
      p3: "60be132cd6930151f40c3339",
      p4: "60be13312a1f882e4a506c89",
      p5: "60be1335d6930151f40c333a",
      p6: "60be13392a1f882e4a506c8a",
      p7: "60be133d09668b59c879c73b",
    },
    test3: {
      p1: "60be13752a1f882e4a506c8b",
      p2: "60be137809668b59c879c73c",
      p3: "60be137c2a1f882e4a506c8c",
      p4: "60be137f09668b59c879c73d",
      p5: "60be13822a1f882e4a506c8d",
      p6: "60be138509668b59c879c73e",
      p7: "60be13882a1f882e4a506c8e",
    },
    test4: {
      p1: "60be13b609668b59c879c73f",
      p2: "60be13bad6930151f40c333b",
      p3: "60be13bd09668b59c879c740",
      p4: "60be13c1d6930151f40c333c",
      p5: "60be13c4d6930151f40c333d",
      p6: "60be13c72a1f882e4a506c8f",
      p7: "60be13cbd6930151f40c333e",
    },
    test5: {
      p1: "60be141bd6930151f40c333f",
      p2: "60be1423d6930151f40c3340",
      p3: "60be142309668b59c879c741",
      p4: "60be1427d6930151f40c3341",
      p5: "60be142a2a1f882e4a506c93",
      p6: "60be142dd6930151f40c3342",
      p7: "60be1432d6930151f40c3343",
    },
    test6: {
      p1: "60be14832a1f882e4a506c95",
      p2: "60be1487d6930151f40c3345",
      p3: "60be148a09668b59c879c742",
      p4: "60be148ed6930151f40c3346",
      p5: "60be14916fded03e1d132b52",
      p6: "60be149409668b59c879c743",
      p7: "60be14976fded03e1d132b53",
    },
    test7: {
      p1: "60be14f809668b59c879c744",
      p2: "60be14fc09668b59c879c745",
      p3: "60be15022a1f882e4a506c97",
      p4: "60be150509668b59c879c746",
      p5: "60be15082a1f882e4a506c98",
      p6: "60be150b09668b59c879c747",
      p7: "60be150f6fded03e1d132b55",
    },
    test8: {
      p1: "60be153b2a1f882e4a506c99",
      p2: "60be153e6fded03e1d132b56",
      p3: "60be154209668b59c879c748",
      p4: "60be15452a1f882e4a506c9a",
      p5: "60be15496fded03e1d132b57",
      p6: "60be154c09668b59c879c749",
      p7: "60be15506fded03e1d132b58",
    },
    test9: {
      p1: "60be15ba2a1f882e4a506c9b",
      p2: "60be15bd6fded03e1d132b59",
      p3: "60be15c12a1f882e4a506c9c",
      p4: "60be15c46fded03e1d132b5a",
      p5: "60be15c82a1f882e4a506c9d",
      p6: "60be15cb6fded03e1d132b5b",
      p7: "60be15ce2a1f882e4a506c9e",
    },
  },
  real: {
    test1: {
      p1: "60be166709668b59c879c74b",
      p2: "60be166a2a1f882e4a506c9f",
      p3: "60be166d6fded03e1d132b5e",
      p4: "60be16712a1f882e4a506ca0",
      p5: "60be16746fded03e1d132b5f",
      p6: "60be16772a1f882e4a506ca1",
      p7: "60be167b09668b59c879c74c",
    },
    test2: {
      p1: "60be16aa09668b59c879c74d",
      p2: "60be16ad2a1f882e4a506ca2",
      p3: "60be16b06fded03e1d132b60",
      p4: "60be16b42a1f882e4a506ca3",
      p5: "60be16b809668b59c879c74e",
      p6: "60be16ba2a1f882e4a506ca4",
      p7: "60be16be6fded03e1d132b62",
    },
    test3: {
      p1: "60be16fb6fded03e1d132b63",
      p2: "60be16ff2a1f882e4a506ca5",
      p3: "60be170209668b59c879c74f",
      p4: "60be17062a1f882e4a506ca6",
      p5: "60be170a6fded03e1d132b64",
      p6: "60be170d2a1f882e4a506ca7",
      p7: "60be17106fded03e1d132b65",
    },
    test4: {
      p1: "60be174b2a1f882e4a506ca8",
      p2: "60be174e09668b59c879c750",
      p3: "60be17512a1f882e4a506ca9",
      p4: "60be17546fded03e1d132b66",
      p5: "60be17582a1f882e4a506caa",
      p6: "60be175b09668b59c879c751",
      p7: "60be175e6fded03e1d132b67",
    },
  },
  long: {
    test1: {
      p1: "60be179c2a1f882e4a506cab",
      p2: "60be17a109668b59c879c752",
      p3: "60be17a42a1f882e4a506cac",
      p4: "60be17a809668b59c879c753",
      p5: "60be17ac2a1f882e4a506cad",
      p6: "60be17af09668b59c879c754",
      p7: "60be17b42a1f882e4a506cae",
    },
    test2: {
      p1: "60be17fb6fded03e1d132b69",
      p2: "60be17fe2a1f882e4a506cb0",
      p3: "60be18026fded03e1d132b6a",
      p4: "60be18062a1f882e4a506cb1",
      p5: "60be180900ece9235a0370b2",
      p6: "60be180c2a1f882e4a506cb2",
      p7: "60be18106fded03e1d132b6b",
    },
  },
};

export default idraw;
