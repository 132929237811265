import { useEffect, useState } from "react";
import { Typography, Modal, Pagination, Spin, Button } from "antd";
import { ExclamationCircleOutlined, LeftOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import {
  CREATE_QUESTION_CLUSTER_PATH,
  LIST_QUESTION_PATH,
  LIST_TEST_PATH,
  PREVIEW_TEST_PATH,
  UPDATE_QUESTION_CLUSTER_PATH,
  TESTING_PATH,
} from "paths";
import QuestionCluster from "components/QuestionCluster";
import api from "api";

const { Title } = Typography;
const { confirm } = Modal;

function QuestionClusterPage() {
  const [questionClusters, setQuestionClusters] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { testID, testSetID } = useParams();

  const getQuestionClusters = (nextPage) => {
    setLoading(true);
    api(`/question-cluster/search-by-test-id/${testID}`, {
      params: { page: nextPage },
    })
      .then((data) => {
        const { docs } = data;
        if (Array.isArray(docs)) {
          setQuestionClusters(docs);
        }
        setTotal(data.total);
        setPage(nextPage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getQuestionClusters(page);
  }, []);

  const deleteByID = (id) => {
    api(`/question-cluster/delete/${id}`).then(() => {
      getQuestionClusters(page);
    });
  };

  const showDeleteConfirm = (questionCluster) => {
    confirm({
      title: `Are you sure delete the question cluster No.${questionCluster.index}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteByID(questionCluster._id);
      },
    });
  };

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex">
          <LeftOutlined
            onClick={() => {
              history.push(LIST_TEST_PATH(testSetID));
            }}
            style={{
              fontSize: "25px",
              marginTop: "10px",
              marginRight: "30px",
              cursor: "pointer",
            }}
          />
          <Title>List Question Cluster</Title>
        </div>

        <div className="mt-2 flex">
          <Button
            className="mr-5"
            onClick={() => {
              history.push(TESTING_PATH(testSetID, testID));
            }}
          >
            Testing
          </Button>
          <Button
            className="mr-5"
            onClick={() => {
              history.push(PREVIEW_TEST_PATH(testSetID, testID));
            }}
          >
            Preview
          </Button>

          <Button
            type="primary"
            onClick={() => {
              history.push(CREATE_QUESTION_CLUSTER_PATH(testSetID, testID));
            }}
          >
            Create
          </Button>
        </div>
      </div>

      <Spin spinning={loading}>
        <div className="mb-6 mt-6 flex justify-end">
          <Pagination
            onChange={(nextPage) => {
              getQuestionClusters(nextPage);
            }}
            current={page}
            total={total}
          />
        </div>

        {questionClusters.map((questionCluster, index) => (
          <div
            key={index}
            className="mb-3 p-5 border border-gray-300 rounded-md hover:border-green-500"
          >
            <QuestionCluster questionCluster={questionCluster} />

            <div className="flex justify-between mt-5">
              <Button
                className="mr-5"
                onClick={() => {
                  history.push(
                    LIST_QUESTION_PATH(testSetID, testID, questionCluster._id)
                  );
                }}
              >
                Detail
              </Button>
              <div className="flex">
                <Button
                  className="mr-5"
                  onClick={() => {
                    history.push(
                      UPDATE_QUESTION_CLUSTER_PATH(
                        testSetID,
                        testID,
                        questionCluster._id
                      )
                    );
                  }}
                >
                  Edit
                </Button>
                <Button
                  danger
                  onClick={() => showDeleteConfirm(questionCluster)}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        ))}
      </Spin>
    </div>
  );
}

export default QuestionClusterPage;
