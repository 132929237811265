/* eslint-disable no-await-in-loop */
import { Typography, Button, Row, Col } from "antd";
import api from "api";
import { to } from "utils";

const { Title } = Typography;

const endpointMedia = "https://toeicx-dev.s3.ap-southeast-1.amazonaws.com/";
// const endpointMedia = "https://toeicx-prd.s3.ap-southeast-1.amazonaws.com/";

function InsertFullTest() {
  const replaceImageContent = async (content, key) => {
    const imagePath = /(?<=<img src=")(.*)(?=.png")/;
    const imageLink = content.match(imagePath);
    if (imageLink) {
      const serverLink = await api.post("/raw-tool/upload-image", {
        link: `${imageLink[0]}.png`,
        key,
      });

      const newContent = content.replace(
        `${imageLink[0]}.png`,
        endpointMedia + serverLink
      );
      return newContent;
    }
    return content;
  };

  const addData = async (testID, testData, indexStart = 1) => {
    const data = testData;
    for (let i = 0; i < data.length; i += 1) {
      const content = await replaceImageContent(
        data[i].content,
        "question-cluster"
      );
      const listening = await replaceImageContent(
        data[i].listening,
        "question-cluster"
      );
      const translate = await replaceImageContent(
        data[i].translate,
        "question-cluster"
      );
      let audioLink = "";
      if (data[i].audio) {
        audioLink = await api.post("/raw-tool/upload-audio", {
          link: data[i].audio,
          key: "question-cluster",
        });
      }
      const [errQuestionCluster, questionCluster] = await to(
        api.post("/question-cluster", {
          index: indexStart + i,
          audio: audioLink ? endpointMedia + audioLink.replace("/src", "") : "",
          part: data[i].part,
          title: data[i].title,
          content,
          listening,
          translate,
          testID,
        })
      );
      if (errQuestionCluster) {
        console.error(errQuestionCluster);
        return;
      }
      for (let j = 0; j < data[i].questions.length; j += 1) {
        const [errQuestion] = await to(
          api.post("/question", {
            questionClusterID: questionCluster._id,
            number: data[i].questions[j].number,
            title: "",
            question: data[i].questions[j].question,
            translate: data[i].questions[j].translate,
            options: data[i].questions[j].options,
            answer: data[i].questions[j].answer,
            explain: data[i].questions[j].explain,
          })
        );
        if (errQuestion) {
          return;
        }
      }
    }
  };

  const addTestDataToeic = async ({
    testAudios,
    testSetID,
    testSetIndex,
    testSetTitle,
    totalTest,
    skipTest,
  }) => {
    const [errTestSet, testSet] = await to(
      api.post("/test-set", {
        index: testSetIndex,
        title: testSetTitle,
        type: "FULL_TEST",
      })
    );
    if (errTestSet) {
      console.error(errTestSet);
      return;
    }
    for (let i = 1; i < totalTest + 1; i += 1) {
      if (skipTest && i === skipTest) {
        // eslint-disable-next-line no-continue
        continue;
      }
      const testData = await import(
        `datajson/FULL-TEST/${testSetID}/TEST-${i}/data`
      );
      const audioLink = await api.post("/raw-tool/upload-audio", {
        link: testAudios[i - 1],
        key: "test",
      });
      const [errTest, test] = await to(
        api.post("/test", {
          index: i,
          audio: endpointMedia + audioLink,
          title: `TEST ${i}`,
          testSetID: testSet._id,
        })
      );
      if (errTest) {
        return;
      }
      await addData(test._id, testData.default);
    }
  };

  const addTestDataToeicETS2020 = async () => {
    const totalTest = 10;
    const testAudios = [];
    for (let i = 1; i < totalTest + 1; i += 1) {
      testAudios.push(
        `https://toeicez.com/medias/toeicfull/ets_toeic_2020_test_${i}/ets_toeic_2020_test_${i}.mp3`
      );
    }
    await addTestDataToeic({
      testAudios,
      totalTest,
      testSetID: "ETS2020",
      testSetIndex: 2,
      testSetTitle: "ETS 2020",
    });
  };

  const addTestDataToeicETS2021 = async () => {
    const totalTest = 5;
    const testAudios = [];
    for (let i = 1; i < totalTest + 1; i += 1) {
      testAudios.push(
        `https://toeicez.com/medias/toeicfull/ets_summer_2021_test_${i}/Test_${
          i < 10 ? `0${i}` : i
        }.mp3`
      );
    }
    await addTestDataToeic({
      testAudios,
      totalTest,
      testSetID: "ETS2021",
      testSetIndex: 2,
      testSetTitle: "ETS 2021",
    });
  };

  const addTestDataToeicETS2022 = async () => {
    const totalTest = 10;
    const testAudios = [];
    for (let i = 1; i < totalTest + 1; i += 1) {
      testAudios.push(
        `https://toeicez.com/medias/toeicfull/ets2022/ets_toeic_2022_test_${i}/ets_2022_test${
          i < 10 ? `0${i}` : i
        }.mp3`
      );
    }
    await addTestDataToeic({
      testAudios,
      totalTest,
      testSetID: "ETS2022",
      testSetIndex: 2,
      testSetTitle: "ETS 2022",
    });
  };

  const addTestDataToeicETS2023 = async () => {
    const totalTest = 10;
    const testAudios = [];
    for (let i = 1; i < totalTest + 1; i += 1) {
      testAudios.push(
        `https://toeicez.com/medias/toeicfull/ets2023/ets_2023_test${
          i < 10 ? `0${i}` : i
        }/ets_2023_test${i < 10 ? `0${i}` : i}.mp3`
      );
    }
    await addTestDataToeic({
      testAudios,
      totalTest,
      testSetID: "ETS2023",
      testSetIndex: 2,
      testSetTitle: "ETS 2023",
    });
  };

  const addTestDataToeicETS2024 = async () => {
    const totalTest = 10;
    const testAudios = [];
    for (let i = 1; i < totalTest + 1; i += 1) {
      // https://toeicez.com/medias/toeicfull/ets2024/ets_2024_test01/ets_2024_test01.mp3
      testAudios.push(
        `https://toeicez.com/medias/toeicfull/ets2024/ets_2024_test${
          i < 10 ? `0${i}` : i
        }/ets_2024_test${i < 10 ? `0${i}` : i}.mp3`
      );
    }
    await addTestDataToeic({
      testAudios,
      totalTest,
      testSetID: "ETS2024",
      testSetIndex: 2,
      testSetTitle: "ETS 2024",
      skipTest: 5,
    });
  };

  const addTestDataToeicETS2019 = async () => {
    const totalTest = 10;
    const testAudios = [];
    for (let i = 1; i < totalTest + 1; i += 1) {
      testAudios.push(
        `https://toeicez.com/medias/toeicfull/ets_toeic_2019_test_${i}/ets_toeic_2019_test_${i}.mp3`
      );
    }
    await addTestDataToeic({
      testAudios,
      totalTest,
      testSetID: "ETS2019",
      testSetIndex: 3,
      testSetTitle: "ETS 2019",
    });
  };

  const addTestDataToeicETS2018 = async () => {
    const totalTest = 5;
    const testAudios = [];
    for (let i = 1; i < totalTest + 1; i += 1) {
      testAudios.push(
        `https://toeicez.com/medias/toeicfull/ets_toeic_2018_test_${i}/ets_toeic_2018_test_${i}.mp3`
      );
    }
    await addTestDataToeic({
      testAudios,
      totalTest,
      testSetID: "ETS2018",
      testSetIndex: 4,
      testSetTitle: "ETS 2018",
    });
  };

  const addTestDataToeicETS = async () => {
    const totalTest = 7;
    const testAudios = [];
    for (let i = 1; i < totalTest + 1; i += 1) {
      testAudios.push(
        `https://toeicez.com/medias/toeicfull/ets_toeic_test_${i}/ets_toeic_test_${i}.mp3`
      );
    }
    await addTestDataToeic({
      testAudios,
      totalTest,
      testSetID: "ETS",
      testSetIndex: 5,
      testSetTitle: "ETS",
    });
  };

  const addTestDataToeicECO = async () => {
    const totalTest = 9;
    const testAudios = [
      "https://toeicez.com/medias/toeicfull/eco_toeic_1000_test_1/eco_toeic_1000_test_1.mp3",
      "https://toeicez.com/medias/toeicfull/eco_toeic_1000_test_2/eco_toeic_1000_test_2.mp3",
      "https://toeicez.com/medias/toeicfull/eco_toeic_1000_test_3/eco_toeic_1000_test_3.mp3",
      "https://toeicez.com/medias/toeicfull/eco_toeic_1000_test_3/eco_toeic_1000_test_4.mp3",
      "https://toeicez.com/medias/toeicfull/eco_toeic_1000_test_3/eco_toeic_1000_test_5.mp3",
      "https://toeicez.com/medias/toeicfull/economy_toeic_4_test_1/economy_toeic_4_test_1.mp3",
      "https://toeicez.com/medias/toeicfull/economy_toeic_4_test_5/economy_toeic_4_test_5.mp3",
      "https://toeicez.com/medias/toeicfull/economy_toeic_5_test_3/economy_toeic_5_test_3.mp3",
      "https://toeicez.com/medias/toeicfull/economy_toeic_5_test_6/economy_toeic_5_test_6.mp3",
    ];
    await addTestDataToeic({
      testAudios,
      totalTest,
      testSetID: "ECO",
      testSetIndex: 6,
      testSetTitle: "ECOMONY 1000",
    });
  };

  const addTestDataToeicREAL = async () => {
    const totalTest = 4;
    const testAudios = [
      "https://toeicez.com/medias/toeicfull/new_real_toeic_test_1/new_real_toeic_test_1.mp3",
      "https://toeicez.com/medias/toeicfull/new_real_toeic_test_2/new_real_toeic_test_2.mp3",
      "https://toeicez.com/medias/toeicfull/new_real_toeic_test_3/new_real_toeic_test_3.mp3",
      "https://toeicez.com/medias/toeicfull/new_real_toeic_pre_test/new_real_toeic_pre_test.mp3",
    ];
    await addTestDataToeic({
      testAudios,
      totalTest,
      testSetID: "REAL",
      testSetIndex: 7,
      testSetTitle: "REAL TOEIC TEST",
    });
  };

  const addTestDataToeicLONG = async () => {
    const totalTest = 2;
    const testAudios = [
      "https://toeicez.com/medias/toeicfull/longman_practice_tests_test_2/longman_practice_tests_test_2.mp3",
      "https://toeicez.com/medias/toeicfull/longman_practice_tests_test_3/longman_practice_tests_test_3.mp3",
    ];
    await addTestDataToeic({
      testAudios,
      totalTest,
      testSetID: "LONG",
      testSetIndex: 8,
      testSetTitle: "LONGMAN",
    });
  };

  const clearData = async () => {
    const [errTest] = await to(api.get("/clear"));
    if (errTest) {
      console.error(errTest);
    }
  };

  const addAll = async () => {
    await addTestDataToeicETS2020();
    await addTestDataToeicETS2019();
    await addTestDataToeicETS2018();
    await addTestDataToeicETS();
    await addTestDataToeicECO();
    await addTestDataToeicREAL();
    await addTestDataToeicLONG();
  };
  return (
    <div>
      <Title>Insert Full Test</Title>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Button className="w-full" onClick={addTestDataToeicETS2024}>
            Add Data ETS 2024
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={addTestDataToeicETS2023}>
            Add Data ETS 2023
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={addTestDataToeicETS2022}>
            Add Data ETS 2022
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={addTestDataToeicETS2021}>
            Add Data ETS 2021
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={addTestDataToeicETS2020}>
            Add Data ETS 2020
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={addTestDataToeicETS2019}>
            Add Data ETS 2019
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={addTestDataToeicETS2018}>
            Add Data ETS 2018
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={addTestDataToeicETS}>
            Add Data ETS
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={addTestDataToeicECO}>
            Add Data ECO
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={addTestDataToeicREAL}>
            Add Data REAL
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={addTestDataToeicLONG}>
            Add Data LONGMAN
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={addAll}>
            Add ALL
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={clearData}>
            Clear Data
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default InsertFullTest;
