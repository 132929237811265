function QuestionTestingResult({ question, answerTesting }) {
  return (
    <div key={question._id} className="mb-10">
      <div className="mb-2">
        <p>
          <b>({question.number})</b> {question.title && question.title}
        </p>
        {question.question && <p>{question.question}</p>}
        {question.translate && (
          <p>
            <b>Translate: </b> {question.translate}
          </p>
        )}
      </div>

      {question.options.map((option) => (
        <div
          key={option._id}
          className={`mb-2 p-2 rounded-md border-2 ${
            question.answer === option.key && "border-blue-500"
          } ${
            answerTesting === option.key &&
            question.answer !== option.key &&
            "border-red-500"
          }`}
        >
          <p>
            <b>{option.key}</b>. {option.value}
          </p>
          <p>
            <b>Translate:</b> {option.translate}
          </p>
        </div>
      ))}
      <b>Explain:</b>
      <div
        dangerouslySetInnerHTML={{
          __html: question.explain,
        }}
      />
    </div>
  );
}

export default QuestionTestingResult;
