const getKeysFromArray = (xs, key) => {
  const keys = [];
  xs.forEach((i) => {
    if (!keys.includes(i[key])) {
      keys.push(i[key]);
    }
  });
  return keys;
};

const getDateString = (date) => {
  const m = new Date(date);
  const dateString = `${`0${m.getUTCDate()}`.slice(-2)}-${`0${
    m.getUTCMonth() + 1
  }`.slice(-2)}-${m.getUTCFullYear()}  ${`0${m.getUTCHours()}`.slice(
    -2
  )}:${`0${m.getUTCMinutes()}`.slice(-2)}:${`0${m.getUTCSeconds()}`.slice(-2)}`;

  return dateString;
};

const to = (promise) => {
  return promise.then((data) => [null, data]).catch((err) => [err]);
};

export { getKeysFromArray, getDateString, to };
