import { useState } from "react";
import { Typography, Input, Form, Spin, Button } from "antd";
import { useHistory } from "react-router-dom";
import { PATH_NAME } from "paths";
import api from "api";

const { Title } = Typography;

function NotificationCreatePage() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const history = useHistory();

  const create = (value) => {
    setLoading(true);
    api
      .post("/notification", value)
      .then(() => {
        history.push(PATH_NAME.NOTIFICATIONS);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinish = (value) => {
    create(value);
  };

  return (
    <div>
      <Title>Create Notification</Title>
      <Spin spinning={loading}>
        <div className="mb-5">
          <Form
            form={form}
            name="create_notification"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: "Please input title!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Body"
              name="body"
              rules={[{ required: true, message: "Please input body!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Image"
              name="image"
              rules={[{ required: true, message: "Please input image!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <div className="flex mt-5">
                <Button
                  type="primary"
                  className="mr-5"
                  onClick={() => form.submit()}
                >
                  Create
                </Button>
                <Button
                  onClick={() => {
                    history.push(PATH_NAME.NOTIFICATIONS);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </div>
  );
}

export default NotificationCreatePage;
