import { useEffect, useState } from "react";
import { Typography, Modal, Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import {
  CREATE_VOCA_CATEGORY_PATH,
  UPDATE_VOCA_CATEGORY_PATH,
  VOCA_PATH,
} from "paths";
import api from "api";

const { Title } = Typography;
const { confirm } = Modal;

function VocaCategoriesPage() {
  const [vocaCategories, setVocaCategories] = useState([]);
  const history = useHistory();

  const getVocaCategories = () => {
    api("/voca-category").then((data) => {
      if (Array.isArray(data)) {
        setVocaCategories(
          data.sort(function (a, b) {
            return a.index - b.index;
          })
        );
      }
    });
  };

  useEffect(() => {
    getVocaCategories();
  }, []);

  const deleteByID = (id) => {
    api(`/voca-category/delete/${id}`).then(() => {
      getVocaCategories();
    });
  };

  const showDeleteConfirm = (vocaCategory) => {
    confirm({
      title: `Are you sure delete ${vocaCategory.title}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteByID(vocaCategory._id);
      },
    });
  };

  return (
    <div>
      <div className="flex justify-between">
        <Title>List Voca Category</Title>
        <Button
          type="primary"
          className="mt-2"
          onClick={() => {
            history.push(CREATE_VOCA_CATEGORY_PATH());
          }}
        >
          Create
        </Button>
      </div>

      <div className="flex flex-wrap content-evenly">
        {vocaCategories.map((vocaCategory, index) => (
          <div
            key={index}
            style={{ minWidth: "300px" }}
            className="mb-8 p-5 border border-gray-300 mr-8 rounded-md hover:border-green-500"
          >
            <div className="flex justify-between">
              <p>
                <b className="text-white bg-gray-500 p-1 rounded">
                  {vocaCategory.index}.
                </b>
              </p>

              {vocaCategory.membership === "FREE" ? (
                <p>
                  <b className="text-white bg-gray-300 p-1 rounded">
                    {vocaCategory.membership}
                  </b>
                </p>
              ) : (
                <p>
                  <b className="text-white bg-green-400 p-1 rounded">
                    {vocaCategory.membership}
                  </b>
                </p>
              )}
            </div>

            <img
              style={{ height: "150px", width: "150px" }}
              alt="Voca Category"
              src={vocaCategory.imgSrc}
              className="rounded-md mt-2"
            />

            <div className="mt-2">
              <b> {vocaCategory.title}</b>
            </div>

            <div className="flex justify-between mt-5">
              <Button
                className="mr-5"
                onClick={() => {
                  history.push(VOCA_PATH(vocaCategory._id));
                }}
              >
                Detail
              </Button>
              <div className="flex">
                <Button
                  className="mr-5"
                  onClick={() => {
                    history.push(UPDATE_VOCA_CATEGORY_PATH(vocaCategory._id));
                  }}
                >
                  Edit
                </Button>
                <Button danger onClick={() => showDeleteConfirm(vocaCategory)}>
                  Delete
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default VocaCategoriesPage;
