/* eslint-disable no-await-in-loop */
import { Typography, Button } from "antd";
import api from "api";
import { to } from "utils";

const { Title } = Typography;

const mediaEndpoint = "http://139.59.232.33:4000";

function InsertSkillTest() {
  const replaceImageContent = async (content) => {
    const imagePath = /(?<=src="https)(.*)(?=.jpg")/;
    const imageLink = content.match(imagePath);

    if (imageLink) {
      const serverLink = await api.post("/raw-tool/upload-image", {
        link: `https${imageLink[0]}.jpg`,
        key: "question-cluster",
      });

      const newContent = content.replace(
        `https${imageLink[0]}.jpg`,
        mediaEndpoint + serverLink
      );
      return newContent;
    }
    return content;
  };

  const addData = async (testID, testData, indexStart = 1) => {
    const data = testData;

    let questionIndex = 1;

    for (let i = 0; i < data.length; i += 1) {
      const content = await replaceImageContent(data[i].content);
      const listening = await replaceImageContent(data[i].listening);
      const translate = await replaceImageContent(data[i].translate);

      let audioLink = "";
      if (data[i].audio) {
        audioLink = await api.post("/raw-tool/upload-audio", {
          link: data[i].audio,
          key: "question-cluster",
        });
      }

      const [errQuestionCluster, questionCluster] = await to(
        api.post("/question-cluster", {
          index: indexStart + i,
          audio: audioLink ? mediaEndpoint + audioLink.replace("/src", "") : "",
          part: data[i].part,
          title: `Question: ${
            data[i].questions.length > 1
              ? `${questionIndex} - ${
                  questionIndex + data[i].questions.length - 1
                }`
              : questionIndex
          }`,
          content,
          listening,
          translate,
          testID,
        })
      );

      if (errQuestionCluster) {
        console.error(errQuestionCluster);
        return;
      }

      for (let j = 0; j < data[i].questions.length; j += 1) {
        const [errQuestion] = await to(
          api.post("/question", {
            questionClusterID: questionCluster._id,
            number: questionIndex,
            title: "",
            question: data[i].questions[j].question,
            translate: data[i].questions[j].translate,
            options: data[i].questions[j].options,
            answer: data[i].questions[j].answer,
            explain: data[i].questions[j].explain,
          })
        );
        questionIndex += 1;

        if (errQuestion) {
          console.error(errQuestion);
          return;
        }
      }
    }
  };

  const addDataTest = async ({
    indexTestSet = 1,
    part = 1,
    level = 1,
    levelLabel = "250-450",
    dataPaths = [
      {
        label: "Photo of People",
        path: "250-450/PART-1/PHOTO-OF-PEOPLE/data",
      },
    ],
  }) => {
    const [errTestSet, testSet] = await to(
      api.post("/test-set", {
        index: indexTestSet,
        title: `PART ${part} SKILL (${levelLabel})`,
        type: `PART${part}_SKILL`,
        level,
      })
    );
    if (errTestSet) {
      console.error(errTestSet);
      return;
    }
    let indexTest = 1;
    for (let i = 0; i < dataPaths.length; i += 1) {
      const testDatas = await import(
        `datajson/SKILL-TEST/${dataPaths[i].path}`
      );
      let currentTestID = "";
      let currentDataTest = [];
      for (let index = 0; index < testDatas.default.length; index += 1) {
        if (index === 0 || index % 10 === 0) {
          const [errTest, test] = await to(
            api.post("/test", {
              index: indexTest,
              audio: "",
              title: `${dataPaths[i].label}${
                testDatas.default.length > 10
                  ? index === 0
                    ? " - 1"
                    : ` - ${index / 10 + 1}`
                  : ""
              }`,
              testSetID: testSet._id,
            })
          );
          if (errTest) {
            console.error(errTest);
            return;
          }
          currentTestID = test._id;
          indexTest += 1;
        }
        currentDataTest.push(testDatas.default[index]);
        if (
          (index !== 0 && (index + 1) % 10 === 0) ||
          index + 1 === testDatas.default.length
        ) {
          await addData(currentTestID, currentDataTest);
          currentDataTest = [];
        }
      }
    }
  };

  const addAllData = async () => {
    const data = {
      level1: {
        part1: [
          { label: "Photos of People", path: "PHOTO-OF-PEOPLE" },
          { label: "Photos of Things", path: "PHOTO-OF-THING" },
        ],
        part2: [
          { label: "Identifying Time", path: "IDENTIFYING-TIME" },
          { label: "Identifying People", path: "IDENTIFYING-PEOPLE" },
          { label: "Identifying an Opinion", path: "IDENTIFYING-OPINION" },
          { label: "Identifying a Choice", path: "IDENTIFYING-CHOICE" },
          { label: "Identifying a Suggestion", path: "IDENTIFYING-SUGGESTION" },
          { label: "Identifying a Reason", path: "IDENTIFYING-REASON" },
          { label: "Identifying a Location", path: "IDENTIFYING-LOCATION" },
        ],
        part3: [
          { label: "Identifying Time", path: "IDENTIFYING-TIME" },
          { label: "Identifying People", path: "IDENTIFYING-PEOPLE" },
          {
            label: "Identifying Intent and Activities",
            path: "IDENTIFYING-INTENT-AND-ACTIVITIES",
          },
          { label: "Identifying the Topic", path: "IDENTIFYING-TOPIC" },
          { label: "Identifying a Reason", path: "IDENTIFYING-REASON" },
          { label: "Identifying a Location", path: "IDENTIFYING-LOCATION" },
          { label: "Identifying an Opinion", path: "IDENTIFYING-OPINION" },
          {
            label: "Identifying Stress and Tone",
            path: "IDENTIFYING-STRESS-TONE",
          },
        ],
        part4: [
          { label: "Identifying the Sequence", path: "IDENTIFYING-SEQUENCE" },
          {
            label: "Identifying the Audience and People",
            path: "IDENTIFYING-AUDIENCE-PEOPLE",
          },
          { label: "Identifying a Situation", path: "IDENTIFYING-SITUATION" },
          { label: "Identifying the Topic", path: "IDENTIFYING-TOPIC" },
          { label: "Identifying a Request", path: "IDENTIFYING-REQUEST" },
        ],
        part5: [
          { label: "Word Families", path: "WORD-FAMILIES" },
          { label: "Similar Words", path: "SIMILAR-WORDS" },
          { label: "Prepositions", path: "PREPOSITIONS" },
          { label: "Conjunctions", path: "CONJUNCTIONS" },
          { label: "Adverbs and Adjectives", path: "ADVERBS-AND-ADJECTIVES" },
          { label: "Pronouns", path: "PRONOUNS" },
          { label: "Conditional Sentences", path: "CONDITIONAL-SENTENCES" },
          { label: "Verb & Tenses", path: "VERB-&-TENSES" },
          { label: "Two-word Verbs", path: "TWO-WORD-VERBS" },
        ],
        part6: [{ label: "Strategy Review", path: "STRATEGY-REVIEW" }],
        part7: [
          { label: "Advertisements", path: "ADVERTISEMENTS" },
          { label: "Business Correspondence", path: "BUSINESS-CORRESPONDENCE" },
          {
            label: "Forms, Charts, and Graphs",
            path: "FORMS,-CHARTS,-AND-GRAPHS",
          },
          { label: "Articles and Reports", path: "ARTICLES-AND-REPORTS" },
          {
            label: "Announcements and Notices",
            path: "ANNOUNCEMENTS-AND-NOTICES",
          },
          { label: "Double passages", path: "DOUBLE-PASSAGES" },
        ],
      },
      level2: {
        part1: [
          { label: "Photos of people", path: "PHOTO-OF-PEOPLE" },
          { label: "Photos of things", path: "PHOTO-OF-THING" },
        ],
        part2: [
          { label: "Statements", path: "STATEMENTS" },
          { label: "Who", path: "WHO" },
          { label: "What", path: "WHAT" },
          { label: "When", path: "WHEN" },
          { label: "Where", path: "WHERE" },
          { label: "Why", path: "WHY" },
          { label: "How", path: "HOW" },
          { label: "Auxiliaries", path: "AUXILIARIES" },
        ],
        part3: [
          { label: "People and Occupations", path: "PEOPLE-OCCUPATIONS" },
          { label: "Activities", path: "ACTIVITIES" },
          { label: "Time", path: "TIME" },
          { label: "Locations", path: "LOCATIONS" },
          { label: "Reasons", path: "REASONS" },
        ],
        part4: [
          { label: "Advertisements", path: "ADVERTISEMENTS" },
          { label: "Weather", path: "WEATHER" },
          { label: "News", path: "NEWS" },
          { label: "Recorded Announcements", path: "RECORDED-ANNOUNCEMENTS" },
          { label: "Business Announcements", path: "BUSSINESS-ANNOUNCEMENTS" },
          { label: "Special Announcements", path: "SPECIAL-ANNOUNCEMENTS" },
        ],
        part5: [
          { label: "Word Choice: Nouns", path: "WORD-CHOICE:-NOUNS" },
          { label: "Word Choice: Verbs", path: "WORD-CHOICE:-VERBS" },
          { label: "Word Choice: Adjectives", path: "WORD-CHOICE:-ADJECTIVES" },
          { label: "Word Choice: Adverbs", path: "WORD-CHOICE:-ADVERBS" },
          {
            label: "Word Choice: Conjunctions",
            path: "WORD-CHOICE:-CONJUNCTIONS",
          },
          {
            label: "Word Choice: Prepositions",
            path: "WORD-CHOICE:-PREPOSITIONS",
          },
          { label: "Word Form: Nouns", path: "WORD-FORM:-NOUNS" },
          { label: "Word Form: Verbs", path: "WORD-FORM:-VERBS" },
          { label: "Word Form: Adjectives", path: "WORD-FORM:-ADJECTIVES" },
          { label: "Word Form: Adverbs", path: "WORD-FORM:-ADVERBS" },
          { label: "Word Form: Pronouns", path: "WORD-FORM:-PRONOUNS" },
        ],
        part6: [
          { label: "Verb and Tense", path: "VERB-AND-TENSE" },
          { label: "Modifiers", path: "MODIFIERS" },
        ],
        part7: [
          { label: "Advertisements", path: "ADVERTISEMENTS" },
          { label: "Forms", path: "FORMS" },

          {
            label: "Letters, E-mail, Faxes, and Memos",
            path: "LETTERS,-E-MAIL,-FAXES,-AND-MEMOS",
          },

          {
            label: "Tables, Indexes, and Charts",
            path: "TABLES,-INDEXES,-AND-CHARTS",
          },
          { label: "Articles and notices", path: "ARTICLES-AND-NOTICES" },
          { label: "Double passages", path: "DOUBLE-PASSAGES" },
        ],
      },
      level3: {
        part1: [
          { label: "Similar sounds", path: "SIMILAR-SOUNDS" },
          { label: "Prepositions", path: "PREPOSITIONS" },
          {
            label: "Words in a different context",
            path: "WORDS-IN-A-DIFFERENT-CONTEXT",
          },
        ],
        part2: [
          { label: "Who", path: "WHO" },
          { label: "What", path: "WHAT" },
          { label: "When", path: "WHEN" },
          { label: "Where", path: "WHERE" },
          { label: "Why", path: "WHY" },
          { label: "How", path: "HOW" },
          { label: "Auxiliaries", path: "AUXILIARIES" },
          { label: "Statements", path: "STATEMENTS" },
        ],
        part3: [
          { label: "Who", path: "WHO" },
          { label: "what", path: "WHAT" },
          { label: "when", path: "WHEN" },
          { label: "where", path: "WHERE" },
          { label: "why", path: "WHY" },
          { label: "how", path: "HOW" },
        ],
        part4: [
          { label: "Business Announcements", path: "BUSINESS-ANNOUNCEMENTS" },
          {
            label: "Special Messages and Announcements",
            path: "SPECIAL-MESSAGES-AND-ANNOUNCEMENTS",
          },
          {
            label: "Recorded Messages and Announcements",
            path: "RECORDED-MESSAGES-AND-ANNOUNCEMENTS",
          },
          { label: "Advertisements", path: "ADVERTISEMENTS" },
          { label: "News", path: "NEWS" },
          { label: "Weather", path: "WEATHER" },
        ],
        part5: [
          { label: "Modifiers", path: "MODIFIERS" },
          { label: "Conjunctions", path: "CONJUNCTIONS" },
          { label: "Prepositions", path: "PREPOSITIONS" },
          { label: "Transition Words", path: "TRANSITION-WORDS" },
          { label: "Nouns", path: "NOUNS" },
          { label: "Verbs: Conditional", path: "VERBS:-CONDITIONAL" },
          { label: "Verbs and Tenses", path: "VERBS-AND-TENSES" },
          { label: "Verbs: Two-Word", path: "VERBS:-TWO-WORD" },
          { label: "Word Families", path: "WORD-FAMILIES" },
        ],
        part6: [
          {
            label: "Comparisons and Superlatives",
            path: "COMPARISONS-AND-SUPERLATIVES",
          },
          { label: "Gerunds and Infinitives", path: "GERUNDS-AND-INFINITIVES" },
          { label: "Verbs and Tenses", path: "VERBS-AND-TENSES" },
          { label: "Participles", path: "PARTICIPLES" },
          { label: "Adjectives and Adverbs", path: "ADJECTIVES-AND-ADVERBS" },
          { label: "Nouns and Pronouns", path: "NOUNS-AND-PRONOUNS" },
        ],
        part7: [
          { label: "Advertisements", path: "ADVERTISEMENTS" },
          { label: "Articles and Bulletins", path: "ARTICLES-AND-BULLETINS" },
          { label: "Forms and Tables", path: "FORMS-AND-TABLES" },
          {
            label: "Notices and Announcements",
            path: "NOTICES-AND-ANNOUNCEMENTS",
          },
          {
            label: "Letters, E-Mail, and Memos",
            path: "LETTERS,-E-MAIL,-AND-MEMOS",
          },
          { label: "Double passages", path: "DOUBLE-PASSAGES" },
        ],
      },
    };
    let indexTestSet = 10;
    for (let i = 1; i < 4; i += 1) {
      const levelLabel = i === 1 ? "250-450" : i === 2 ? "450-650" : "650-990";
      for (let pi = 1; pi < 8; pi += 1) {
        const dataPaths = [];
        for (
          let pip = 0;
          pip < data[`level${i}`][`part${pi}`].length;
          pip += 1
        ) {
          dataPaths.push({
            label: data[`level${i}`][`part${pi}`][pip].label,
            path: `${levelLabel}/PART-${pi}/${
              data[`level${i}`][`part${pi}`][pip].path
            }/data`,
          });
        }
        await addDataTest({
          indexTestSet,
          part: pi,
          level: i,
          levelLabel,
          dataPaths,
        });
        indexTestSet += 1;
      }
    }
  };

  return (
    <div>
      <Title>Insert Skill Test</Title>
      <Button onClick={addAllData}>Add all</Button>
    </div>
  );
}

export default InsertSkillTest;
