import { useEffect, useState } from "react";
import { Typography, Input, Select, Spin, Form, Button } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { PATH_NAME } from "paths";
import UploadImage from "components/UploadImage";
import api from "api";

const { Title } = Typography;
const { Option } = Select;

function UserCreatePage() {
  const [imgList, setImgList] = useState([]);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();

  const history = useHistory();
  const { userID } = useParams();

  useEffect(() => {
    if (userID) {
      api(`/user/${userID}`).then((data) => {
        setUser(data);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  const create = (value) => {
    api.post("/user", value).then(() => {
      history.push(PATH_NAME.USERS);
    });
  };

  const update = (value) => {
    api.put("/user", { _id: userID._id, ...value }).then(() => {
      history.push(PATH_NAME.USERS);
    });
  };

  const onFinish = (value) => {
    userID ? update(value) : create(value);
  };

  return (
    <div>
      <Title>{userID ? "Update" : "Create"} User</Title>
      {loading ? (
        <Spin />
      ) : (
        <div>
          <UploadImage
            keyImage="avatar"
            imageList={imgList}
            onChangeImageList={(list) => {
              setImgList(list);
            }}
          />

          <div className="mt-5">
            <Form
              form={form}
              name="createupdateuser"
              layout="vertical"
              initialValues={user}
              onFinish={onFinish}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: "Please input user name!" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Please input your name!" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Phone"
                name="phone"
                rules={[
                  { required: true, message: "Please input your phone!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Role"
                name="role"
                rules={[{ required: true, message: "Please input your role!" }]}
              >
                <Select>
                  <Option value="ADMIN">ADMIN</Option>
                  <Option value="USER">USER</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Image"
                name="picture"
                rules={[
                  { required: true, message: "Please input image link!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
          </div>

          <div className="flex mt-5">
            <Button
              className="mr-5"
              type="primary"
              onClick={() => form.submit()}
            >
              {userID ? "Update" : "Create"}
            </Button>
            <Button
              onClick={() => {
                history.push("/user");
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserCreatePage;
