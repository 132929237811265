import { useEffect, useState } from "react";
import { Typography, Form, Spin, InputNumber, Input, Button } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { TIP_PATH } from "paths";
import UploadImage from "components/UploadImage";
import RichTextEditor from "components/RichTextEditor";
import api from "api";

const { Title } = Typography;

function TipCreatePage() {
  const [form] = Form.useForm();

  const [tip, setTip] = useState({});

  const [loading, setLoading] = useState(true);
  const [imageList, setImageList] = useState([]);

  const history = useHistory();
  const { tipID } = useParams();

  useEffect(() => {
    if (tipID) {
      api(`/tip/${tipID}`).then((data) => {
        setTip(data);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  const create = (value) => {
    api.post("/tip", value).then(() => {
      history.push(TIP_PATH());
    });
  };

  const update = (value) => {
    api.put("/tip", { _id: tip._id, ...value }).then(() => {
      history.push(TIP_PATH());
    });
  };

  const onFinish = (value) => {
    tipID ? update(value) : create(value);
  };

  return (
    <div>
      <Title>{tipID ? "Update" : "Create"} Tip</Title>
      {loading ? (
        <Spin />
      ) : (
        <div>
          <div className="mt-5">
            <div className="mb-5">
              <UploadImage
                keyImage="tip"
                imageList={imageList}
                onChangeImageList={(list) => setImageList(list)}
              />
            </div>

            <Form
              form={form}
              name="createupdatetestset"
              layout="vertical"
              initialValues={tip}
              onFinish={onFinish}
            >
              <Form.Item
                label="Index"
                name="index"
                rules={[{ required: true, message: "Please input index!" }]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: "Please input title!" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Banner"
                name="banner"
                rules={[{ required: true, message: "Please input banner!" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Content"
                name="content"
                rules={[{ required: true, message: "Please input content!" }]}
              >
                <RichTextEditor />
              </Form.Item>
            </Form>

            <div className="flex">
              <Button
                className="mr-5"
                type="primary"
                onClick={() => form.submit()}
              >
                {tipID ? "Update" : "Create"}
              </Button>
              <Button
                onClick={() => {
                  history.push(TIP_PATH());
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TipCreatePage;
