/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-use-before-define */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-continue */
import { Typography, Button, Col, Row } from "antd";
import cheerio from "cheerio";
import api from "api";
import idraw from "datajson/dataTestID/data";
import { to } from "utils";

const { Title } = Typography;

function RawFullTest() {
  const getOption = (card, $) => {
    const optionQuestions = [];
    const optionDoms = $(card).find(".form-check");
    let answer = "";
    for (let j = 0; j < optionDoms.length; j += 1) {
      const optionValueDom = optionDoms[j];

      const translateOptionDom = $(optionDoms[j]).find(
        ".small.pl-4.text-muted"
      )[0];

      const optionValue = optionValueDom.children[2].data.toString().trim();
      const key = optionValue.split(" ")[0];

      if (optionValueDom.attribs.class.includes("text-success")) {
        answer = key.replace("(", "").replace(")", "");
      }
      optionQuestions.push({
        key: key.replace("(", "").replace(")", ""),
        value: optionValue.replace(key, "").trim(),
        translate: translateOptionDom.firstChild?.data.toString().trim(),
        isCorrect: optionValueDom.attribs.class.includes("text-success"),
      });
    }
    return [answer, optionQuestions];
  };

  const getQuestions = (
    cards,
    $,
    isGetAudio = false,
    isGetExplain = false,
    isGetTranslateQuestion = true
  ) => {
    const questions = [];
    for (let i = 0; i < cards.length; i += 1) {
      const card = cards[i];

      const contentsDom = $(card).find(".col.p-2 img");

      if (contentsDom.length > 0) {
        continue;
      }

      let audio = "";
      if (isGetAudio) {
        audio = $(card).find("audio > source")[0];
      }
      const questionValue = $(card).find(
        ".font-weight-bold.mb-0.text-justify"
      )[0];

      let translate = "";
      if (isGetTranslateQuestion) {
        translate = $(card).find(
          ".font-weight-bold.small.text-muted.text-justify.pl-4"
        )[0];
      }

      const [answer, optionQuestions] = getOption(card, $);

      let explain = "";
      if (isGetExplain) {
        explain = $(card).find(".card-footer.h-100.alert-info");
      }

      const number = questionValue.firstChild.data
        .toString()
        .trim()
        .split(".")[0]
        .trim();

      const question = {
        number,
        audio: isGetAudio ? audio.attribs.src : "",
        question: questionValue.firstChild.data
          .toString()
          .trim()
          .replace(`${number} .`, "")
          .trim(),
        translate: isGetTranslateQuestion
          ? translate.firstChild.data.toString().trim()
          : "",
        options: optionQuestions,
        explain: isGetExplain ? explain.html() : "",
        answer,
      };
      questions.push(question);
    }
    return questions;
  };

  const rawp1 = async (id) => {
    const [errResponse, response] = await to(
      api.get(`/raw-tool/raw-data/${id}`)
    );

    if (errResponse) {
      return [errResponse];
    }
    const questionClusters = [];
    const $ = cheerio.load(response);
    const cards = $(".card.my-3");

    for (let i = 0; i < cards.length; i += 1) {
      const card = cards[i];
      const audio = $(card).find("audio > source")[0];
      const audioSource = audio.attribs.src;
      const content = $(card)
        .find(".card.my-3  .text-center.w-100.p-2")
        .html()
        .trim();
      const number = $(card)
        .find(".card.my-3 .font-weight-bold.mb-0.text-justify")
        .text()
        .trim()
        .replace(".", "");
      const [answer, options] = getOption(card, $);

      questionClusters.push({
        part: "1",
        title: `Question ${number}:`,
        audio: audioSource,
        content,
        translate: "",
        listening: "",
        questions: [
          {
            number,
            question: "",
            translate: "",
            options,
            explain: "",
            answer,
          },
        ],
      });
    }
    return [null, questionClusters];
  };

  const rawp2 = async (id) => {
    const [errResponse, response] = await to(
      api.get(`/raw-tool/raw-data/${id}`)
    );

    if (errResponse) {
      return [errResponse];
    }

    const $ = cheerio.load(response);
    const cards = $(".col.p-2");
    const questions = getQuestions(cards, $, true);

    const questionClusters = questions.map((question) => ({
      part: "2",
      title: `Question ${question.number}:`,
      audio: question.audio,
      content: "",
      translate: "",
      listening: "",
      questions: [question],
    }));
    return [null, questionClusters];
  };

  const rawp3 = async (id) => {
    const [errResponse, response] = await to(
      api.get(`/raw-tool/raw-data/${id}`)
    );

    if (errResponse) {
      return [errResponse];
    }

    const questionClusters = [];
    const $ = cheerio.load(response);
    const cards = $(".card.mt-4");
    for (let i = 0; i < cards.length; i += 1) {
      const card = cards[i];
      const listening = $(card).find(
        ".col-sm-6.bg-light.px-3.pt-2.border-right.border-bottom.text-justify"
      );

      const translate = $(card)
        .find(".col-sm-6.bg-light.border-bottom.px-3.pt-2.text-justify")
        .get(1);

      const title = $(card).find(".card-header.font-weight-bold.text-center");
      const audio = $(card).find("audio > source")[0];
      let contentText = "";
      const contentsDom = $(card).find(".col.p-2 img");

      if (contentsDom.length > 0) {
        contentText = $(card).find(".col.p-2 .car-body.p-2").html();
      }

      const questions = $(card).find(".col.p-2");
      const questionCluster = {
        part: "3",
        audio: audio.attribs.src,
        content: contentText,
        listening: listening.html().trim(),
        translate: $(translate).html().trim(),
        title: `${title.text().trim()}:`,
        questions: getQuestions(questions, $),
      };
      questionClusters.push(questionCluster);
    }
    return [null, questionClusters];
  };

  const rawp4 = async (id) => {
    const [errResponse, response] = await to(
      api.get(`/raw-tool/raw-data/${id}`)
    );

    if (errResponse) {
      return [errResponse];
    }

    const questionClusters = [];
    const $ = cheerio.load(response);
    const cards = $(".card.mt-4");
    for (let i = 0; i < cards.length; i += 1) {
      const card = cards[i];
      const listening = $(card).find(
        ".col-sm-6.bg-light.px-3.pt-2.border-right.border-bottom.text-justify"
      );

      const translate = $(card)
        .find(".col-sm-6.bg-light.border-bottom.px-3.pt-2.text-justify")
        .get(1);

      const title = $(card).find(".card-header.font-weight-bold.text-center");
      const audio = $(card).find("audio > source")[0];

      let contentText = "";
      const contentsDom = $(card).find(".col.p-2 img");

      if (contentsDom.length > 0) {
        contentText = $(card).find(".col.p-2 .car-body.p-2").html();
      }

      const questions = $(card).find(".col.p-2");
      const questionCluster = {
        part: "4",
        audio: audio.attribs.src,
        content: contentText.trim(),
        listening: listening.html().trim(),
        translate: $(translate).html().trim(),
        title: `${title.text().trim()}:`,
        questions: getQuestions(questions, $),
      };
      questionClusters.push(questionCluster);
    }
    return [null, questionClusters];
  };

  const rawp5 = async (id) => {
    const [errResponse, response] = await to(
      api.get(`/raw-tool/raw-data/${id}`)
    );

    if (errResponse) {
      return [errResponse];
    }
    const $ = cheerio.load(response);
    const cards = $(".col.p-2");
    const questions = getQuestions(cards, $, false, true);
    const questionClusters = questions.map((question) => ({
      part: "5",
      title: `Question ${question.number}:`,
      audio: question.audio,
      content: "",
      translate: "",
      listening: "",
      questions: [question],
    }));
    return [null, questionClusters];
  };

  const rawp6 = async (id) => {
    const [errResponse, response] = await to(
      api.get(`/raw-tool/raw-data/${id}`)
    );

    if (errResponse) {
      return [errResponse];
    }
    const questionClusters = [];
    const $ = cheerio.load(response);
    const cards = $(".card.mt-4");
    for (let i = 0; i < cards.length; i += 1) {
      const card = cards[i];
      const content = $(card).find(
        ".col-sm-6.bg-light.px-3.pt-2.border-right.border-bottom.text-justify"
      );

      const translate = $(card)
        .find(".col-sm-6.bg-light.border-bottom.px-3.pt-2.text-justify")
        .get(1);

      const title = $(card).find(".card-header.font-weight-bold.text-center");
      const questions = $(card).find(".col.p-2");
      const questionCluster = {
        part: "6",
        listening: "",
        audio: "",
        content: content.html().trim(),
        translate: $(translate).html().trim(),
        title: `${title.text().trim()}:`,
        questions: getQuestions(questions, $, false, true, false),
      };
      questionClusters.push(questionCluster);
    }
    return [errResponse, questionClusters];
  };

  const rawp7 = async (id) => {
    const [errResponse, response] = await to(
      api.get(`/raw-tool/raw-data/${id}`)
    );

    if (errResponse) {
      return [errResponse];
    }
    const questionClusters = [];
    const $ = cheerio.load(response);
    const cards = $(".card.mt-4");
    for (let i = 0; i < cards.length; i += 1) {
      const card = cards[i];
      const content = $(card).find(
        ".col-sm-6.bg-light.px-3.pt-2.border-right.border-bottom.text-justify"
      );
      const translate = $(card)
        .find(".col-sm-6.bg-light.border-bottom.px-3.pt-2.text-justify")
        .get(1);

      const title = $(card).find(".card-header.font-weight-bold.text-center");

      const questions = $(card).find(".col.p-2");
      const questionCluster = {
        part: "7",
        listening: "",
        audio: "",
        content: $(content.get(0)).html().trim(),
        translate: $(translate).html().trim(),
        title: `${title.text().trim()}:`,
        questions: getQuestions(questions, $, false, true),
      };
      questionClusters.push(questionCluster);
    }
    return [null, questionClusters];
  };

  const rawETS2020 = async () => {
    await rawTestData({
      dataset: idraw.ets2020,
      totalTest: 10,
    });
  };

  const rawETS2021 = async () => {
    await rawTestData({
      dataset: idraw.ets2021,
      totalTest: 5,
    });
  };

  const rawETS2022 = async () => {
    await rawTestData({
      dataset: idraw.ets2022,
      totalTest: 10,
    });
  };

  const rawETS2023 = async () => {
    await rawTestData({
      dataset: idraw.ets2023,
      totalTest: 10,
    });
  };

  const rawETS2024 = async () => {
    await rawTestData({
      dataset: idraw.ets2024,
      totalTest: 10,
      skipTest: 5,
    });
  };

  const rawETS2019 = async () => {
    await rawTestData({
      dataset: idraw.ets2019,
      totalTest: 10,
    });
  };

  const rawETS2018 = async () => {
    await rawTestData({
      dataset: idraw.ets2018,
      totalTest: 5,
    });
  };

  const rawETS = async () => {
    await rawTestData({
      dataset: idraw.ets,
      totalTest: 7,
    });
  };

  const rawECO = async () => {
    await rawTestData({
      dataset: idraw.eco,
      totalTest: 9,
    });
  };

  const rawREAL = async () => {
    await rawTestData({
      dataset: idraw.real,
      totalTest: 4,
    });
  };

  const rawLONG = async () => {
    await rawTestData({
      dataset: idraw.long,
      totalTest: 2,
    });
  };

  const rawTestData = async ({ dataset, totalTest, skipTest }) => {
    for (let testindex = 1; testindex < totalTest + 1; testindex += 1) {
      if (skipTest && testindex === skipTest) {
        continue;
      }
      const test = dataset[`test${testindex}`];
      let datas = [];
      const [errp1, part1] = await rawp1(test.p1);
      if (errp1) {
        console.error(errp1);
        return;
      }
      datas = datas.concat(part1);
      const [errp2, part2] = await rawp2(test.p2);
      if (errp2) {
        console.error(errp2);
        return;
      }
      datas = datas.concat(part2);
      const [errp3, part3] = await rawp3(test.p3);
      if (errp3) {
        console.error(errp3);
        return;
      }
      datas = datas.concat(part3);
      const [errp4, part4] = await rawp4(test.p4);
      if (errp4) {
        console.error(errp4);
        return;
      }
      datas = datas.concat(part4);
      const [errp5, part5] = await rawp5(test.p5);
      if (errp5) {
        console.error(errp5);
        return;
      }
      datas = datas.concat(part5);
      const [errp6, part6] = await rawp6(test.p6);
      if (errp6) {
        console.error(errp6);
        return;
      }
      datas = datas.concat(part6);
      const [errp7, part7] = await rawp7(test.p7);
      if (errp7) {
        console.error(errp7);
        return;
      }
      datas = datas.concat(part7);
      console.log("test: ", testindex);
      console.log(datas);
    }
  };

  const getTitleResultTest = async () => {
    // await rawTitleResultTestToCheckDataTestID(
    //   idraw.ets2020,
    //   10,
    //   "Kết quả và đáp án ETS TOEIC 2020"
    // );
    // await rawTitleResultTestToCheckDataTestID(
    //   idraw.ets2019,
    //   10,
    //   "Kết quả và đáp án ETS TOEIC 2019"
    // );
    // await rawTitleResultTestToCheckDataTestID(
    //   idraw.ets2018,
    //   5,
    //   "Kết quả và đáp án ETS TOEIC 2018"
    // );
    // await rawTitleResultTestToCheckDataTestID(
    //   idraw.ets,
    //   7,
    //   "Kết quả và đáp án ETS TOEIC"
    // );
    // await rawTitleResultTestToCheckDataTestID(
    //   idraw.eco,
    //   9,
    //   "Kết quả và đáp án Eco TOEIC 1000"
    // );
    // await rawTitleResultTestToCheckDataTestID(
    //   idraw.real,
    //   4,
    //   "Kết quả và đáp án New Real TOEIC"
    // );
    await rawTitleResultTestToCheckDataTestID(
      idraw.long,
      2,
      "Kết quả và đáp án Longman Practice"
    );
  };

  const rawTitleResultTestToCheckDataTestID = async (
    dataset,
    totalTest,
    title
  ) => {
    for (let testindex = 1; testindex < totalTest + 1; testindex += 1) {
      const test = dataset[`test${testindex}`];

      for (let part = 1; part < 8; part += 1) {
        const [errResponse, response] = await to(
          api.get(`/raw-tool/raw-data/${test[`p${part}`]}`)
        );

        if (errResponse) {
          return [errResponse];
        }
        const exTitle = `${title} Test ${testindex}                                                (Part ${part})`;
        const $ = cheerio.load(response);
        const titles = $(".text-info");

        if (exTitle === $(titles[0]).text().replaceAll("\n", "").trim()) {
          console.log(true);
        } else {
          console.error(false);
        }
        console.log("----------------------------------------------");
      }
    }
  };

  return (
    <div>
      <Title>Raw Full Test</Title>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Button className="w-full" onClick={getTitleResultTest}>
            Raw Title Result test
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={rawETS2024}>
            Raw Data ETS 2024
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={rawETS2023}>
            Raw Data ETS 2023
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={rawETS2022}>
            Raw Data ETS 2022
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={rawETS2021}>
            Raw Data ETS 2021
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={rawETS2020}>
            Raw Data ETS 2020
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={rawETS2019}>
            Raw Data ETS 2019
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={rawETS2018}>
            Raw Data ETS 2018
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={rawETS}>
            Raw Data ETS
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={rawECO}>
            Raw Data ECO
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={rawREAL}>
            Raw Data REAL
          </Button>
        </Col>
        <Col span={6}>
          <Button className="w-full" onClick={rawLONG}>
            Raw Data LONGMAN
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default RawFullTest;
