import { useEffect, useState } from "react";
import { Typography, Input, Form, Spin, Button, Switch } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { PATH_NAME } from "paths";
import UploadImage from "components/UploadImage";
import api from "api";

const { Title } = Typography;

function DefaultAvatarCreatePage() {
  const [defaultAvatar, setDefaultAvatar] = useState({});
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [imageList, setImageList] = useState([]);

  const history = useHistory();
  const { defaultAvatarID } = useParams();

  useEffect(() => {
    if (defaultAvatarID) {
      api(`/default-avatar/${defaultAvatarID}`).then((data) => {
        setDefaultAvatar(data);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  const create = (value) => {
    api.post("/default-avatar", value).then(() => {
      history.push(PATH_NAME.DEFAULT_AVATAR);
    });
  };

  const update = (value) => {
    api.put("/default-avatar", { ...value, _id: defaultAvatarID }).then(() => {
      history.push(PATH_NAME.DEFAULT_AVATAR);
    });
  };

  const onFinish = (value) => {
    defaultAvatarID ? update(value) : create(value);
  };

  return (
    <div>
      <Title>{defaultAvatarID ? "Update" : "Create"} Default Avatar</Title>
      {loading ? (
        <Spin />
      ) : (
        <div>
          <div className="mb-5">
            <div className="mb-5">
              <p>Upload image</p>
              <div className="mt-5">
                <UploadImage
                  keyImage="default-avatar"
                  imageList={imageList}
                  onChangeImageList={(list) => setImageList(list)}
                />
              </div>
            </div>
            <Form
              form={form}
              name="CreateUpdateDefaultAvatar"
              layout="vertical"
              initialValues={defaultAvatar}
              onFinish={onFinish}
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Please input name!" }]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item
                label="Source"
                name="src"
                rules={[{ required: true, message: "Please input source!" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item label="Active" name="active" valuePropName="checked">
                <Switch />
              </Form.Item>

              <Form.Item>
                <div className="flex mt-5">
                  <Button
                    type="primary"
                    className="mr-5"
                    onClick={() => form.submit()}
                  >
                    {defaultAvatarID ? "Update" : "Create"}
                  </Button>
                  <Button
                    onClick={() => {
                      history.push(PATH_NAME.DEFAULT_AVATAR);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
}

export default DefaultAvatarCreatePage;
