import { useEffect, useState } from "react";
import { Typography, Modal, Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import {
  CREATE_GRAMMAR_TOPIC_PATH,
  GRAMMAR_PATH,
  UPDATE_GRAMMAR_TOPIC_PATH,
} from "paths";
import api from "api";

const { Title } = Typography;
const { confirm } = Modal;

function GrammarTopicPage() {
  const [grammars, setGrammars] = useState([]);
  const history = useHistory();

  const getGrammarTopics = () => {
    api("/grammar-topic").then((data) => {
      if (Array.isArray(data)) {
        setGrammars(
          data.sort(function (a, b) {
            return a.index - b.index;
          })
        );
      }
    });
  };

  useEffect(() => {
    getGrammarTopics();
  }, []);

  const deleteByID = (id) => {
    api(`/grammar-topic/delete/${id}`).then(() => {
      getGrammarTopics();
    });
  };

  const showDeleteConfirm = (grammar) => {
    confirm({
      title: `Are you sure delete the grammar No.${grammar.index}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteByID(grammar._id);
      },
    });
  };

  return (
    <div>
      <div className="flex justify-between">
        <Title>List Grammar</Title>
        <Button
          type="primary"
          className="mt-2"
          onClick={() => {
            history.push(CREATE_GRAMMAR_TOPIC_PATH());
          }}
        >
          Create
        </Button>
      </div>

      <div className="flex flex-wrap content-evenly">
        {grammars.map((grammar, index) => (
          <div
            key={index}
            style={{ minWidth: "300px" }}
            className="mb-8 p-5 border border-gray-300 mr-8 rounded-md hover:border-green-500"
          >
            <div className="flex justify-between">
              <p>
                <b className="text-white bg-gray-500 p-1 rounded">
                  {grammar.index}.
                </b>
              </p>

              {grammar.membership === "FREE" ? (
                <p>
                  <b className="text-white bg-gray-300 p-1 rounded">
                    {grammar.membership}
                  </b>
                </p>
              ) : (
                <p>
                  <b className="text-white bg-green-400 p-1 rounded">
                    {grammar.membership}
                  </b>
                </p>
              )}
            </div>

            <div className="mt-2">
              <b>{grammar.title}</b>
            </div>

            <div className="mt-2">
              <p>Test ID: {grammar.testID}</p>
            </div>

            <div className="flex justify-between mt-5">
              <Button
                className="mr-5"
                onClick={() => {
                  history.push(GRAMMAR_PATH(grammar._id));
                }}
              >
                Detail
              </Button>
              <div className="flex">
                <Button
                  className="mr-5"
                  onClick={() => {
                    history.push(UPDATE_GRAMMAR_TOPIC_PATH(grammar._id));
                  }}
                >
                  Edit
                </Button>
                <Button danger onClick={() => showDeleteConfirm(grammar)}>
                  Delete
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default GrammarTopicPage;
