/* eslint-disable jsx-a11y/media-has-caption */
function QuestionCluster({ questionCluster }) {
  return (
    <div>
      <p>
        <b className="text-white bg-gray-500 p-1 rounded">
          {questionCluster.index}.
        </b>
        <b className="p-4" style={{ fontSize: "20px" }}>
          PART {questionCluster.part}
        </b>
      </p>
      <div className="mt-1 mb-5">
        <b>{questionCluster.title}</b>
      </div>

      {questionCluster.audio && (
        <div className="mb-5">
          <audio controls>
            <source src={questionCluster.audio} type="audio/mp3" />
          </audio>
        </div>
      )}

      {questionCluster.content && (
        <div className="border border-gray-300 rounded-md p-5 mb-5">
          <p>
            <u>Content:</u>
          </p>
          <div dangerouslySetInnerHTML={{ __html: questionCluster.content }} />
        </div>
      )}

      {questionCluster.translate && (
        <div className="border border-gray-300 rounded-md p-5 mb-5">
          <p>
            <u>Translate:</u>
          </p>
          <div
            dangerouslySetInnerHTML={{ __html: questionCluster.translate }}
          />
        </div>
      )}

      {questionCluster.listening && (
        <div className="border border-gray-300 rounded-md p-5 mb-5">
          <p>
            <u>Listening:</u>
          </p>
          <div
            dangerouslySetInnerHTML={{ __html: questionCluster.listening }}
          />
        </div>
      )}
    </div>
  );
}

export default QuestionCluster;
