function QuestionTesting({
  question,
  hiddenQuestion,
  hiddenOption,
  answerTesting,
  onChooseAnswer,
}) {
  return (
    <div key={question._id} className="mb-10">
      <div className="mb-2">
        <p>
          <b>{question.number}. </b> {question.title && question.title}
        </p>
        {question.question && !hiddenQuestion && <p>{question.question}</p>}
      </div>

      {question.options.map((option) => (
        <div
          onClick={() => onChooseAnswer(option.key)}
          key={option._id}
          className={`cursor-pointer hover:border-blue-500 mb-2 p-2 rounded-md border-2 ${
            answerTesting === option.key && "border-blue-500"
          }`}
        >
          <p>
            <b>({option.key})</b> {!hiddenOption && option.value}
          </p>
        </div>
      ))}
    </div>
  );
}

export default QuestionTesting;
